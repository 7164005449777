import React, { useState, useEffect } from "react";
import { Button, Card, message, Form, Input } from "antd";
import { createURLById } from "../../../Constants/globals";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import MuiAccordion from "@material-ui/core/Accordion";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import trans from "../../../Languages/Languages";
import API from "../../../API";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import AccordionContentById from "../../Components/common/AccordionContentById";

const InstitutionAndEvidence = () => {
  const [refresh, setRefresh] = useState(true);
  const [expanded, setExpanded] = React.useState();

  // institution & evidence section states
  const [institutionEvidenceData, setInstitutionEvidenceData] = React.useState(
    []
  );
  const [institutionEvidenceLoading, setInstitutionEvidenceLoading] =
    useState(false);

  // institution & evidence content states
  const [institutionEvidenceContentData, setInstitutionEvidenceContentData] =
    React.useState([]);
  const [
    institutionEvidenceContentLoading,
    setInstitutionEvidenceContentLoading,
  ] = useState(false);

  const [sectionData, setSectionData] = useState([]);
  const [newData, setNewData] = useState([]);
  const [sectionEdit, setSectionEdit] = useState(false);

  ///////////////////////////////////////////////////////////////////////////////////////////////
  ///                            Use Effect                                                   ///
  ///////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    setInstitutionEvidenceLoading(true);
    setInstitutionEvidenceContentLoading(true);

    // get all institutions & evidence sections
    const getInstitutionEvidence = () => {
      API.get("/admin/institution-evidence")
        .then((res) => {
          setInstitutionEvidenceData(res.data);
          setInstitutionEvidenceLoading(false);
        })
        .catch((err) => {});
    };

    // get all institutions & evidence content
    const getInstitutionEvidenceContent = () => {
      API.get("/admin/institution-evidence-content")
        .then((res) => {
          setInstitutionEvidenceContentData(res.data);
          setInstitutionEvidenceContentLoading(false);
        })
        .catch((err) => {});
    };

    getInstitutionEvidence();
    getInstitutionEvidenceContent();
  }, [refresh]);

  // methods
  // handle accordion change
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  // handle add another section
  const handleAddAnotherSection = () => {
    const dummyData = [...sectionData, { title: "", new: true }];
    setSectionData(dummyData);

    const newDummyData = [...newData, { title: "", new: true }];
    setNewData(newDummyData);
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////
  ///                             create new section records
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const handleCreateNew = async () => {
    setInstitutionEvidenceLoading(true);

    if (newData.length > 0) {
      const formData = new FormData();
      newData.forEach((element, i) => {
        formData.append(`data[${i}][title]`, element.title);
      });

      await API.post("/admin/institution-evidence/create", formData)
        .then((res) => {
          setNewData([]);
          setInstitutionEvidenceLoading(false);
          setRefresh(!refresh);
          setSectionData([]);
          message.success("Section Created Successfully");
        })
        .catch((e) => {
          if (e.response.status === 400) {
            message.error("Field is required");
          }

          setInstitutionEvidenceLoading(false);
        });
    }
  };

  return (
    <div className="pages-container">
      <div className="content-wrapper">
        <Card
          className="p-0"
          bordered={false}
          loading={institutionEvidenceLoading}
          style={{ minHeight: 600 }}
        >
          {/* Accordion Institution & Evidence Section  */}
          {institutionEvidenceData?.length > 0 &&
            institutionEvidenceData?.map((section) => {
              // handle the data
              const filteredInstitutionEvidenceContentData =
                institutionEvidenceContentData.filter(
                  (item) => item?.institution_evidence_id === section?.id
                );

              return (
                <div className="h-accordion-container" key={section?.id}>
                  <AccordionUpload
                    id={section?.id}
                    name={section?.title}
                    expanded={expanded}
                    handleChange={handleChange}
                    data={filteredInstitutionEvidenceContentData}
                    setData={setInstitutionEvidenceContentData}
                    sectionEdit={sectionEdit}
                    setSectionEdit={setSectionEdit}
                    setNewData={setNewData}
                    loading={institutionEvidenceContentLoading}
                    setLoading={setInstitutionEvidenceContentLoading}
                    refresh={refresh}
                    setRefresh={setRefresh}
                  />
                </div>
              );
            })}

          {/* /////////////////// edit section /////////////////// */}
          {sectionEdit && (
            <div className="position-relative mb-5">
              <AddSection
                key={1}
                index={1}
                data={[]}
                setData={setSectionData}
                originalData={sectionData}
                newData={newData}
                setNewData={setNewData}
                sectionEdit={sectionEdit}
                setSectionEdit={setSectionEdit}
                refresh={refresh}
                setRefresh={setRefresh}
                setLoading={setInstitutionEvidenceLoading}
              />
            </div>
          )}

          {/* /////////////////// add section /////////////////// */}
          <div className="position-relative mb-5">
            {sectionData.length > 0 &&
              sectionData.map((item, i) => {
                return (
                  <AddSection
                    key={i}
                    index={i}
                    data={item}
                    setData={setSectionData}
                    originalData={sectionData}
                    setNewData={setNewData}
                    newData={newData}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    setLoading={setInstitutionEvidenceLoading}
                  />
                );
              })}

            {/* add another */}
            {!sectionEdit && (
              <div>
                <div
                  className="faculty-add-another"
                  onClick={handleAddAnotherSection}
                >
                  <span className="f-12 fw-500">
                    {trans.Addanother}{" "}
                    <span className="f-20 fw-500 align-middle">+</span>
                  </span>
                </div>

                {/* save changes */}
                {newData.length > 0 && (
                  <Button
                    onClick={handleCreateNew}
                    className="btn-save-changes"
                    type="primary"
                    htmlType="submit"
                  >
                    {trans.Save}
                  </Button>
                )}
              </div>
            )}
          </div>
        </Card>
      </div>
    </div>
  );
};

export default InstitutionAndEvidence;

// accordion upload
const AccordionUpload = ({
  id,
  name,
  expanded,
  handleChange,
  data,
  setData,
  setSectionEdit,
  setNewData,
  loading,
  setLoading,
  refresh,
  setRefresh,
}) => {
  // states
  const [newContentRecord, setNewContentRecord] = useState([]);

  ///////////////////////////////////////////////////////////////////////////////////////////////
  ///                  Accordion Add Another / Delete methods
  ////////////////////////////////////////////////////////////////////////////////////////////////
  const handleContentAnother = (id) => {
    const newData = [
      ...data,
      {
        institution_evidence_id: id,
        title: "",
        file: "",
        new: true,
      },
    ];
    setData(newData);

    const newRecord = [
      ...newContentRecord,
      {
        institution_evidence_id: id,
        title: "",
        file: "",
        new: true,
      },
    ];
    setNewContentRecord(newRecord);
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////
  ///                             create new records
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const handleCreateRecords = async (id) => {
    let error = false;

    if (data.length > 0) {
      const formData = new FormData();
      data.forEach((element, i) => {
        if (!element.id) {
          formData.append(`data[${i}][institution_evidence_id]`, id);
          formData.append(`data[${i}][title]`, element.title);
          formData.append(`data[${i}][file]`, element.file);
        }
        if (element.title === "" || element.file === "") {
          error = true;
        }
      });

      if (!error) {
        let createUrl = createURLById[3];

        await API.post(createUrl, formData)
          .then((res) => {
            message.success("Document Uploaded Successfully", 3);
            setNewContentRecord([]);
            setRefresh(!refresh);
          })
          .catch((err) => {
            message.error("Something went wrong, please try again", 3);
            setNewContentRecord([]);
            setRefresh(!refresh);
          });
      } else {
        message.error("Text or File/Image field is empty, fill all fields", 3);
      }
    }
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////
  ///                 handle new content record delete
  ////////////////////////////////////////////////////////////////////////////////////////////////
  const handleNewRecordDelete = (index) => {
    const newData = data.filter((item, i) => {
      return index !== i;
    });

    setData(newData);
  };

  // handle section edit
  const handleSectionEdit = (id) => {
    setSectionEdit(true);

    setNewData({
      id: id,
      title: name,
    });
  };

  // delete record
  const handleSectionDelete = async () => {
    setLoading(true);

    await API.post(`/admin/institution-evidence/delete/${id}`)
      .then((res) => {
        message.success("Section Deleted Successfully");
        setRefresh(!refresh);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <Accordion
      square
      expanded={expanded === `panel${id}`}
      onChange={handleChange(`panel${id}`)}
    >
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <p className="f-14 fw-600 mb-0">
          {name}
          <span>
            <KeyboardArrowDownIcon className="down-arrow-accordion" />
          </span>
        </p>
      </AccordionSummary>
      <AccordionDetails className="accordion-detail">
        <Card bordered={false} className="p-3" loading={loading}>
          <div>
            <div className="d-flex justify-content-between">
              <p className="f-16 fw-500 mt-10">{trans.Downloadablecontent}</p>

              {/* edit action */}
              <div className="ml-4">
                <span onClick={() => handleSectionEdit(id)}>
                  <EditIcon className="b-icon-edit w-16 h-16 cursor-pointer" />
                </span>
                <CloseIcon
                  className="b-icon-close w-20 h-20 cursor-pointer ml-1"
                  onClick={() => handleSectionDelete(id)}
                />
              </div>
            </div>

            {data?.map((item, index) => {
              return (
                <AccordionContentById
                  id={id}
                  key={index}
                  index={index}
                  data={item}
                  setData={setData}
                  originData={data}
                  newData={newContentRecord}
                  setNewData={setNewContentRecord}
                  refresh={refresh}
                  setRefresh={setRefresh}
                  setLoading={setLoading}
                  handleDelete={handleNewRecordDelete}
                />
              );
            })}

            {/* add another */}
            <span
              className="f-12 fw-500 align-middle h-add-another"
              onClick={() => handleContentAnother(id)}
            >
              {trans.Addanother}
              <span className="f-18 align-middle">+</span>
            </span>

            {newContentRecord.length > 0 && (
              <div className="d-flex justify-content-end">
                <Button
                  onClick={() => handleCreateRecords(id)}
                  className="btn-save-changes"
                  type="primary"
                  htmlType="submit"
                >
                  {trans.Save}
                </Button>
              </div>
            )}
          </div>
        </Card>
      </AccordionDetails>
    </Accordion>
  );
};

export { AccordionUpload };

// section upload

const AddSection = ({
  data,
  index,
  setData,
  originalData,
  newData,
  setNewData,
  sectionEdit,
  setSectionEdit,
  setLoading,
  refresh,
  setRefresh,
}) => {
  // methods
  const handleNewSectionDelete = (index) => {
    const dummyData = originalData.filter((item, i) => {
      return index !== i;
    });
    setData(dummyData);

    let newIndex = newData.length + index - originalData.length;
    let newRecord = [];
    newRecord = newData.filter((item, i) => {
      return newIndex !== i;
    });
    setNewData(newRecord);
  };

  // handle field value change
  const handleInputValueChange = ({ target }) => {
    if (newData.length + index - originalData.length >= 0) {
      const dummyNewData = [...newData];
      dummyNewData[newData.length + index - originalData.length][target.name] =
        target.value;
      setNewData(dummyNewData);
    }
  };

  // cancel update
  const handleCancelUpdate = () => {
    setSectionEdit(false);
    setNewData([]);
  };

  // update record
  const handleSectionUpdate = async (values) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("title", values.title);

    await API.post(
      `/admin/institution-evidence/update/${newData?.id}`,
      formData
    )
      .then((res) => {
        setRefresh(!refresh);
        setLoading(false);
        setSectionEdit(false);
        setNewData([]);
        message.success("Section Updated Successfully", 3);
      })
      .catch((e) => {
        setLoading(false);
        message.error("Field is required.");
      });
  };

  // delete record
  const handleSectionDelete = async () => {
    setLoading(true);

    await API.post(`/admin/institution-evidence/delete/${data.id}`)
      .then((res) => {
        message.success("Section Deleted Successfully");
        setRefresh(!refresh);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <div className="bg-content-container faculty-container college-container">
      <Form onFinish={handleSectionUpdate}>
        {/* edit action */}
        <div style={{ height: 10 }}>
          {sectionEdit ? (
            <div className="edit-action icon-container d-block mr-1">
              <span
                className="f-12 fw-400 cancel cursor-pointer"
                onClick={handleCancelUpdate}
              >
                {trans.Cancel}
              </span>

              <span className="save">
                <Button htmlType="submit">{trans.Save}</Button>
              </span>
            </div>
          ) : (
            <div className="icon-container">
              <CloseIcon
                className="b-icon-close cursor-pointer"
                onClick={() => {
                  data.new
                    ? handleNewSectionDelete(index)
                    : handleSectionDelete(index);
                }}
              />
            </div>
          )}
        </div>

        <div className="d-flex justify-content-between">
          <div className="accordion-detail">
            <div className="f-12 fw-500 faculty-heading">{trans.Title}</div>
            <Form.Item name="title" initialValue={newData?.title}>
              <Input
                onChange={handleInputValueChange}
                disabled={sectionEdit || data.new ? "" : "disabled"}
                type="text"
                name="title"
                placeholder={trans.Typehere}
              />
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
};

///////////////////////////////////////////////////////////////////////////////////////////////
///                  Material Accordion BoilerPlate
////////////////////////////////////////////////////////////////////////////////////////////////
const Accordion = withStyles({
  root: {
    // border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    fontWeight: 600,

    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
      border: "1px solid rgba(0, 0, 0, .125)",
      marginBottom: 10,
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "#FCFCFC",
    border: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: 16,
    minHeight: 40,

    "&$expanded": {
      marginBottom: 0,
      border: 0,

      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    backgroundColor: "#FCFCFC",
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);
