import { Switch, Route, HashRouter as Router } from "react-router-dom";
import React from "react";
import "./App.css";
import Dashboard from "./Pages/Dashboard/index";
import Pages from "./Pages/Pages/Index";
import MyProfile from "./Pages/Components/MyProfile/index";
import Users from "./Pages/Users/Users";
import Edit from "./Pages/Users/Edit";
import Login from "./Pages/Login/login";
import ForgotPassword from "./Pages/Login/ForgotPassword";
import ResetPassword from "./Pages/Login/ResetPassword";
import Sidebar from "./Pages/Sidebar/Sidebar";
import PrivateRoute from "./Pages/Components/Routes/PrivateRoutes";
import UserProvider from "./Context/UserContext";
import PagesHome from "./Pages/Pages/Home/Home";
import KIUVision from "./Pages/Pages/AboutUs/KiuVision";
import KIUDirector from "./Pages/Pages/AboutUs/KIUDirector";
import BoardOfTrustees from "./Pages/Pages/AboutUs/BoardOfTrustees";
import Faculties from "./Pages/Pages/AboutUs/Faculties";
import CollegesInstitutes from "./Pages/Pages/AboutUs/CollegesInstitutes";
import NewsAndEvents from "./Pages/Pages/AboutUs/NewsAndEvents";
import FAQ from "./Pages/Pages/AboutUs/FAQ";
import ContactUS from "./Pages/Pages/AboutUs/ContactUs";
import Degree from "./Pages/Pages/OnlineDegree/degree";
import Courses from "./Pages/Pages/Courses/Courses";
import FeeStructure from "./Pages/Pages/Courses/FeeStructure";
import OnlineFreeCourses from "./Pages/Pages/Courses/OnlineFreeCourses";
import StudyOverview from "./Pages/Pages/HowItWorks/StudyOverview";
import StudentPortal from "./Pages/Pages/HowItWorks/StudentPortal";
import StudyMaterial from "./Pages/Pages/HowItWorks/StudyMaterial";
import VideoLectures from "./Pages/Pages/HowItWorks/VideoLectures";
import LiveSessions from "./Pages/Pages/HowItWorks/LiveSessions";
import KIUStudentForum from "./Pages/Pages/HowItWorks/KIUStudentForum";
import Assessment from "./Pages/Pages/HowItWorks/Assessment";
import Requirements from "./Pages/Pages/admission/requirements";
import EnrollmentProcess from "./Pages/Pages/admission/enrollmentProcess";
import Scholarships from "./Pages/Pages/admission/scholarships";
import AdmissionDeadline from "./Pages/Pages/admission/admissionDeadline";
import WhoShouldStudyAtKIU from "./Pages/Pages/WhyKIU/whoShouldStudyAtKIU";
import BenefitOfKIUDegree from "./Pages/Pages/WhyKIU/benefitOfKIUDegree";
import CoursesCategories from "./Pages/Pages/Courses/CourseCategories";
import InstitutionAndEvidence from "./Pages/Pages/AboutUs/InstitutionAndEvidence";

function App() {
  return (
    <UserProvider>
      <Router basename="/">
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/forgot-password" component={ForgotPassword} exact />
          <Route path="/reset-password" component={ResetPassword} exact />
          <PrivateRoute path="/pages" exact>
            <Sidebar component={<Pages />} />
          </PrivateRoute>
          <PrivateRoute path="/users" exact>
            <Sidebar component={<Users />} />
          </PrivateRoute>
          <PrivateRoute path="/my-profile" exact>
            <Sidebar component={<MyProfile />} />
          </PrivateRoute>
          <PrivateRoute path="/user/:id" exact>
            <Sidebar component={<Edit />} />
          </PrivateRoute>
          <PrivateRoute path="/" exact>
            <Sidebar component={<Dashboard />} />
          </PrivateRoute>
          {
            //==============================================
            //                    pages/aboutUs
            //==============================================
          }

          <PrivateRoute path="/pages/home" exact>
            <Sidebar component={<PagesHome />} subPage />
          </PrivateRoute>

          <PrivateRoute path="/pages/aboutus/kiuvision" exact>
            <Sidebar component={<KIUVision />} subPage />
          </PrivateRoute>
          <PrivateRoute path="/pages/aboutus/kiudirector" exact>
            <Sidebar component={<KIUDirector />} subPage />
          </PrivateRoute>
          <PrivateRoute path="/pages/aboutus/boardoftrustees" exact>
            <Sidebar component={<BoardOfTrustees />} subPage />
          </PrivateRoute>
          <PrivateRoute path="/pages/aboutus/faculties" exact>
            <Sidebar component={<Faculties />} subPage />
          </PrivateRoute>
          <PrivateRoute path="/pages/aboutus/collegeandinstitute" exact>
            <Sidebar component={<CollegesInstitutes />} subPage />
          </PrivateRoute>
          <PrivateRoute path="/pages/aboutus/newsandevents" exact>
            <Sidebar component={<NewsAndEvents />} subPage />
          </PrivateRoute>
          <PrivateRoute path="/pages/aboutus/institutionandevidence" exact>
            <Sidebar component={<InstitutionAndEvidence />} subPage />
          </PrivateRoute>
          <PrivateRoute path="/pages/aboutus/faq" exact>
            <Sidebar component={<FAQ />} subPage />
          </PrivateRoute>
          <PrivateRoute path="/pages/aboutus/contactus" exact>
            <Sidebar component={<ContactUS />} subPage />
          </PrivateRoute>
          {
            //==============================================
            //                    pages/onlineDegree
            //==============================================
          }
          <PrivateRoute path="/pages/degree/:id/aboutdegree" exact>
            <Sidebar component={<Degree currentPage="aboutDegree" />} subPage />
          </PrivateRoute>
          <PrivateRoute path="/pages/degree/:id/syllabusbysemester" exact>
            <Sidebar
              component={<Degree currentPage="syllabusBySemester" />}
              subPage
            />
          </PrivateRoute>
          <PrivateRoute path="/pages/degree/:id/tuitionfees" exact>
            <Sidebar component={<Degree currentPage="tuitionFees" />} subPage />
          </PrivateRoute>
          <PrivateRoute path="/pages/degree/:id/academichelp" exact>
            <Sidebar
              component={<Degree currentPage="academicHelp" />}
              subPage
            />
          </PrivateRoute>

          {
            //==============================================
            //                    pages/courses
            //==============================================
          }
          <PrivateRoute path="/pages/courses/courses" exact>
            <Sidebar component={<Courses />} subPage />
          </PrivateRoute>
          <PrivateRoute path="/pages/courses/coursescategories" exact>
            <Sidebar component={<CoursesCategories />} subPage />
          </PrivateRoute>
          <PrivateRoute path="/pages/courses/feestructure" exact>
            <Sidebar component={<FeeStructure />} subPage />
          </PrivateRoute>
          <PrivateRoute path="/pages/courses/onlinefreecourses" exact>
            <Sidebar component={<OnlineFreeCourses />} subPage />
          </PrivateRoute>
          {
            //==============================================
            //                    pages/howItWorks
            //==============================================
          }
          <PrivateRoute path="/pages/howitworks/studyoverview" exact>
            <Sidebar component={<StudyOverview />} subPage />
          </PrivateRoute>
          <PrivateRoute path="/pages/howitworks/studentportal" exact>
            <Sidebar component={<StudentPortal />} subPage />
          </PrivateRoute>
          <PrivateRoute path="/pages/howitworks/studymaterial" exact>
            <Sidebar component={<StudyMaterial />} subPage />
          </PrivateRoute>
          <PrivateRoute path="/pages/howitworks/videolectures" exact>
            <Sidebar component={<VideoLectures />} subPage />
          </PrivateRoute>
          <PrivateRoute path="/pages/howitworks/livesessions" exact>
            <Sidebar component={<LiveSessions />} subPage />
          </PrivateRoute>
          <PrivateRoute path="/pages/howitworks/kiustudentforum" exact>
            <Sidebar component={<KIUStudentForum />} subPage />
          </PrivateRoute>
          <PrivateRoute path="/pages/howitworks/assessment" exact>
            <Sidebar component={<Assessment />} subPage />
          </PrivateRoute>
          {
            //==============================================
            //                    pages/admissionFee
            //==============================================
          }
          <PrivateRoute path="/pages/admissionFee/requirements" exact>
            <Sidebar component={<Requirements />} subPage />
          </PrivateRoute>
          <PrivateRoute path="/pages/admissionFee/enrollmentprocess" exact>
            <Sidebar component={<EnrollmentProcess />} subPage />
          </PrivateRoute>
          <PrivateRoute path="/pages/admissionFee/scholarships" exact>
            <Sidebar component={<Scholarships />} subPage />
          </PrivateRoute>
          <PrivateRoute path="/pages/admissionFee/admissiondeadline" exact>
            <Sidebar component={<AdmissionDeadline />} subPage />
          </PrivateRoute>
          {
            //==============================================
            //                    pages/whyKIU
            //==============================================
          }
          <PrivateRoute path="/pages/whyKIU/whoshouldstudyatKIU" exact>
            <Sidebar component={<WhoShouldStudyAtKIU />} subPage />
          </PrivateRoute>
          <PrivateRoute path="/pages/whyKIU/benefitsofdegree" exact>
            <Sidebar component={<BenefitOfKIUDegree />} subPage />
          </PrivateRoute>
        </Switch>
      </Router>
    </UserProvider>
  );
}

export default App;
