import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import ClearIcon from "@material-ui/icons/Clear";
import trans from "./../../../Languages/Languages";
import { Button, Form, Input, message, Select, Card } from "antd";
import React, { useEffect, useState } from "react";
import API from "../../../API";
import { useLocation } from "react-router";

const { Option } = Select;

const Syllabus = () => {
  const currentDegreeID = window.location.hash.split("/")[3];

  // states
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [semesterData, setSemesterData] = useState([]);
  const [newSemesterData, setNewSemesterData] = useState([]);

  const handleAnotherSemesterAdded = () => {
    const dummyData = [
      ...semesterData,
      {
        semester_order: 1,
        new: true,
        syllabus: [],
      },
    ];
    setSemesterData(dummyData);

    const newDummyData = [
      ...newSemesterData,
      {
        semester_order: 1,
        new: true,
        syllabus: [],
      },
    ];
    setNewSemesterData(newDummyData);
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////
  ///                  Use Effect
  ////////////////////////////////////////////////////////////////////////////////////////////////
  let location = useLocation();
  useEffect(() => {
    setLoading(true);
    const init = async () => {
      await API.get(`/admin/semester/${currentDegreeID}`)
        .then((res) => {
          setSemesterData(res.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    };
    init();
  }, [refresh, currentDegreeID, location]);

  // create new semesters
  const handleCreateNew = async () => {
    if (newSemesterData.length > 0) {
      const formData = new FormData();
      newSemesterData.forEach((element, i) => {
        formData.append(
          `semesters[${i}][semester_order]`,
          element.semester_order
        );
        formData.append(`semesters[${i}][onlinedegree_id]`, currentDegreeID);
      });

      await API.post("/admin/semester/create", formData)
        .then((res) => {
          setNewSemesterData([]);
          setLoading(false);
          setRefresh(!refresh);
          message.success("Semester Created Succesfully.");
        })
        .catch((e) => {
          if (e.response.status === 400) {
            message.error("Field is required");
          }
          setLoading(false);
        });
    }
  };
  return (
    <div>
      <div className="pages-container">
        <Card bordered={false} className="p-0" loading={loading}>
          <div className="content-wrapper">
            {semesterData.length > 0 && (
              <>
                <div className="fw-600 mb-2">{trans.Syllabusbysemester}</div>

                {semesterData.map((item, i) => {
                  return (
                    <AddSemester
                      key={i}
                      index={i}
                      data={item}
                      setLoading={setLoading}
                      newSemesterData={newSemesterData}
                      setNewSemesterData={setNewSemesterData}
                      semesterData={semesterData}
                      setSemesterData={setSemesterData}
                      currentDegreeID={currentDegreeID}
                      refresh={refresh}
                      setRefresh={setRefresh}
                    />
                  );
                })}
              </>
            )}

            {/* add another */}
            <div className="d-flex justify-content-center">
              <div
                className="semester-add-another cursor-pointer"
                onClick={handleAnotherSemesterAdded}
              >
                <span className="f-12 fw-500">
                  <span className="mr-2"> {trans.AddNewSection}</span>

                  <span className="f-18 fw-500 align-middle">+</span>
                </span>
              </div>
            </div>
          </div>

          {newSemesterData.length > 0 && (
            <Button
              onClick={handleCreateNew}
              className="btn-save-changes"
              type="primary"
              htmlType="submit"
            >
              {trans.Save}
            </Button>
          )}
        </Card>
      </div>
    </div>
  );
};

export default Syllabus;

const AddSemester = ({
  index,
  data,
  setLoading,
  newSemesterData,
  setNewSemesterData,
  semesterData,
  setSemesterData,
  currentDegreeID,
  refresh,
  setRefresh,
}) => {
  const [edit, setEdit] = useState(false);
  // semesters dropdown values for new semester
  const [semesters] = useState([
    { name: 1, disable: false },
    { name: 2, disable: false },
    { name: 3, disable: false },
    { name: 4, disable: false },
    { name: 5, disable: false },
    { name: 6, disable: false },
    { name: 7, disable: false },
    { name: 8, disable: false },
  ]);
  const [newSubjectsData, setNewSubjectsData] = useState([]);

  const handleSelectChange = (e) => {
    const dummyData = [...semesterData];
    dummyData[index].semester_order = e;
    setSemesterData(dummyData);

    if (newSemesterData.length + index - semesterData.length >= 0) {
      const newDummyData = [...newSemesterData];
      newDummyData[
        newSemesterData.length + index - semesterData.length
      ].semester_order = e;
      setNewSemesterData(newDummyData);
    }
  };

  const handleAnotherCourse = () => {
    const dummyData = [...semesterData];
    dummyData[index].syllabus.push({
      new: true,
      updatedCourses: "",
      credit_hours: 0,
    });
    setSemesterData(dummyData);

    const newDummyData = [
      ...newSubjectsData,
      { new: true, courses: "", credit_hours: 0 },
    ];
    setNewSubjectsData(newDummyData);
  };

  // create new course
  const handleCreateNew = async () => {
    if (true) {
      const formData = new FormData();
      newSubjectsData.forEach((element, i) => {
        formData.append(`courses[${i}][course]`, element.courses);
        formData.append(`courses[${i}][credit_hours]`, element.credit_hours);
      });
      formData.append("semester", data.id);
      formData.append("onlinedegree_id", currentDegreeID);

      await API.post("/admin/semester/syllabus/create", formData)
        .then((res) => {
          setLoading(false);
          setRefresh(!refresh);
          message.success("Syllabus Created Successfully");
        })
        .catch((e) => {
          if (e.response.status === 400) {
            message.error("Field is required");
          }
          setLoading(false);
        });
    }
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////
  ///                             delete course
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const semesterDelete = async () => {
    setLoading(true);

    await API.post(`/admin/semester/delete/${data.id}`)
      .then((res) => {
        const dummyData = semesterData.filter((item, i) => {
          return index !== i;
        });
        setSemesterData(dummyData);
        message.success("Deleted Successfully");
        setLoading(false);
      })
      .catch((err) => {
        message.error("Something went wrong, please try again", 3);
        setLoading(false);
      });
  };
  const semesterNewDelete = () => {
    const dummyData = [...semesterData];
    const newSemester = dummyData.filter((s, i) => {
      return i !== index;
    });
    setSemesterData(newSemester);
  };

  const handleCoursesUpdate = async () => {
    const formData = new FormData();

    data.syllabus.forEach((element, i) => {
      formData.append(`courses[${i}][course_id]`, element.id);
      formData.append(`courses[${i}][course]`, element.courses);
      formData.append(`courses[${i}][credit_hours]`, element.credit_hours);
    });
    formData.append(`onlinedegree_id`, currentDegreeID);

    await API.post(`/admin/semester/syllabus/update`, formData)
      .then((res) => {
        message.success("Updated Successfully");
        setEdit(false);
      })
      .catch((e) => {
        if (e.response.status === 400) {
          message.error("Field is required.");
        }
      });
  };

  return (
    <div className="bg-content-container faculty-container">
      {/* edit action */}
      <div style={{ height: 10 }}>
        {edit ? (
          <div className="edit-action icon-container d-block mr-1">
            <span
              className="f-12 fw-400 cancel "
              onClick={() => setEdit(false)}
            >
              {trans.Cancel}
            </span>

            <span className="save">
              <Button onClick={handleCoursesUpdate} type="" htmlType="submit">
                {trans.Save}
              </Button>
            </span>
          </div>
        ) : !edit && data.syllabus.length > 0 ? (
          <div className="icon-container">
            {!data.new && (
              <span onClick={() => setEdit(true)}>
                <EditIcon className="b-icon-edit cursor-pointer" />
              </span>
            )}
            <CloseIcon
              className="b-icon-close cursor-pointer"
              onClick={() =>
                data.new ? semesterNewDelete(index) : semesterDelete(index)
              }
            />
          </div>
        ) : (
          <div className="icon-container">
            <CloseIcon
              className="b-icon-close cursor-pointer"
              onClick={() =>
                data.new ? semesterNewDelete(index) : semesterDelete(index)
              }
            />
          </div>
        )}
      </div>
      {/* courses */}
      <div className="f-15 fw-400 mb-5 faculty-heading text-center">
        Semester {data.semester_order}
      </div>

      {data.new && (
        <>
          <div className="f-14 fw-600 faculty-heading">{trans.Semester}</div>
          <Select
            onChange={handleSelectChange}
            suffixIcon=""
            defaultValue=""
            className="mb-3 select-semester"
            rules={[{ required: true }]}
          >
            {semesters.map((s, i) => {
              return (
                <Option disable={false} value={i + 1}>
                  {i + 1}
                </Option>
              );
            })}
          </Select>
        </>
      )}

      {data.syllabus.length > 0 && (
        <div className="f-14 fw-600 faculty-heading">{trans.Courses}</div>
      )}

      {data.syllabus.length > 0 &&
        data.syllabus.map((c, cIndex) => {
          return (
            <Courses
              data={c}
              index={cIndex}
              semesterData={semesterData}
              setSemesterData={setSemesterData}
              newSubjectsData={newSubjectsData}
              setNewSubjectsData={setNewSubjectsData}
              semesterIndex={index}
              edit={edit}
              setLoading={setLoading}
            />
          );
        })}
      {/* add another */}

      {!semesterData[index]?.new && (
        <div
          className="add-new-course"
          onClick={() => handleAnotherCourse(index)}
        >
          <span className="f-12 fw-500 ">
            <span className="mr-2"> {trans.Addnewcourse}</span>
            <span className="f-18 fw-500 align-middle">+</span>
          </span>
        </div>
      )}
      {/* save changes */}
      {newSubjectsData.length > 0 && (
        <div className="d-flex justify-content-end">
          <Button
            onClick={handleCreateNew}
            className="btn-save-changes"
            type=""
            htmlType="submit"
          >
            <span>{trans.Save}</span>
          </Button>
        </div>
      )}
    </div>
  );
};

const Courses = ({
  index,
  data,
  semesterData,
  setSemesterData,
  newSubjectsData,
  setNewSubjectsData,
  semesterIndex,
  edit,
  setLoading,
}) => {
  const handleSectionFinish = (values) => {};
  ///////////////////////////////////////////////////////////////////////////////////////////////
  ///                             new course delete
  ////////////////////////////////////////////////////////////////////////////////////////////////
  const handleNewCourseDelete = () => {
    const dummyData = [...semesterData];
    let newSubjects = dummyData[semesterIndex].syllabus.filter((item, i) => {
      return index !== i;
    });
    dummyData[semesterIndex].syllabus = newSubjects;
    setSemesterData(dummyData);

    let newIndex =
      newSubjectsData.length +
      index -
      semesterData[semesterIndex].syllabus.length -
      1;
    let newRecord = [];
    newRecord = newSubjectsData.filter((item, i) => {
      return newIndex !== i;
    });
    setNewSubjectsData(newRecord);
  };
  const handleCourseDelete = async () => {
    setLoading(true);

    await API.post(`/admin/semester/syllabus/delete/${data.id}`)
      .then((res) => {
        const dummyData = semesterData.filter((item, i) => {
          return index !== i;
        });
        setSemesterData(dummyData);
        message.success("Deleted Successfully");
        setLoading(false);
      })
      .catch((err) => {
        message.error("Something went wrong, please try again", 3);
        setLoading(false);
      });
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////
  ///                             handle Input
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const handleInputValueChange = ({ target }) => {
    let dataIndex =
      newSubjectsData.length +
      index -
      semesterData[semesterIndex].syllabus.length;
    if (dataIndex >= 0) {
      const dummyNewData = [...newSubjectsData];
      dummyNewData[dataIndex][target.name] = target.value;
      setNewSubjectsData(dummyNewData);
    }
    const originalData = [...semesterData];
    originalData[semesterIndex].syllabus[index][target.name] = target.value;
    setSemesterData(originalData);
  };
  return (
    <>
      <Form onFinish={handleSectionFinish}>
        <div key={index}>
          <div className="d-flex justify-content-start">
            <div className="accordion-detail semester-name">
              <div className="f-12 fw-400 faculty-heading">
                {trans.Coursename}
              </div>
              <Form.Item name="courseName" initialValue={data.courses}>
                <Input
                  disabled={edit || data.new ? "" : "disabled"}
                  onChange={handleInputValueChange}
                  name="courses"
                  type="text"
                  placeholder={trans.Typehere}
                />
              </Form.Item>
            </div>
            <div className="accordion-detail semester-hours ">
              <div className="f-12 fw-400 faculty-heading">
                {trans.Credithours}
              </div>
              <Form.Item name="courseCredits" initialValue={data.credit_hours}>
                <Input
                  disabled={edit || data.new ? "" : "disabled"}
                  onChange={handleInputValueChange}
                  name="credit_hours"
                  type="text"
                  placeholder={trans.Typehere}
                />
              </Form.Item>
            </div>
            <div
              className="d-inline-block mt-3 semester-delete-icon cursor-pointer"
              onClick={() =>
                data.new ? handleNewCourseDelete() : handleCourseDelete()
              }
            >
              <ClearIcon style={{ marginTop: 15 }} fontSize="small" />
            </div>
          </div>
        </div>
      </Form>
    </>
  );
};
