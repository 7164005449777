import Editor from "../../Components/common/Editor";
import CloseIcon from "@material-ui/icons/Close";
import {useState} from "react";
import {Form, Input, Button, Card, message} from "antd";
import trans from "./../../../Languages/Languages";
import React, {useEffect} from "react";
import API from "../../../API";
import EditIcon from "@material-ui/icons/Edit";
const StudyOverview = () => {
  message.config({
    top: 80,
  });
  const [studyOverviewData, setStudyOverviewData] = useState([]);
  const [newData, setNewData] = useState([]);
  const handleAddSubject = () => {
    const dummyData = [
      ...studyOverviewData,
      {
        course: "",
        subject: "",
        credit_hours: "",
        new: true,
      },
    ];
    setStudyOverviewData(dummyData);
    const newDummyData = [
      ...newData,
      {
        course: "",
        subject: "",
        credit_hours: "",
        new: true,
      },
    ];
    setNewData(newDummyData);
  };

  const [studyEditorHtml, setStudyEditorHtml] = useState("");

  const handleStudyEditorChange = (html) => {
    setStudyEditorHtml(html);
  };
  const [summaryEditorHtml, setSummaryEditorHtml] = useState("");

  const handleSummaryEditorChange = (html) => {
    setSummaryEditorHtml(html);
  };
  const [loading, setLoading] = useState(false);
  //
  const [refresh, setRefresh] = useState(false);
  ///////////////////////////////////////////////////////////////////////////////////////////////
  ///                             Use Effect
  ////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    setLoading(true);

    const init = async () => {
      await API.get("/admin/overview")
        .then((res) => {
          setStudyOverviewData(res.data);
        })
        .catch((err) => {});
    };

    init();
    const about = async () => {
      await API.get("/admin/overview/about")
        .then((res) => {
          setStudyEditorHtml(res?.data?.overview);
          setSummaryEditorHtml(res?.data?.summary);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    };

    about();
  }, [refresh]);
  //////////////////////////////////////////////////////////////////////////////////////////////
  ///                             update about section
  ////////////////////////////////////////////////////////////////////////////////////////////////
  const handleAboutSectionSave = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("overview", studyEditorHtml);
    formData.append("summary", summaryEditorHtml);

    await API.post(`/admin/overview/about/update`, formData)
      .then((res) => {
        setRefresh(!refresh);
        setLoading(false);
        message.success("Section Updated Successfully", 3);
      })
      .catch((err) => {
        message.error("Something went wrong, please try again", 3);
        setLoading(false);
      });
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////
  ///                             create new records
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const handleCreateNew = async () => {
    // setLoading(true);
    if (newData.length > 0) {
      const formData = new FormData();
      newData.forEach((element, i) => {
        // if (element.image === "" || element.subject === "") {
        //   error = true;
        // }
        formData.append(`courses[${i}][course]`, element.course);
        formData.append(`courses[${i}][subject]`, element.subject);
        formData.append(`courses[${i}][credit_hours]`, element.credit_hours);
      });

      await API.post("/admin/overview/create", formData)
        .then((res) => {
          setLoading(false);
          message.success("Created Successfullyy", 3);
          setNewData([]);
          setRefresh(!refresh);
        })
        .catch((err) => {
          setLoading(false);
          if (err.response.status === 400)
            message.error("Value must be an Integer");
          else message.error("something went wrong please try again", 3);
        });
    }
  };

  return (
    <div className='pages-container'>
      <div className='content-wrapper'>
        <Card loading={loading} bordered={false}>
          <div className='bg-content-container '>
            {/* study overview */}
            <div className='fw-600 mb-2'>{trans.Studyoverview}</div>
            <Editor
              key='studyOverview11s'
              editorHtml={studyEditorHtml}
              setEditorHtml={setStudyEditorHtml}
              handleChange={handleStudyEditorChange}
            />
            {/* In summary each module comprises of */}
            <div className='fw-600 mb-2 mt-4'>
              {trans.Insummaryeachmodulecomprisesof}
            </div>
            <Editor
              key='InSummaryEachs'
              editorHtml={summaryEditorHtml}
              setEditorHtml={setSummaryEditorHtml}
              handleChange={handleSummaryEditorChange}
            />
            <div className='d-flex justify-content-end save-section'>
              {/* save changes */}
              <div
                className='btn-save-section fw-500'
                onClick={handleAboutSectionSave}
              >
                <span>{trans.Savesection}</span>
              </div>
            </div>
          </div>

          <>
            {/* semester details content */}
            <div className='fw-400 mb-2'>
              {trans.Currentlysemester1ismadeupoffollowingsubjects}
              <div className='bg-content-container bg-semester-subjects'>
                <div className='row'>
                  <div className='col-5 f-12 fw-500'>{trans.Course}</div>
                  <div className='col-5 f-12 fw-500'>{trans.Subject}</div>
                  <div className='col-2 f-12 fw-500'>{trans.Credithours}</div>
                </div>
              </div>
              {studyOverviewData.map((item, index) => {
                return (
                  <Subjects
                    data={item}
                    originalData={studyOverviewData}
                    setData={setStudyOverviewData}
                    index={index}
                    newData={newData}
                    setNewData={setNewData}
                  />
                );
              })}
              {/* end */}
              <div
                className='d-flex justify-content-center add-another-subject'
                onClick={handleAddSubject}
              >
                <span className='f-12 fw-500'>
                  {trans.Addnew}{" "}
                  <span className='f-20 fw-500 align-middle'>+</span>
                </span>
              </div>
            </div>
            {/* save changes */}

            {newData.length > 0 && (
              <Button
                onClick={handleCreateNew}
                className='btn-save-changes'
                type='primary'
                htmlType='submit'
              >
                {trans.Save}
              </Button>
            )}
          </>
        </Card>
      </div>
    </div>
  );
};

export default StudyOverview;

const Subjects = ({
  index,
  data,
  newData,
  setNewData,
  originalData,
  setData,
  refresh,
  setRefresh,
}) => {
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const handleInputValueChange = ({target}) => {
    // const dummyData = [...newData];
    // dummyData[index][target.name] = target.value;
    // setNewData(dummyData);

    if (newData.length + index - originalData.length >= 0) {
      const dummyNewData = [...newData];
      dummyNewData[newData.length + index - originalData.length][target.name] =
        target.value;
      setNewData(dummyNewData);
    }
  };
  const handleNewDelete = () => {
    const dummyData = originalData.filter((item, i) => {
      return index !== i;
    });
    setData(dummyData);
    let newIndex = newData.length + index - originalData.length;
    let newRecord = [];
    newRecord = newData.filter((item, i) => {
      return newIndex !== i;
    });
    setNewData(newRecord);
  };
  const handleDelete = async () => {
    setLoading(true);

    await API.post(`/admin/overview/delete/${data.id}`)
      .then((res) => {
        setLoading(false);

        message.success("Deleted Successfully", 3);
        handleNewDelete();
      })
      .catch((err) => {
        message.error("Something went wrong, please try again", 3);
        setLoading(false);
      });
  };
  ///////////////////////////////////////////////////////////////////////////////////////////////
  ///                             update record
  ////////////////////////////////////////////////////////////////////////////////////////////////
  const handleSave = async (values) => {
    // setLoading(true);
    const formData = new FormData();
    formData.append("course", values.course);
    formData.append("subject", values.subject);
    formData.append("credit_hours", values.creditHours);
    await API.post(`/admin/overview/update/${data.id}`, formData)
      .then((res) => {
        message.success("Board Member Updated Successfully", 3);
        setEdit(false);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        Object.keys(err.response.data).map((e) => {
          message.error(err.response.data[e]);
        });
      });
  };
  return (
    <Card
      loading={loading}
      bordered={false}
      className='subjects-study-overview'
    >
      <Form onFinish={handleSave} initialValues={data}>
        <div className='bg-content-container bg-semester-details' key={index}>
          <div className='d-flex justify-content-between'>
            <div className='f-12 fw-500'>
              <div className='accordion-detail subject-course-input'>
                <Form.Item
                  name='course'
                  className='mb-0'
                  initialValue={data.course}
                >
                  <Input
                    name='course'
                    onChange={handleInputValueChange}
                    type='text'
                    className='w-100'
                    disabled={edit || data.new ? "" : "disabled"}
                  />
                </Form.Item>
              </div>
            </div>
            <div className=' f-12 fw-500'>
              <div className='accordion-detail subject-input'>
                <Form.Item
                  name='subject'
                  className='mb-0'
                  initialValue={data.subject}
                >
                  <Input
                    name='subject'
                    onChange={handleInputValueChange}
                    type='text'
                    className='w-100'
                    disabled={edit || data.new ? "" : "disabled"}
                  />
                </Form.Item>
              </div>
            </div>
            <div className=' f-12 fw-500'>
              <div className='accordion-detail subject-credit-input'>
                <Form.Item
                  name='creditHours'
                  className='mb-0'
                  initialValue={data.credit_hours}
                >
                  <Input
                    name='credit_hours'
                    onChange={handleInputValueChange}
                    type='text'
                    // className='w-100 i-number'
                    className='w-100'
                    disabled={edit || data.new ? "" : "disabled"}
                  />
                </Form.Item>
              </div>
            </div>
            <div className='f-12 fw-500 study-overview-action-container'>
              {/* <span onClick={data.new ? handleNewDelete : handleDelete}>
                <CloseIcon fontSize='small' className='ml-4 mt-1' />
              </span> */}

              {/* edit action */}
              <div style={{height: 10}}>
                {edit ? (
                  <div className='edit-action icon-container d-block mr-1'>
                    <span
                      className='f-12 fw-400 cancel cursor-pointer'
                      onClick={() => setEdit(false)}
                    >
                      {trans.Cancel}
                    </span>

                    <span className='save'>
                      <Button type='' htmlType='submit'>
                        {trans.Save}
                      </Button>
                    </span>
                  </div>
                ) : (
                  <div className='icon-container'>
                    {!data.new && (
                      <span onClick={() => setEdit(true)}>
                        <EditIcon
                          className='b-icon-edit cursor-pointer mr-3'
                          style={{fontSize: 15}}
                        />
                      </span>
                    )}
                    <CloseIcon
                      className='b-icon-close cursor-pointer'
                      style={{fontSize: 20}}
                      onClick={() => {
                        data.new ? handleNewDelete(index) : handleDelete(index);
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Form>
    </Card>
  );
};
