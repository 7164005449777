import React, { createContext, useContext } from "react";
import trans from "../Languages/Languages";

const LanguageContext = createContext();
export const useLanguage = () => useContext(LanguageContext);

export default function UserProvider(props) {
  const [language, setlanguage] = React.useState("en");
  const [dummy, setdummy] = React.useState(false);

  React.useEffect(() => {
    trans.setLanguage(language);
    // Update lang attribute of HTML tag
    document.documentElement.lang = language;
  }, [language]);

  const isRTL = React.useMemo(() => {
    switch (language) {
      case "en":
        return false;
      case "ar":
        return true;
      case "ur":
        return true;
      case "fr":
        return false;
      default:
        return false;
    }
  }, [language]);

  const updateLanguage = (current) => {
    trans.setLanguage(current);
    setlanguage(current);
    setdummy(!dummy);
  };

  return (
    <LanguageContext.Provider value={{ language, updateLanguage, isRTL }}>
      {props?.children}
    </LanguageContext.Provider>
  );
}
