import trans from "./../../../Languages/Languages";
import React, { useState } from "react";
import ImageIcon from "../../../assets/icons/image.svg";

const FutureImages = ({
  data,
  setData,
  futureImages,
  setFutureImages,
  index,
}) => {
  const inputReplace = React.createRef();
  const [isActionShown, setIsActionShown] = useState(false);

  const handleChange = (e) => {
    const dummyImages = [...futureImages];
    dummyImages[index].image = e.target.files[0];
    setFutureImages(dummyImages);

    const dummyData = { ...data };
    dummyData.images[index].image = URL.createObjectURL(e.target.files[0]);
    setData(dummyData);
  };

  const handleRemove = () => {
    const dummyImages = [...futureImages];
    dummyImages[index].image = "";
    setFutureImages(dummyImages);
    const dummyData = { ...data };
    dummyData.images[index].image = "";
    setData(dummyData);
  };

  const handleReplace = () => {
    inputReplace.current.click();
  };

  return (
    <div
      onMouseEnter={() => setIsActionShown(true)}
      onMouseLeave={() => setIsActionShown(false)}
      className={
        data?.images?.length > 0 && data?.images[index]?.image !== ""
          ? "future-image-upload-container uploaded"
          : "future-image-upload-container"
      }
      key={index}
    >
      {/* medium images */}
      <div className="future-image-upload medium-img">
        {isActionShown && (
          <div>
            {/* image action */}
            <div className="image-action-container">
              <div className="image-action d-flex justify-content-center">
                <div
                  className="f-9 fw-400 image-action-txt"
                  onClick={handleReplace}
                >
                  {trans.Replace}
                </div>
                <div className="image-action-divider mx-2"></div>
                <div
                  className="f-9 fw-400 image-action-txt"
                  onClick={handleRemove}
                >
                  {trans.Remove}
                </div>
              </div>
            </div>
          </div>
        )}
        <label className="vision-image-box" htmlFor={`futureImage${index}`}>
          {data?.images?.length > 0 && data?.images[index]?.image !== "" ? (
            <img
              src={
                futureImages[index].image === ""
                  ? data?.images[index]?.image
                  : URL.createObjectURL(futureImages[index].image)
              }
              alt=""
            />
          ) : (
            <div>
              <img src={ImageIcon} alt="" className="px-2" />
              <span className="f-12 fw-400">Upload Image</span>
            </div>
          )}

          <input
            ref={inputReplace}
            type="file"
            name="image1"
            id={`futureImage${index}`}
            style={{ display: "none" }}
            onChange={handleChange}
          />
        </label>
      </div>
      <span className="float-right f-img-size f-12 fw-400">
        {trans.Maxsize} : 5MB
      </span>
    </div>
  );
};

export default FutureImages;
