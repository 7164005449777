// globals enums

export const formatErrors = (errors) => {
  return Object.keys(errors).map((k) => {
    return {
      name: k,
      errors: [errors[k]],
      validateStatus: "error",
    };
  });
};

export const createURLById = {
  0: "/admin/interactive/lecture/create",
  1: "/admin/appendix/create",
  2: "/admin/student/guide/create",
  3: "/admin/institution-evidence-content/create",
};

export const updateURLById = {
  0: "/admin/interactive/lecture/update",
  1: "/admin/appendix/update",
  2: "/admin/student/guide/update",
  3: "/admin/institution-evidence-content/update",
};

export const deleteURLById = {
  0: "/admin/interactive/lecture/delete",
  1: "/admin/appendix/delete",
  2: "/admin/student/guide/delete",
  3: "/admin/institution-evidence-content/delete",
};
