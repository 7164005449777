import Editor from "../../Components/common/Editor";
import {Button, Input, Form, Card, message} from "antd";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import trans from "./../../../Languages/Languages";
import React, {useState, useEffect} from "react";
import API from "../../../API";

const OnlineFreeCourses = () => {
  const [editorHtml, setEditorHtml] = useState("");
  const [newData, setNewData] = useState([]);
  const [coursesData, setCoursesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(true);

  const handleTextEditorChange = (html) => {
    setEditorHtml(html);
  };

  const handleAddAnotherSubject = () => {
    setCoursesData([
      ...coursesData,
      {course_name: "", lecturer: "", access_link: "", new: true},
    ]);

    const newRecord = [
      ...newData,
      {course_name: "", lecturer: "", access_link: "", new: true},
    ];
    setNewData(newRecord);
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////
  ///                  Use Effect
  ////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    setLoading(true);

    const init = async () => {
      await API.get("/admin/freecourses")
        .then((res) => {
          setCoursesData(res.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    };

    init();

    const about = async () => {
      await API.get("/admin/freecourses/about")
        .then((res) => {
          setEditorHtml(res.data.about);
        })
        .catch((err) => {
          setLoading(false);
        });
    };

    about();
  }, [refresh]);

  ///////////////////////////////////////////////////////////////////////////////////////////////
  ///                             create new records
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const handleCreateNew = async () => {
    setLoading(true);
    if (newData.length > 0) {
      const formData = new FormData();
      newData.forEach((element, i) => {
        if (
          element.course_name === "" ||
          element.lecturer === "" ||
          element.access_link === ""
        ) {
        }
        formData.append(`course[${i}][course_name]`, element.course_name);
        formData.append(`course[${i}][lecturer]`, element.lecturer);
        formData.append(`course[${i}][access_link]`, element.access_link);
      });

      await API.post("/admin/freecourses/create", formData)
        .then((res) => {
          setNewData([]);
          setLoading(false);
          setRefresh(!refresh);
          message.success("Created successfully");
        })
        .catch((err) => {
          setLoading(false);
          message.error("Something went wrong, please try again.");
        });
    }
  };

  const handleSaveAboutUs = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("about", editorHtml);

    await API.post("/admin/freecourses/about/update", formData)
      .then((res) => {
        setRefresh(!refresh);
        setLoading(false);
        message.success("Created successfully");
      })
      .catch((err) => {
        setLoading(false);
        message.error("Something went wrong, please try again.");
      });
  };
  return (
    <div className='pages-container'>
      <Card bordered={false} loading={loading}>
        {coursesData && (
          <div className='content-wrapper'>
            <div className='bg-content-container'>
              <div className='fw-600 mb-2'>{trans.KIUFreecourselectures}</div>
              <Editor
                key='onlineFreeCoursesKIUs'
                editorHtml={editorHtml}
                setEditorHtml={setEditorHtml}
                handleChange={handleTextEditorChange}
              />
              <div className='d-flex justify-content-end save-section'>
                {/* save changes */}
                <div
                  className='btn-save-section fw-500'
                  onClick={handleSaveAboutUs}
                >
                  <span>{trans.Savesection}</span>
                </div>
              </div>
            </div>

            {coursesData.map((item, i) => {
              return (
                <Courses
                  key={i}
                  data={item}
                  index={i}
                  setLoading={setLoading}
                  refresh={refresh}
                  setRefresh={setRefresh}
                  newData={newData}
                  setNewData={setNewData}
                  coursesData={coursesData}
                  setCoursesData={setCoursesData}
                />
              );
            })}
            <div className='d-flex justify-content-center'>
              <span className='f-12 fw-500 '>
                <span className='mr-2 cursor-pointer' onClick={handleAddAnotherSubject}>
                  {trans.Addnewsubject}
                </span>
                <span className='f-18 fw-500 align-middle'>+</span>
              </span>
            </div>
            {/* save changes */}

            {newData.length > 0 && (
              <Button
                onClick={handleCreateNew}
                className='btn-save-changes'
                type='primary'
                htmlType='submit'
              >
                {trans.Save}
              </Button>
            )}
          </div>
        )}
      </Card>
    </div>
  );
};

export default OnlineFreeCourses;

const Courses = ({
  data,
  index,
  setLoading,
  refresh,
  setRefresh,
  coursesData,
  setCoursesData,
  newData,
  setNewData,
}) => {
  const [edit, setEdit] = useState(false);

  const handleRemoveAnotherSubject = async (index) => {
    setLoading(true);

    await API.post(`/admin/freecourses/delete/${data.id}`)
      .then((res) => {
        setNewData([]);
        setRefresh(!refresh);
        setLoading(false);
        message.success("Deleted Successfully");
      })
      .catch((err) => {
        setLoading(false);
        message.error("Something went wrong, please try again.");
      });
  };

  const handleRemoveNewSubject = () => {
    const dummyData = coursesData.filter((item, i) => {
      return index !== i;
    });

    setCoursesData(dummyData);
    let newIndex = newData.length + index - coursesData.length;
    let newRecord = newData.filter((item, i) => {
      return newIndex !== i;
    });
    setNewData(newRecord);
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////
  ///                             update record
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const onFinish = async (values) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("course_name", values.courseName);
    formData.append("lecturer", values.lecturer);
    formData.append("access_link", values.accessLink);

    await API.post(`/admin/freecourses/update/${data.id}`, formData)
      .then((res) => {
        setRefresh(!refresh);
        setLoading(false);
        message.success("Update successfully");
      })
      .catch((err) => {
        setLoading(false);
        message.error("Something went wrong, please try again.");
      });
  };

  let rules = [
    {
      required: true,
      message: "field is required",
    },
  ];
  const handleInputValueChange = ({target}) => {
    if (newData.length + index - coursesData.length >= 0) {
      const dummyNewData = [...newData];
      dummyNewData[newData.length + index - coursesData.length][target.name] =
        target.value;
      setNewData(dummyNewData);
    }
  };
  return (
    <>
      <div className='fw-600 mb-2'>{trans.Courses}</div>
      <div className='bg-content-container b-container'>
        <Form onFinish={onFinish}>
          {/* edit action */}
          <div style={{height: 10}}>
            {edit ? (
              <div className='edit-action icon-container d-block mr-1'>
                <span
                  className='f-12 fw-400 cancel cursor-pointer'
                  onClick={() => setEdit(false)}
                >
                  {trans.Cancel}
                </span>

                <span className='save'>
                  <Button type='primary' htmlType='Submit'>
                    {trans.Save}
                  </Button>
                </span>
              </div>
            ) : (
              <div className='icon-container'>
                {!data.new && (
                  <span onClick={() => setEdit(true)}>
                    <EditIcon className='b-icon-edit' />
                  </span>
                )}
                <CloseIcon
                  className='b-icon-close'
                  onClick={() => {
                    data.new
                      ? handleRemoveNewSubject(index)
                      : handleRemoveAnotherSubject(index);
                  }}
                />
              </div>
            )}
          </div>
          <div className='d-flex justify-content-md-start'>
            <div className='accordion-detail free-courses-name'>
              <div className='f-12 fw-500 input-heading'>
                {trans.Coursename}
              </div>
              <Form.Item
                rules={rules}
                name='courseName'
                initialValue={data.course_name}
              >
                <Input
                  name='course_name'
                  onChange={handleInputValueChange}
                  disabled={edit || data.new ? "" : "disabled"}
                  placeholder={trans.Typehere}
                />
              </Form.Item>
            </div>
            <div className='accordion-detail free-courses-lecturer'>
              <div className='f-12 fw-500 input-heading'>{trans.Lecturer}</div>
              <Form.Item
                rules={rules}
                name='lecturer'
                initialValue={data.lecturer}
              >
                <Input
                  onChange={handleInputValueChange}
                  name='lecturer'
                  handleInputValueChangehandleInputValueChange
                  disabled={edit || data.new ? "" : "disabled"}
                  placeholder={trans.Typehere}
                />
              </Form.Item>
            </div>
          </div>
          <div className='accordion-detail free-courses-access'>
            <div className='f-12 fw-500 input-heading'>{trans.Accesslink}</div>
            <Form.Item
              rules={rules}
              name='accessLink'
              initialValue={data.access_link}
            >
              <Input
                name='access_link'
                onChange={handleInputValueChange}
                disabled={edit || data.new ? "" : "disabled"}
                placeholder={trans.Typehere}
              />
            </Form.Item>
          </div>
        </Form>
      </div>
    </>
  );
};
