import React, {useEffect, useState} from "react";
import {Box} from "@material-ui/core";
import {Button, Col, Input, message, Row, Typography} from "antd";
import {PlusCircleOutlined, SearchOutlined} from "@ant-design/icons";
import UserCard from "./../Components/Users/UserCard";
import AddUser from "./Add";
import trans from "./../../Languages/Languages";
import {useLanguage} from "../../Context/LanguageContext";
import API from "../../API";

const createUserStyle = {
  display: "flex",
  alignItems: "center",
};
function Users() {
  const {isRTL} = useLanguage();
  const [isModalVisible, setIsModalVisible] = useState(false);
    const [refresh, setRefresh] = useState(true);
  const [users,setUsers] = useState([]);

  useEffect(()=> {
       (function (){
          API.get('/admin/user').then(res=>{
              setUsers(res.data);
          }).catch(error=>{
              if(error.response.status === 400){
                  message.error('Something went wrong. please refresh');
              }else if(error.response.status === 500){
                  message.error('An internal server error occurred');
              }
          })
      })();
  },[refresh]);

  return (
    <div>
      <AddUser
          refresh={refresh}
          setRefresh={setRefresh}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />
      <div className='s-22'/>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingLeft: 16,
          paddingRight: 16,
        }}
      >
        <Typography.Title className='f-20 fw-600'>
          {trans.Users}
        </Typography.Title>
        <Box display='flex'>
          <Button
            type='text'
            className='f-14 fw-500'
            style={createUserStyle}
            onClick={() => setIsModalVisible(true)}
          >
            {trans.CreateUser}
            <PlusCircleOutlined className={isRTL ? "f-23 mx-2" : "f-23"} />
          </Button>
          <Input
            size='small'
            placeholder={trans.Search}
            suffix={<SearchOutlined className='f-20' />}
          />
        </Box>
      </div>
      <div className='s-22'/>
      <Row gutter={[32, 22]} className='mx-auto'>
        {users.map((user,i) => {
          return (
            <Col sm={24} lg={8} xl={6} key={i}>
              <UserCard user={user} refresh={refresh} setRefresh={setRefresh} />
            </Col>
          );
        })}
      </Row>
    </div>
  );
}

export default Users;
