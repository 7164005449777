import AvatarImage from "../../../assets/avatar/avatar.jpg";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import {Upload, Button, Form, Input, message} from "antd";
import trans from "./../../../Languages/Languages";
import React, {useMemo, useState} from "react";
import {useUserContext} from "../../../Context/UserContext";
import API from "../../../API";

const {Item} = Form;
const MyProfile = () => {
    const {getUser} = useUserContext();
    const user = useMemo(() => {
        return getUser().user ?? null
    }, [getUser])
    const [errors, setErrors] = useState({});
    const handleUpdate = (values) => {
      API.post(`/admin/user/update/profile/${user.id}`, values)
        .then((r) => {
          message.success(r.data.success);
        })
        .catch((e) => {
          if (e.response.status === 400) {
            setErrors(e.response.data);
          }
        });
    }
    return (
       user && <Form initialValues={user} onFinish={handleUpdate}>
            <div className='my-profile-container'>
                <div className='f-20 fw-600'>{trans.MyProfile}</div>
                <div className='my-profile-content'>
                    <div className='f-17 fw-600'>{trans.PersonalInformation}</div>
                    <div className='d-flex justify-content-start flex-wrapper'>
                        <div className='my-profile-img-container'>
                            {/* image */}
                            <div
                                className='my-profile-img'
                                style={{background: `url(${AvatarImage})`}}
                            />
                            <div className='mp-upload-img'>
                                <CameraAltIcon/>
                                <Upload>
                                    <Button>{trans.Uploadimage}</Button>
                                </Upload>
                            </div>
                        </div>
                        {/*  */}
                        <div className='my-profile-input-container'>
                            <div className='my-profile-input'>
                                <div className='f-12 fw-500 mp-input-heading'>{trans.Name}</div>
                                <Item name={"name"} help={errors.name} validateStatus={errors.name ? "error" : ""}>
                                    <Input type='text'/>
                                </Item>
                            </div>
                            <div className='my-profile-input'>
                                <div className='f-12 fw-500 mp-input-heading mt-18'>
                                    {trans.Email}
                                </div>
                                <Item name={"email"} help={errors.email} validateStatus={errors.email ? "error" : ""}>
                                    <Input type='text'/>
                                </Item>
                            </div>
                            <div className='my-profile-input'>
                                <div className='f-12 fw-500 mp-input-heading mt-18'>
                                    {trans.Password}
                                </div>
                                <Input type='password' disabled/>
                            </div>
                        </div>
                        {/*  */}
                        <div>
                            <div className='my-profile-input'>
                                <div className='f-12 fw-500 mp-input-heading'>
                                    {trans.UserName}
                                </div>
                                <Item name={"user_name"} help={errors.user_name}
                                      validateStatus={errors.user_name ? "error" : ""}>
                                    <Input type='text'/>
                                </Item>
                            </div>
                            <div className='my-profile-input'>
                                <div className='f-12 fw-500 mp-input-heading mt-18'>
                                    {trans.PhoneNumber}
                                </div>
                                <Item name={"phone_number"} validateStatus={errors.phone_number ? "error" : ""}
                                      help={errors.phone_number}>

                                    <Input type='text'/>
                                </Item>
                            </div>
                            <div className='my-profile-input'>
                                <button className='btn f-14 fw-500'>
                                    {trans.ChangePassword}
                                </button>
                            </div>
                        </div>
                    </div>
                    {/* end flex */}
                    {/* save changes */}
                    <Item>
                        <button type={"submit"} className='btn-mp-save-changes'>
                            <span>{trans.Savechanges}</span>
                        </button>
                    </Item>
                </div>
            </div>
        </Form>
    );
};

export default MyProfile;
