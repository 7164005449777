import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import React, { useState, useEffect } from "react";
import FAQImage from "../../../assets/icons/faq-group-icon.svg";
import trans from "./../../../Languages/Languages";
import { Input, Form, Button, Card, message } from "antd";
import API from "../../../API";

const FAQ = () => {
  const [newData, setNewData] = useState([]);
  const [FAQData, setFAQData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);

  const handleAnotherFAQ = () => {
    const dummyData = [...FAQData, { question: "", answer: "", new: true }];
    setFAQData(dummyData);

    const newRecord = [...newData, { question: "", answer: "", new: true }];
    setNewData(newRecord);
  };

  // Use Effect
  useEffect(() => {
    setLoading(true);

    const init = async () => {
      await API.get("/admin/faqs")
        .then((res) => {
          setFAQData(res.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    };

    init();
  }, [refresh]);

  // create new records
  const handleCreateNew = async () => {
    setLoading(true);
    if (newData.length > 0) {
      const formData = new FormData();
      newData.forEach((element, i) => {
        formData.append(`faq[${i}][question]`, element.question);
        formData.append(`faq[${i}][answer]`, element.answer);
      });

      await API.post("/admin/faqs/create", formData)
        .then((res) => {
          setLoading(false);
          setNewData([]);
          setRefresh(!refresh);
          message.success("FAQ Created Successfully.");
        })
        .catch((e) => {
          setLoading(false);
          message.error("Field is required.");
        });
    }
  };

  return (
    <div className="pages-container">
      <Card bordered={false} className="p-0" loading={loading}>
        <div className="content-wrapper">
          {FAQData.length > 0 &&
            FAQData.map((item, i) => {
              return (
                <div className="content-wrapper news-content">
                  <AddQuestion
                    key={i}
                    index={i}
                    data={item}
                    originalData={FAQData}
                    setData={setFAQData}
                    setLoading={setLoading}
                    newData={newData}
                    setNewData={setNewData}
                    refresh={refresh}
                    setRefresh={setRefresh}
                  />
                </div>
              );
            })}
        </div>
        {/* add another */}
        <div className="w-100 d-flex justify-content-center">
          <div className="news-add-another" onClick={handleAnotherFAQ}>
            <span className="f-12 fw-500">
              {trans.Addanother}
              <span className="ml-1 f-20 fw-500 align-middle">+</span>
            </span>
          </div>
        </div>

        {/* save changes */}

        {newData.length > 0 && (
          <Button
            onClick={handleCreateNew}
            className="btn-save-changes"
            type="primary"
            htmlType="submit"
          >
            {trans.Save}
          </Button>
        )}
      </Card>
    </div>
  );
};

export default FAQ;

const AddQuestion = ({
  data,
  index,
  originalData,
  setData,
  newData,
  setNewData,
  setLoading,
  refresh,
  setRefresh,
}) => {
  const [edit, setEdit] = useState(false);

  // update record
  const handleSectionSave = async (values) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("question", values.question);
    formData.append("answer", values.answer);

    await API.post(`/admin/faqs/update/${data.id}`, formData)
      .then((res) => {
        setRefresh(!refresh);
        setLoading(false);
        message.success("FAQ Updated Successfully.");
      })
      .catch((err) => {
        setLoading(false);
        message.error("Field is required.");
      });
  };

  //delete record
  const handleNewFAQDelete = (index) => {
    const dummyData = originalData.filter((item, i) => {
      return index !== i;
    });
    setData(dummyData);
    let newIndex = newData.length + index - originalData.length;
    let newRecord = [];
    newRecord = newData.filter((item, i) => {
      return newIndex !== i;
    });
    setNewData(newRecord);
  };

  const handleFAQDelete = async (index) => {
    setLoading(true);

    await API.post(`/admin/faqs/delete/${data.id}`)
      .then((res) => {
        setRefresh(!refresh);
        setLoading(false);
        message.success("FAQ Deleted Successfully.");
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const handleInputValueChange = ({ target }) => {
    if (newData.length + index - originalData.length >= 0) {
      const dummyNewData = [...newData];
      dummyNewData[newData.length + index - originalData.length][target.name] =
        target.value;
      setNewData(dummyNewData);
    }
  };
  return (
    <div className="bg-content-container faculty-container">
      <Form onFinish={handleSectionSave}>
        {/* edit action */}
        <div style={{ height: 10 }}>
          {edit ? (
            <div className="edit-action icon-container d-block mr-1">
              <span
                className="f-12 fw-400 cancel cursor-pointer"
                onClick={() => setEdit(false)}
              >
                {trans.Cancel}
              </span>

              <span className="save">
                <Button type="" htmlType="submit">
                  {trans.Save}
                </Button>
              </span>
            </div>
          ) : (
            <div className="icon-container">
              {!data.new && (
                <span onClick={() => setEdit(true)}>
                  <EditIcon className="b-icon-edit cursor-pointer" />
                </span>
              )}
              <CloseIcon
                className="b-icon-close cursor-pointer"
                onClick={() => {
                  data.new ? handleNewFAQDelete(index) : handleFAQDelete(index);
                }}
              />
            </div>
          )}
        </div>
        <div className="d-flex justify-content-center">
          <img src={FAQImage} alt="" />
        </div>
        <div className="accordion-detail ">
          <div className="f-12 fw-500 faculty-heading">{trans.Question}</div>
          <Form.Item name="question" initialValue={data.question}>
            <Input
              disabled={edit || data.new ? "" : "disabled"}
              name="question"
              onChange={handleInputValueChange}
              type="text"
            />
          </Form.Item>
        </div>
        <div className="accordion-detail ">
          <div className="f-12 fw-500 faculty-heading">{trans.Answer}</div>
          <Form.Item name="answer" initialValue={data.answer}>
            <Input
              disabled={edit || data.new ? "" : "disabled"}
              name="answer"
              onChange={handleInputValueChange}
              type="text"
            />
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};
