import CloseIcon from "@material-ui/icons/Close";
import { Upload, message } from "antd";
import trans from "../../../Languages/Languages";
import React, { useEffect, useState } from "react";
import API from "../../../API";
import clsx from "clsx";
import { deleteURLById, updateURLById } from "../../../Constants/globals";

const AccordionContentById = ({
  data,
  handleDelete,
  setData,
  index,
  originData,
  setLoading,
  newData,
  setNewData,
  refresh,
  setRefresh,
  id,
}) => {
  message.config({
    top: 80,
  });
  const [focused, setFocused] = useState(true);
  const [showSuccess, setShowSuccess] = useState(false);

  ///////////////////////////////////////////////////////////////////////////////////////////////
  ///                  Use Effect
  ////////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (!focused && data.file !== "" && data.title !== "" && !data.new) {
      const formData = new FormData();
      formData.append("institution_evidence_id", data.institution_evidence_id);
      formData.append("title", data.title);
      formData.append("file", data.file);

      let updateUrl = updateURLById[3];

      API.post(`${updateUrl}/${data.id}`, formData)
        .then((res) => {
          message.success("Updated Successfully", 1);
        })
        .catch((err) => {
          message.error("Something went wrong, please try again", 1);
        });
    }
  }, [focused, id, data.title, data.file, data.id, data.new]);

  ///////////////////////////////////////////////////////////////////////////////////////////////
  ///                  Methods
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const handleChange = (info) => {
    const dummyData = [...originData];
    dummyData[index].file = info.file.originFileObj;
    setData(dummyData);

    if (newData.length > 0) {
      const newDummyData = [...newData];
      newData[newData.length + index - originData.length].file =
        info.file.originFileObj;
      setNewData(newDummyData);
    }
  };

  const handleTitleChange = ({ target }) => {
    const dummyData = [...originData];
    dummyData[index].title = target.value;
    setData(dummyData);

    if (newData.length > 0) {
      const newDummyData = [...newData];
      newData[newData.length + index - originData.length].title = target.value;
      setNewData(newDummyData);
    }
  };

  const removeFile = () => {
    const newData = [...originData];
    newData[index].file = "";
    setData(newData);
  };

  const onFocus = () => {
    setFocused(true);
  };

  const onBlur = () => {
    setFocused(false);
    if (data.title !== "" && data.file !== "") {
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
      }, 1000);
    } else {
      setShowSuccess(false);
    }
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////
  ///                             delete record
  ////////////////////////////////////////////////////////////////////////////////////////////////
  const handleDeleteRecord = async () => {
    setNewData(newData.filter((item) => item.id !== data.id));

    setLoading(true);

    let deleteUrl = deleteURLById[3];

    await API.post(`${deleteUrl}/${data.id}`)
      .then((res) => {
        message.success("Deleted Successfully");
        setRefresh(!refresh);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <div className="mb-3">
      <div>
        <input
          type="text"
          name="title"
          placeholder={trans.Typehere}
          value={data.title}
          onChange={handleTitleChange}
          onFocus={onFocus}
          onBlur={onBlur}
        />
        <Upload
          className="d-inline-block"
          onChange={handleChange}
          maxCount={1}
          onRemove={removeFile}
          showUploadList={false}
          customRequest=""
          disabled={data?.file !== "" ? true : false}
        >
          <span
            className={clsx(
              "btn f-12 fw-400 upload-file ml-2",
              data?.file !== "" && "disabled-text"
            )}
          >
            {trans.Browse}
          </span>
          {/* {data.file === "" ? (
            <span className="btn f-12 fw-400 upload-file ml-2">Upload</span>
          ) : (
            <span className="btn f-12 fw-400 upload-file ml-2">Replace</span>
          )} */}
        </Upload>

        <span>
          <CloseIcon
            fontSize="small"
            className="ml-3 cursor-pointer"
            onClick={() => {
              data.new ? handleDelete(index) : handleDeleteRecord(index);
            }}
          />
        </span>
        {showSuccess && !focused && !data.new ? (
          <span>
            <span>
              <div className="alert alert-success d-inline-block f-10 py-1 ml-3">
                Record Updated
              </div>
            </span>
          </span>
        ) : (
          ""
        )}
        {/* save changes */}
      </div>

      {data.file && (
        <a
          href={data.file}
          target="_blank"
          rel="noreferrer"
          style={{ color: "#079751 !important" }}
        >
          Download File
        </a>
      )}
    </div>
  );
};

export default AccordionContentById;
