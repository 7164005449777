import React from "react";
import {Redirect, Route} from "react-router-dom";
import {useUserContext} from "../../../Context/UserContext";

function PrivateRoute({component, ...props}) {
  // eslint-disable-next-line
  const { isLogin } = useUserContext();

  return (
    <>
      {isLogin() ? (
        <Route {...props} render={() => component} />
      ) : (
        <Redirect to="/login" />
      )}
    </>
  );
}

export default PrivateRoute;
