import {DatePicker, Space} from "antd";
import trans from "./../../../Languages/Languages";
import {useState, useEffect} from "react";
import {Button, Card, message, Form} from "antd";
import API from "../../../API";
import moment from "moment";

const AdmissionDeadline = () => {
  message.config({
    top: 80,
  });

  const admissionDate = [
    { name: trans.AutumnFallsemester },
    { name: trans.Springsemester },
  ];

  const [admissionDeadlineData, setAdmissionDeadlineData] = useState();
  const [toRefreshData, setToRefreshData] = useState(false);
  const [loading, setLoading] = useState(true);

  //
  ///////////////////////////////////////////////////////////////////////////////////////////////
  ///                             Use Effect
  ////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    setLoading(true);

    const init = async () => {
      await API.get("/admin/admissions/deadline")
        .then((res) => {
          setAdmissionDeadlineData(res.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    };

    init();
  }, [toRefreshData]);
  ///////////////////////////////////////////////////////////////////////////////////////
  ///                             update
  ///////////////////////////////////////////////////////////////////////////////////////

  const handleSave = (values) => {
    setLoading(true);
    const formData = new FormData();
    formData.append(
      "fall_starting_date",
      moment(values.fallStartingDate).format("YYYY-MM-DD")
    );
    formData.append(
      "spring_starting_date",
      moment(values.springStartingDate).format("YYYY-MM-DD")
    );
    formData.append(
      "fall_last_date",
      moment(values.fallLastDate).format("YYYY-MM-DD")
    );
    formData.append(
      "spring_last_date",
      moment(values.springLastDate).format("YYYY-MM-DD")
    );
    formData.append(
      "fall_final_exams",
      moment(values.fallFinalExam).format("YYYY-MM-DD")
    );
    formData.append(
      "spring_final_exams",
      moment(values.springFinalExam).format("YYYY-MM-DD")
    );

    API.post("/admin/admissions/deadline/update", formData)
      .then((res) => {
        setLoading(false);
        setToRefreshData(!toRefreshData);
        message.success("Section Updated Successfully", 3);
      })
      .catch((err) => {
        setLoading(false);
        message.error("Something went wrong, please try again", 3);
      });
  };

  return (
    <div className="pages-container admission-deadline">
      <Card loading={loading} bordered={false}>
        <div className="content-wrapper">
          <div className="fw-600 h-heading ">{trans.Admissionsdeadline}</div>
          {admissionDeadlineData && (
            <Form onFinish={handleSave}>
              {admissionDate.map((ad, i) => {
                return (
                  <Deadline ad={ad} i={i} record={admissionDeadlineData} />
                );
              })}

              {/* save changes */}

              <Button
                className="btn-save-changes"
                type="primary"
                htmlType="submit"
              >
                {trans.Save}
              </Button>
            </Form>
          )}
        </div>
      </Card>
    </div>
  );
};

export default AdmissionDeadline;

const Deadline = ({record, i, ad}) => {
  let name = ["fall", "spring"];

  return (
    <div className='bg-content-container' key={i}>
      <div className='f-12 fw-500 h-heading '>{ad.name}</div>
      {/* Autumn date */}
      <div className='d-flex justify-content-start events-date-time'>
        {/* starting date */}
        <div className='accordion-detail p-date date-mr'>
          <div className='f-12 fw-500 ad-date-m'>{trans.Startingdate}</div>
          <Space direction='vertical'>
            <Form.Item
              name={`${name[i]}StartingDate`}
              initialValue={moment(record[`${name[i]}_starting_date`])}
            >
              <DatePicker />
            </Form.Item>
          </Space>
        </div>
        {/* Last date of submission date */}
        <div className='accordion-detail p-date date-mr'>
          <div className='f-12 fw-500 ad-date-m'>
            {trans.Lastdateofsubmission}
          </div>
          <Space direction='vertical'>
            <Form.Item
              name={`${name[i]}LastDate`}
              initialValue={moment(record[`${name[i]}_last_date`])}
            >
              <DatePicker />
            </Form.Item>
          </Space>
        </div>
        {/* Final Exams */}
        <div className='accordion-detail p-date'>
          <div className='f-12 fw-500 ad-date-m'>{trans.FinalExams}</div>
          <Space direction='vertical'>
            <Form.Item
              name={`${name[i]}FinalExam`}
              initialValue={moment(record[`${name[i]}_final_exams`])}
            >
              <DatePicker />
            </Form.Item>
          </Space>
        </div>
      </div>
    </div>
  );
};
