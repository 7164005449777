import React, { useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Logo from "../../assets/logo/slide_logo-KIU.png";
import { ReactComponent as DashboardIcon } from "../../assets/icons/dashboard.svg";
import { ReactComponent as FileIcon } from "../../assets/icons/file.svg";
import { ReactComponent as UserIcon } from "../../assets/icons/users.svg";
import { Select } from "antd";
import { HashRouter as Router, Link } from "react-router-dom";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { useLanguage } from "./../../Context/LanguageContext";
import trans from "./../../Languages/Languages";
import SubSideBar from "../Pages/Index";
import { useUserContext } from "../../Context/UserContext";
const drawerWidth = 204;
const LanguageDropdownStyle = {
  width: 140,
  zIndex: 99999999,
};
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    // transition: theme.transitions.create(["margin", "width"], {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.leavingScreen,
    // }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    "[dir='rtl'] &": {
      marginRight: drawerWidth,
      marginLeft: "0px !important",
      left: "0px !important",
      right: "auto !important",
    },

    // transition: theme.transitions.create(["margin", "width"], {
    //   easing: theme.transitions.easing.easeOut,
    //   duration: theme.transitions.duration.enteringScreen,
    // }),
    minHeight: 70,
    backgroundColor: "white",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    height: "100vh",
    width: drawerWidth,
    flexShrink: 0,
    backgroundColor: "#23282C",
  },

  drawerPaper: {
    backgroundColor: "#23282C",
    width: drawerWidth,
    "& *": {
      color: "#7A7A7A",
    },
    "& ul": {
      marginTop: 75,
    },
    "& .MuiButtonBase-root": {
      paddingLeft: 40,
    },
    "& .MuiListItemIcon-root": {
      minWidth: 25,
    },
  },

  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    // transition: theme.transitions.create("margin", {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.leavingScreen,
    // }),
    paddingLeft: 27,
    marginTop: 70,
    paddingRight: 27,
    backgroundColor: "#F1F1F1",
  },
  contentShift: {
    // transition: theme.transitions.create("margin", {
    //   easing: theme.transitions.easing.easeOut,
    //   duration: theme.transitions.duration.enteringScreen,
    // }),
    marginLeft: 0,
  },
  logoArea: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: 70,
    backgroundColor: "white",
  },
  activeTab: {
    "& *": {
      color: "#FFF",
      fontSize: 18,
      fontWeight: 500,
    },
  },
}));

export default function Sidebar(props) {
  const { logout, getCollege, getUser } = useUserContext();
  const { updateLanguage, language, isRTL } = useLanguage();
  // const [currentLang, setCurrentLang] = React.useState(language);

  const handleLanguageChange = (value) => {
    updateLanguage(value);
    // setCurrentLang(value);
  };

  const SidebarItems = [
    { id: "item1", name: trans.Dashboard, icon: <DashboardIcon />, url: "/" },
    { id: "item2", name: trans.Pages, icon: <FileIcon />, url: "/pages/home" },
    // {id: "item3", name: "Gallery", icon: ImageIcon, url: "/gallery"},
    { id: "item4", name: trans.Users, icon: <UserIcon />, url: "/users" },
  ];

  useEffect(() => {
    // trans.setLanguage(language);
    sessionStorage.setItem(
      "asd",
      JSON.stringify({ username: "123", pass: "123" })
    );
  }, [language]);

  const GetCurrentTabOnRefresh = () => {
    switch (window.location.pathname.split("/")[1]) {
      case "":
        return "item1";
      case "pages":
        return "item2";
      case "users":
        return "item4";
      default:
        return "";
    }
  };

  let user = getUser()?.user ?? "admin";
  const classes = useStyles();
  const [open] = React.useState(true);
  const [pageon, setpageon] = React.useState(GetCurrentTabOnRefresh());

  return (
    <Router basename="/">
      {trans.setLanguage(language)}
      <div className={isRTL ? "rtl" : ""}>
        <div className={classes.root} dir={isRTL ? "rtl" : "ltr"}>
          <CssBaseline />
          <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: open,
            })}
          >
            <Toolbar className="d-flex justify-content-between">
              <div>
                <Select
                  className="portal-select"
                  defaultValue={getCollege()}
                  dropdownStyle={LanguageDropdownStyle}
                  style={LanguageDropdownStyle}
                  // onChange={handleLanguageChange}
                  disabled
                  suffixIcon={
                    <KeyboardArrowDownIcon
                      className="select-option-icon"
                      fontSize="small"
                    />
                  }
                >
                  <Select.Option key="1" value="en">
                    English Portal
                  </Select.Option>
                  <Select.Option key="2" value="ar">
                    Arabic Portal
                  </Select.Option>
                  <Select.Option key="3" value="ur">
                    Urdu Portal
                  </Select.Option>
                  <Select.Option key="4" value="fr">
                    French Portal
                  </Select.Option>
                </Select>
              </div>
              {/* user menu dropdown */}
              <div className="user-menu-container d-flex align-items-center">
                <Select
                  className="app-bar-select-option mx-2"
                  defaultValue={language}
                  dropdownStyle={LanguageDropdownStyle}
                  style={LanguageDropdownStyle}
                  onChange={handleLanguageChange}
                  suffixIcon={
                    <KeyboardArrowDownIcon
                      className="select-option-icon"
                      fontSize="small"
                    />
                  }
                >
                  <Select.Option key="1" value="en">
                    English
                  </Select.Option>
                  <Select.Option key="2" value="ar">
                    Arabic
                  </Select.Option>
                  <Select.Option key="3" value="ur">
                    Urdu
                  </Select.Option>
                  <Select.Option key="4" value="fr">
                    French
                  </Select.Option>
                </Select>
                <div className="dropdown">
                  <button
                    className="btn"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                  >
                    <span className="user-action-icon ">
                      <AccountCircleIcon fontSize="small" />
                    </span>
                    <span className="f-16 fw-500 align-middle user-action-text">
                      {user?.user_name}
                    </span>
                    <span>
                      <KeyboardArrowDownIcon
                        className="select-option-icon"
                        fontSize="small"
                      />
                    </span>
                  </button>

                  <ul className="dropdown-menu">
                    <li>
                      <Link to="/my-profile" className="text-decoration-none">
                        <span className="dropdown-item" href="#">
                          {trans.YourProfile}
                        </span>
                      </Link>
                    </li>
                    <li onClick={logout}>
                      <span className="dropdown-item" href="#">
                        {trans.Logout}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </Toolbar>
          </AppBar>
          <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={open}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.logoArea}>
              <img src={Logo} width="128" height="42" alt="KIU" />
            </div>
            <Divider />
            <List>
              {SidebarItems.map((item, index) => (
                <Link
                  key={item.id}
                  to={item.url}
                  className="text-decoration-none"
                >
                  <ListItem
                    button
                    className={pageon === item.id ? classes.activeTab : ""}
                    onClick={() => setpageon(item.id)}
                  >
                    <ListItemIcon
                      className={pageon === item.id ? "page-icon-white" : ""}
                    >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={item.name} />
                  </ListItem>
                </Link>
              ))}
            </List>
          </Drawer>
          <main
            className={clsx(classes.content, {
              [classes.contentShift]: open,
            })}
          >
            {props.subPage ? <SubSideBar {...props} /> : props.component}
          </main>
        </div>
      </div>
    </Router>
  );
}
