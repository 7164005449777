import React from "react";
import {Button, Card, Input} from "antd";
import {EyeInvisibleOutlined, EyeTwoTone} from "@ant-design/icons";
import trans from "./../../Languages/Languages";

function ResetPassword() {
  const handleSubmit = (e) => {
  };
  return (
    <div className='login-wrapper'>
      <Card
        style={{maxWidth: 500, boxShadow: "0px 3px 6px #E3E3E3"}}
        bodyStyle={{padding: "86px 49px 86px 35px"}}
        className='mx-auto'
      >
        <div className='header-text'>
          <p className='f-27 fw-600  text-center'>{trans.ResetPassword}</p>
        </div>
        <div className='subheader-text'>
          <p className='f-13 text-muted text-center'>
            {trans.EnterYourNewPasswordToLogin}
          </p>
        </div>
        <div className='s-27'></div>
        <form onSubmit={handleSubmit}>
          <div style={{maxWidth: 293, margin: "auto"}}>
            <label htmlFor='newPassword'>{trans.NewPassword}</label>
            <Input.Password
              className='login-input'
              id='newPassword'
              name='new_password'
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </div>
          <div style={{maxWidth: 293, margin: "auto"}}>
            <label htmlFor='ConfirmPassword'>{trans.ConfirmPassword}</label>
            <Input.Password
              className='login-input'
              id='confirm_Password'
              name='confirm_password'
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </div>
          <div className='s-58'></div>
          <div className='d-flex justify-content-center mt-2'>
            <Button
              type='primary'
              htmlType='submit'
              style={{minWidth: 252, minHeight: 39}}
              className='f-16'
            >
              {trans.Confirm}
            </Button>
          </div>
          <div className='d-flex justify-content-center mt-2'>
            <Button
              type='text'
              htmlType='reset'
              style={{minWidth: 252, minHeight: 39}}
              className='f-16 text-muted'
            >
              {trans.Cancel}
            </Button>
          </div>
        </form>
      </Card>
    </div>
  );
}

export default ResetPassword;
