import React, { createContext, useContext, useState } from "react";

const UserContext = createContext();
export const useUserContext = () => useContext(UserContext);

export default function UserProvider(props) {
  const [user, setUser] = useState(
    sessionStorage.getItem("user")
      ? JSON.parse(sessionStorage.getItem("user"))
      : null
  );
  const login = (data) => {
    sessionStorage.setItem("user", JSON.stringify(data));
    setUser(data);
  };
  const logout = () => {
    sessionStorage.clear();
    setUser(null);
  };
  const getUser = () => {
    return user;
  };
  const isLogin = () => {
    return user?.token ? true : false;
  };
  // const token = () => {
  //   return user?.token ? user?.token : null;
  // };
  const getCollege = () => {
    return user?.user?.college ?? "ar";
  };
  return (
    <UserContext.Provider
      value={{ login, logout, getUser, isLogin, getCollege }}
    >
      {props?.children}
    </UserContext.Provider>
  );
}
