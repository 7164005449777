import React, { useState, useEffect } from "react";
import { Button, Form, Input, Card, message } from "antd";
import trans from "./../../../Languages/Languages";
import API from "../../../API";

const ContactUS = () => {
  const [loading, setLoading] = useState(true);
  const [contactUsData, setContactUsData] = useState();
  const [refresh, setRefresh] = useState(false);
  const [errors, setErrors] = useState({});

  // Use Effect
  useEffect(() => {
    setLoading(true);

    const init = async () => {
      await API.get("/admin/contactus")
        .then((res) => {
          setContactUsData(res.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    };

    init();
  }, [refresh]);

  // update record
  const handleSectionSave = async (values) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("po_box", values.poBox);
    formData.append("city", values.city);
    formData.append("email", values.email);
    formData.append("fax", values.fax);

    await API.post(`/admin/contactus/update`, formData)
      .then((res) => {
        setRefresh(!refresh);
        setLoading(false);
        setErrors({});
        message.success("Updated Successfully");
      })
      .catch((e) => {
        setErrors(e.response.data);
        setLoading(false);
      });
  };
  return (
    <div className="pages-container">
      <div className="content-wrapper contact-us-content">
        <div className="f-14 fw-600 mb-2">{trans.Contactus}</div>
        <Card bordered={false} className="p-0" loading={loading}>
          {contactUsData && (
            <Form onFinish={handleSectionSave}>
              <div className="f-12 fw-500 mb-3">
                {trans.KnowledgeInternationalUniversity}
              </div>
              {/* content */}
              <div className="bg-content-container mb-4">
                <div className="accordion-detail ">
                  <div className="f-12 fw-500 faculty-heading contact-main-heading">
                    {trans.AdministratorAddress}
                  </div>
                  <div className="d-flex justify-content-start align-items-baseline">
                    <div className="f-12 fw-500 faculty-heading contact-us-heading">
                      {trans.POBox}
                    </div>
                    <Form.Item name="poBox" initialValue={contactUsData.po_box}>
                      <Input type="text" />
                    </Form.Item>
                    {errors?.po_box && (
                      <span className="text-danger">{errors?.po_box}</span>
                    )}
                  </div>
                  <div className="d-flex justify-content-start align-items-baseline">
                    <div className="f-12 fw-500 faculty-heading contact-us-heading">
                      {trans.City}
                    </div>
                    <Form.Item name="city" initialValue={contactUsData.city}>
                      <Input type="text" />
                    </Form.Item>
                    {errors?.city && (
                      <span className="text-danger">{errors?.city}</span>
                    )}
                  </div>
                </div>
              </div>

              <div className="bg-content-container ">
                <div className="accordion-detail ">
                  <div className="f-12 fw-500 faculty-heading contact-main-heading">
                    {trans.KingdomofSaudiArabia}
                  </div>
                  <div className="d-flex justify-content-start align-items-baseline">
                    <div className="f-12 fw-500 faculty-heading contact-us-heading">
                      {trans.Email}
                    </div>
                    <Form.Item name="email" initialValue={contactUsData.email}>
                      <Input type="text" />
                    </Form.Item>
                    {errors?.email && (
                      <span className="text-danger">{errors?.email}</span>
                    )}
                  </div>
                  <div className="d-flex justify-content-start align-items-baseline">
                    <div className="f-12 fw-500 faculty-heading contact-us-heading">
                      {trans.Fax}
                    </div>
                    <Form.Item name="fax" initialValue={contactUsData.fax}>
                      <Input type="text" />
                    </Form.Item>
                    {errors?.fax && (
                      <span className="text-danger">{errors?.fax}</span>
                    )}
                  </div>
                </div>
              </div>
              {/* save changes */}

              <Button
                className="btn-save-changes"
                type="primary"
                htmlType="submit"
              >
                {trans.Save}
              </Button>
            </Form>
          )}
        </Card>
      </div>
    </div>
  );
};

export default ContactUS;
