import CloseIcon from "@material-ui/icons/Close";
import {message, Card, Button, Form, Input} from "antd";
import trans from "../../../Languages/Languages";
import React, { useEffect, useState } from "react";
import API from "../../../API";
import EditIcon from "@material-ui/icons/Edit";
import Editor from "../common/Editor";

const AccordionContent = () => {
    message.config({
        top: 80,
    });

    const [teachingData, setTeachingData] = useState([]);
    const [newData, setNewData] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const handleAnotherNews = () => {
        setLoading(true);
        const dummyData = [
            {
                heading: "",
                description: "",
                new: true,
            },
            ...teachingData,
        ];
        setTeachingData(dummyData);

        const newDummyData = [
            {
                heading: "",
                description: "",
                new: true,
            },
            ...newData,
        ];
        setNewData(newDummyData);

        setTimeout(() => {
            setLoading(false);
        }, 500);
    };

    const [loading, setLoading] = useState(true);

    // Use Effect
    useEffect(() => {
        setLoading(true);

        const init = async () => {
            await API.get("/admin/teaching")
                .then((res) => {
                    setTeachingData(res.data);
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                });
        };

        init();
    }, [refresh]);

    // create new records
    const handleCreateNew = async () => {
        setLoading(true);

        if (newData.length > 0) {
            const formData = new FormData();

            newData.forEach((element, i) => {
                formData.append(`teachings[${i}][heading]`, element.heading);
                formData.append(`teachings[${i}][description]`, element.description);
            });

            await API.post("/admin/teaching/create", formData)
                .then((res) => {
                    setNewData([]);
                    message.success("Teaching Methodology Created Successfully.");
                    setLoading(false);
                    setRefresh(!refresh);
                })
                .catch((e) => {
                    if (e.response.status === 400) {
                        message.error("Field is required");
                    }
                    setLoading(false);
                });
        }
    };

    return (
        <div className="pages-container">
            <Card bordered={false} className="p-0" loading={loading}>
                {newData.length > 0 && (
                    <div className="d-flex justify-content-end">
                        <div
                            className="btn-save-section fw-500 float-right my-3"
                            onClick={handleCreateNew}
                        >
                            <span>{trans.Save}</span>
                        </div>
                    </div>
                    // <Button
                    //     onClick={handleCreateNew}
                    //     className="btn-save-changes"
                    //     type="primary"
                    //     htmlType="submit"
                    // >
                    //     {trans.Save}
                    // </Button>
                )}

                {teachingData &&
                    teachingData?.map((item, i) => {
                        return (
                            <div className="content-wrapper" key={i}>
                                <AddHeading
                                    index={i}
                                    data={item}
                                    setData={setTeachingData}
                                    originalData={teachingData}
                                    refresh={refresh}
                                    setRefresh={setRefresh}
                                    setLoading={setLoading}
                                    newData={newData}
                                    setNewData={setNewData}
                                />
                            </div>
                        );
                    })}

                {/* add another */}
                <div className="w-100 d-flex justify-content-center">
                    <div className="news-add-another" onClick={handleAnotherNews}>
                        <span className="f-12 fw-500">
                          {trans.Addanother}
                            <span className="ml-1 f-20 fw-500 align-middle">+</span>
                        </span>
                    </div>
                </div>
            </Card>
        </div>
    );
};

export default AccordionContent;

const AddHeading = ({
                     data,
                     index,
                     originalData,
                     setData,
                     newData,
                     setNewData,
                     setLoading,
                     refresh,
                     setRefresh,
                 }) => {
    const [editorHtml, setEditorHtml] = useState("");

    const handleTextEditorChange = (html) => {
        setEditorHtml(html);
        if (index <= newData.length - 1) {
            const dummyNewData = [...newData];
            dummyNewData[index].description = html;
            setNewData(dummyNewData);
        }
    };

    const [edit, setEdit] = useState(false);

    useEffect(() => {
        setEditorHtml(data.description);
    }, [data]);

    // update record
    const handleSectionSave = async (values) => {
        setLoading(true);
        const formData = new FormData();
        formData.append("heading", values.heading);
        formData.append("description", editorHtml);

        await API.post(`/admin/teaching/update/${data.id}`, formData)
            .then((res) => {
                setRefresh(!refresh);
                setLoading(false);
                message.success("Teaching Methodology Updated Successfully");
            })
            .catch((e) => {
                setLoading(false);
                message.error("Field is required.");
            });
    };

    const handleInputValueChange = ({ target }) => {
        if (index >= 0 && newData.length > 0 && data.new) {
            const dummyNewData = [...newData];
            dummyNewData[index][target.name] = target.value;
            setNewData(dummyNewData);
        }
    };

    const handleNewTeachingDelete = (index) => {
        const dummyData = originalData.filter((item, i) => {
            return index !== i;
        });
        setData(dummyData);
        let newIndex = newData.length + index - originalData.length;
        let newRecord = [];
        newRecord = newData.filter((item, i) => {
            return newIndex !== i;
        });
        setNewData(newRecord);
    };
    ///////////////////////////////////////////////////////////////////////////////////////////////
    ///                             delete record
    ////////////////////////////////////////////////////////////////////////////////////////////////
    const handleTeachingDelete = async () => {
        setLoading(true);

        await API.post(`/admin/teaching/delete/${data.id}`)
            .then((res) => {
                setRefresh(!refresh);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    return (
        <div className="bg-content-container faculty-container ">
            <Form onFinish={handleSectionSave}>
                {/* edit action */}
                <div style={{ height: 10 }}>
                    {edit ? (
                        <div className="edit-action icon-container d-block mr-1">
                          <span
                              className="f-12 fw-400 cancel cursor-pointer"
                              onClick={() => setEdit(false)}
                          >
                            {trans.Cancel}
                          </span>
                            <span className="save">
                            <Button type="" htmlType="submit">
                              {trans.Save}
                            </Button>
                          </span>
                        </div>
                    ) : (
                        <div className="icon-container">
                            {!data.new && (
                                <span onClick={() => setEdit(true)}>
                                  <EditIcon className="b-icon-edit cursor-pointer" />
                                </span>
                            )}
                            <CloseIcon
                                className="b-icon-close cursor-pointer"
                                onClick={() => {
                                    data.new
                                        ? handleNewTeachingDelete(index)
                                        : handleTeachingDelete(index);
                                }}
                            />
                        </div>
                    )}
                </div>

                <div>
                    <div className="accordion-detail">
                        <div className="f-12 fw-500 faculty-heading">{trans.Heading}</div>
                        <Form.Item name="heading" initialValue={data?.heading}>
                            <Input
                                onChange={handleInputValueChange}
                                name="heading"
                                disabled={edit || data.new ? "" : "disabled"}
                                placeholder={trans.Typehere}
                            />
                        </Form.Item>
                    </div>
                </div>

                <div className={edit || data.new ? "" : "quill-readonly"}>
                    <Editor
                        key={`news-tab${index}`}
                        editorHtml={editorHtml}
                        setEditorHtml={setEditorHtml}
                        handleChange={handleTextEditorChange}
                        readonly={!edit && !data.new}
                    />
                </div>
            </Form>
        </div>
    );
};
