import Editor from "../../Components/common/Editor";
import trans from "./../../../Languages/Languages";
import {useState} from "react";
import {Button, Card, message} from "antd";
import React, {useEffect} from "react";
import API from "../../../API";
import { useLocation } from "react-router";

const AcademicHelp = () => {
  const [editorHtml, setEditorHtml] = useState("");
  const [loading, setLoading] = useState(false);

  const handleTextEditorChange = (html) => {
    setEditorHtml(html);
  };
  const currentDegreeID = window.location.hash.split("/")[3];
  const location = useLocation();
  ///////////////////////////////////////////////////////////////////////////////////////////////
  ///                  Use Effect
  ////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    setLoading(true);
    const init = async () => {
      await API.get(`/admin/degree/${currentDegreeID}`)
        .then((res) => {
          if (res.data.help.length > 0) {
            setEditorHtml(res.data.help[0].about);
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    };
    init();
  }, [currentDegreeID, location]);
  const handleSaveSection = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("about", editorHtml);

    await API.post(
      `/admin/degree/academic/help/update/${currentDegreeID}`,
      formData
    )
      .then((res) => {
        message.success("Saved Successfully", 3);
        setLoading(false);
      })
      .catch((err) => {
        message.error("Something went wrong, please try again", 3);
        setLoading(false);
      });
  };
  return (
    <div className="pages-container">
      <Card loading={loading} bordered={false}>
        <div className="content-wrapper">
          {/*  text */}

          {/* Tuition Fees */}
          <div className="bg-content-container mb-0">
            <div className="fw-600 fw-15 mb-2">{trans.Academichelp}</div>
            <Editor
              key="academicHelp"
              editorHtml={editorHtml}
              setEditorHtml={setEditorHtml}
              handleChange={handleTextEditorChange}
            />
          </div>
        </div>
        {/* save changes */}

        <Button
          onClick={handleSaveSection}
          className="btn-save-changes"
          type="primary"
          htmlType="submit"
        >
          {trans.Save}
        </Button>
      </Card>
    </div>
  );
};

export default AcademicHelp;
