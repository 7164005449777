import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Collapse from "@material-ui/core/Collapse";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandLess from "@material-ui/icons/ExpandMore";
import ExpandMore from "@material-ui/icons/ChevronRight";
import clsx from "clsx";
import Home from "./Home/Home";
import KIUVision from "./AboutUs/KiuVision";
import KIUDirector from "./AboutUs/KIUDirector";
import BoardOfTrustees from "./AboutUs/BoardOfTrustees";
import Faculties from "./AboutUs/Faculties";
import CollegesInstitutes from "./AboutUs/CollegesInstitutes";
import NewsAndEvents from "./AboutUs/NewsAndEvents";
import FAQ from "./AboutUs/FAQ";
import ContactUS from "./AboutUs/ContactUs";
import Courses from "./Courses/Courses";
import CourseCategories from "./Courses/CourseCategories";
import FeeStructure from "./Courses/FeeStructure";
import OnlineFreeCourses from "./Courses/OnlineFreeCourses";
import StudyOverview from "./HowItWorks/StudyOverview";
import StudentPortal from "./HowItWorks/StudentPortal";
import StudyMaterial from "./HowItWorks/StudyMaterial";
import VideoLectures from "./HowItWorks/VideoLectures";
import LiveSessions from "./HowItWorks/LiveSessions";
import KIUStudentForum from "./HowItWorks/KIUStudentForum";
import Assessment from "./HowItWorks/Assessment";
import { Modal, Form, Input, Button, message } from "antd";
import Requirements from "./admission/requirements";
import EnrollmentProcess from "./admission/enrollmentProcess";
import Scholarships from "./admission/scholarships";
import AdmissionDeadline from "./admission/admissionDeadline";
import WhoShouldStudyAtKIU from "./WhyKIU/whoShouldStudyAtKIU";
import BenefitOfKIUDegree from "./WhyKIU/benefitOfKIUDegree";
import trans from "../../Languages/Languages";
import { useLanguage } from "./../../Context/LanguageContext";
import API from "../../API";
import { getDegrees } from "./../../utility/getDegree";
import { Link } from "react-router-dom";
import InstitutionAndEvidence from "./AboutUs/InstitutionAndEvidence";

const drawerWidth = 172;

function Pages(props) {
  message.config({
    top: 80,
  });
  // eslint-disable-next-line
  const [currentDegreeID, setCurrentDegreeID] = useState(0);
  const [currentPage, setCurrentPage] = React.useState("Home");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentDegree, setCurrentDegree] = useState("");
  const { language } = useLanguage();
  const classes = useStyles();

  const [pages, setPages] = useState([
    {
      id: 0,
      name: trans.Home,
      children: null,
      content: <Home />,
      open: false,
      subChildren: null,
      url: "/pages/home",
    },
    {
      id: 1,
      name: trans.AboutUs,
      children: [
        {
          id: 101,
          name: trans.KIUVisionMissions,
          content: <KIUVision />,
          url: "/pages/aboutus/kiuvision",
        },
        {
          id: 102,
          name: trans.KIUDirectors,
          content: <KIUDirector />,
          url: "/pages/aboutus/kiudirector",
        },
        {
          id: 103,
          name: trans.Boardoftrustees,
          content: <BoardOfTrustees />,
          url: "/pages/aboutus/boardoftrustees",
        },
        {
          id: 104,
          name: trans.Faculities,
          content: <Faculties />,
          url: "/pages/aboutus/faculties",
        },
        {
          id: 105,
          name: trans.Collegesinstitutes,
          content: <CollegesInstitutes />,
          url: "/pages/aboutus/collegeandinstitute",
        },
        {
          id: 106,
          name: trans.Newsevents,
          content: <NewsAndEvents />,
          url: "/pages/aboutus/newsandevents",
        },
        {
          id: 107,
          name: trans.InstitutionAndEvidence,
          content: <InstitutionAndEvidence />,
          url: "/pages/aboutus/institutionandevidence",
        },
        {
          id: 108,
          name: trans.FAQs,
          content: <FAQ />,
          url: "/pages/aboutus/faq",
        },
        {
          id: 109,
          name: trans.Contactus,
          content: <ContactUS />,
          url: "/pages/aboutus/contactus",
        },
      ],
      open: false,
      subChildren: null,
    },
    {
      id: 2,
      name: trans.OnlineDegree,
      children: [],
      subChildren: [
        {
          id: 22,
          name: trans.AboutDegree,
          content: null,
          url: `/aboutdegree`,
        },
        {
          id: 23,
          name: trans.Syllabusbysemester,
          content: null,
          url: `/syllabusbysemester`,
        },
        {
          id: 24,
          name: trans.TuitionFees,
          content: null,
          url: `/tuitionfees`,
        },
        {
          id: 25,
          name: trans.Academichelp,
          content: null,
          url: `/academichelp`,
        },
      ],
      addAnother: true,
      open: false,
    },
    {
      id: 4,
      name: trans.Courses,
      open: false,
      children: [
        {
          id: 400,
          name: trans.Courses,
          open: false,
          content: <Courses />,
          url: "/pages/courses/courses",
        },
        {
          id: 401,
          name: trans.Coursecategories,
          open: false,
          content: <CourseCategories />,
          url: "/pages/courses/coursescategories",
        },
        {
          id: 402,
          name: trans.FeeStructure,
          open: false,
          content: <FeeStructure />,
          url: "/pages/courses/feestructure",
        },
        {
          id: 403,
          name: trans.Onlinefreecourses,
          open: false,
          content: <OnlineFreeCourses />,
          url: "/pages/courses/onlinefreecourses",
        },
      ],
      subChildren: null,
    },
    {
      id: 5,
      name: trans.Howitworks,
      open: false,
      subChildren: null,
      children: [
        {
          id: 500,
          name: trans.Studyoverview,
          open: false,
          content: <StudyOverview />,
          url: "/pages/howitworks/studyoverview",
        },
        {
          id: 501,
          name: trans.Studentportal,
          open: false,
          content: <StudentPortal />,
          url: "/pages/howitworks/studentportal",
        },
        {
          id: 502,
          name: trans.Studymaterial,
          open: false,
          content: <StudyMaterial />,
          url: "/pages/howitworks/studymaterial",
        },
        {
          id: 503,
          name: trans.VideoLectures,
          open: false,
          content: <VideoLectures />,
          url: "/pages/howitworks/videolectures",
        },
        {
          id: 504,
          name: trans.Livesessions,
          open: false,
          content: <LiveSessions />,
          url: "/pages/howitworks/livesessions",
        },
        {
          id: 505,
          name: trans.KIUstudentforum,
          open: false,
          content: <KIUStudentForum />,
          url: "/pages/howitworks/kiustudentforum",
        },
        {
          id: 506,
          name: trans.Assessment,
          open: false,
          content: <Assessment />,
          url: "/pages/howitworks/assessment",
        },
      ],
    },
    {
      id: 6,
      name: trans.AdmissionFee,
      children: [
        {
          id: 601,
          name: trans.Requirements,
          content: <Requirements />,
          url: "/pages/admissionfee/requirements",
        },
        {
          id: 602,
          name: trans.Enrollmentprocess,
          content: <EnrollmentProcess />,
          url: "/pages/admissionfee/enrollmentprocess",
        },
        {
          id: 603,
          name: trans.Scholarships,
          content: <Scholarships />,
          url: "/pages/admissionfee/scholarships",
        },
        {
          id: 604,
          name: trans.Admissionsdeadline,
          content: <AdmissionDeadline />,
          url: "/pages/admissionfee/admissiondeadline",
        },
      ],
      open: false,
      subChildren: null,
    },
    {
      id: 7,
      name: trans.WhyKIU,
      children: [
        {
          id: 701,
          name: trans.WhoshouldstudyatKIU,
          content: <WhoShouldStudyAtKIU />,
          url: "/pages/whyKIU/whoshouldstudyatKIU",
        },
        {
          id: 702,
          name: trans.BenefitofKIUdegree,
          content: <BenefitOfKIUDegree />,
          url: "/pages/whyKIU/benefitsofdegree",
        },
      ],
      open: false,
      subChildren: null,
    },
  ]);
  const tabsData = [
    {
      id: 0,
      name: trans.Home,
    },
    {
      id: 1,
      name: trans.AboutUs,
      children: [
        {
          id: 101,
          name: trans.KIUVisionMissions,
        },
        {
          id: 102,
          name: trans.KIUDirectors,
        },
        {
          id: 103,
          name: trans.Boardoftrustees,
        },
        {
          id: 104,
          name: trans.Faculities,
        },
        {
          id: 105,
          name: trans.Collegesinstitutes,
        },
        {
          id: 106,
          name: trans.Newsevents,
        },
        {
          id: 107,
          name: trans.InstitutionAndEvidence,
        },
        {
          id: 108,
          name: trans.FAQs,
        },
        {
          id: 109,
          name: trans.Contactus,
        },
      ],
    },
    {
      id: 2,
      name: trans.OnlineDegree,

      subChildren: [
        {
          id: 22,
          name: trans.AboutDegree,
        },
        {
          id: 23,
          name: trans.Syllabusbysemester,
        },
        {
          id: 24,
          name: trans.TuitionFees,
        },
        {
          id: 25,
          name: trans.Academichelp,
        },
      ],
    },
    {
      id: 4,
      name: trans.Courses,

      children: [
        {
          id: 400,
          name: trans.Courses,
        },
        {
          id: 401,
          name: trans.Coursecategories,
        },
        {
          id: 402,
          name: trans.FeeStructure,
        },
        {
          id: 403,
          name: trans.Onlinefreecourses,
        },
      ],
      subChildren: null,
    },
    {
      id: 5,
      name: trans.Howitworks,

      children: [
        {
          id: 500,
          name: trans.Studyoverview,
        },
        {
          id: 501,
          name: trans.Studentportal,
        },
        {
          id: 502,
          name: trans.Studymaterial,
        },
        {
          id: 503,
          name: trans.VideoLectures,
        },
        {
          id: 504,
          name: trans.Livesessions,
        },
        {
          id: 505,
          name: trans.KIUstudentforum,
        },
        {
          id: 506,
          name: trans.Assessment,
        },
      ],
    },
    {
      id: 6,
      name: trans.AdmissionFee,
      children: [
        {
          id: 601,
          name: trans.Requirements,
        },
        {
          id: 602,
          name: trans.Enrollmentprocess,
        },
        {
          id: 603,
          name: trans.Scholarships,
        },
        {
          id: 604,
          name: trans.Admissionsdeadline,
        },
      ],
    },
    {
      id: 7,
      name: trans.WhyKIU,
      children: [
        {
          id: 701,
          name: trans.WhoshouldstudyatKIU,
        },
        {
          id: 702,
          name: trans.BenefitofKIUdegree,
        },
      ],
    },
  ];
  const [refresh, setRefresh] = useState(true);

  ///////////////////////////////////////////////////////////////////////////////////////////////
  ///                                   Use Effect
  ///////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    const init = async () => {
      await API.get("/admin/degree")
        .then((res) => {
          getDegrees(res.data, pages, setPages);
        })
        .catch((err) => {});
    };
    init();
    // eslint-disable-next-line
  }, [refresh]);

  //////////////// main collapse
  const handleCollapseOpen = (id) => {
    const data = [...pages];

    const newData = data.map((item) => {
      var temp = { ...item };

      if (item.addAnother) {
        const newChildData = temp.children.map((childItem) => {
          var tempChild = { ...childItem };
          tempChild.open = false;

          return tempChild;
        });
        temp.children = newChildData;
      }

      if (temp.id === id) {
        temp.open = !item.open;
      } else if (temp.id !== id) {
        temp.open = false;
      }
      return temp;
    });
    setPages(newData);
  };

  /////////////  child collapse
  const handleSubChildCollapseOpen = (id) => {
    const data = [...pages];
    const newData = data.map((item) => {
      var temp = { ...item };
      if (item.addAnother) {
        const newChildData = temp.children.map((childItem) => {
          var tempChild = { ...childItem };

          if (tempChild.id === id) {
            tempChild.open = !childItem.open;
          } else if (tempChild.id !== id) {
            tempChild.open = false;
          }

          return tempChild;
        });
        temp.children = newChildData;
      }
      return temp;
    });
    setPages(newData);
  };

  //////////////// Modal - add another child online degree

  const handleOkAnotherDegree = (degreeName) => {
    const data = [...pages];

    data[2].children.push({
      id: `20${Math.random()}`,
      name: degreeName["english"],
      open: false,
    });

    setPages(data);
    setIsModalVisible(false);
  };
  // get current Degree

  const handleSetCurrentDegree = (child) => {
    setCurrentDegree(child);
    setCurrentDegreeID(child.id);
  };
  const [modalDegreeName, setModalDegreeName] = useState({
    arabic: "",
    english: "",
    french: "",
    urdu: "",
  });
  const handleModalInput = (e, degreeName) => {
    const data = { ...modalDegreeName };
    data[degreeName] = e.target.value;
    setModalDegreeName(data);
  };
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div>
      {trans.setLanguage(language)}
      <h1 className="f-20 fw-600 pages-heading">{trans.Pages}</h1>
      <div className={classes.root}>
        {
          // =======================================================================
          //                                Drawer
          // =======================================================================
        }
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.toolbar} />

          <List>
            {pages.map((page, index) =>
              page.children === null ? (
                <ListItem
                  key={index}
                  button
                  className={clsx(
                    currentPage === page.name
                      ? classes.activeListPage
                      : classes.inActiveListPage
                  )}
                  onClick={() => {
                    setCurrentPage(page.name);
                    handleCollapseOpen(page.id);
                    handleSetCurrentDegree("");
                  }}
                >
                  <ListItemText className="text">
                    <Link to={page.url}>
                      <span>{tabsData[index]?.name}</span>
                    </Link>
                  </ListItemText>
                </ListItem>
              ) : (
                <div>
                  <ListItem
                    key={index}
                    button
                    className={clsx(
                      page.open
                        ? classes.activeListPage
                        : classes.inActiveListPage
                    )}
                    onClick={() => {
                      // handleCollapse();
                      handleCollapseOpen(page.id);
                      handleSetCurrentDegree("");
                      setCurrentPage(
                        page.subChildren === null
                          ? page.children[0].name
                          : page.name
                      );
                    }}
                  >
                    <ListItemText className="text">
                      <Link
                        to={
                          page.children.length > 0
                            ? page.children[0].url ?? "#"
                            : ""
                        }
                      >
                        <span>{tabsData[index]?.name}</span>
                      </Link>
                    </ListItemText>
                    <span>
                      {page.open ? (
                        <ExpandLess fontSize="small" />
                      ) : (
                        <ExpandMore fontSize="small" />
                      )}
                    </span>
                  </ListItem>
                  {/* Collapse Dropdown */}
                  <Collapse
                    key="collapse1"
                    in={page.open}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div">
                      {!page.addAnother ? (
                        <div>
                          {page.children.map((child, childIndex) => {
                            return (
                              /* // List item in dropdown */
                              <Link to={child.url}>
                                <ListItem
                                  button
                                  key={childIndex}
                                  className={clsx(
                                    currentPage === child.name
                                      ? classes.activeListPageChild
                                      : classes.inActiveListPageChild
                                  )}
                                  onClick={() => {
                                    setCurrentPage(child.name);
                                    handleSetCurrentDegree("");
                                  }}
                                >
                                  <ListItemText>
                                    <span>
                                      {
                                        tabsData[index]?.children[childIndex]
                                          ?.name
                                      }
                                    </span>
                                  </ListItemText>
                                </ListItem>
                              </Link>
                            );
                          })}
                        </div>
                      ) : (
                        <div>
                          {page.children.map((child, childIndex) => {
                            return (
                              // List item in dropdown, Add AnotherNew page adding enable
                              <div key={childIndex}>
                                <ListItem
                                  button
                                  className={clsx(
                                    child.open
                                      ? classes.activeListPageChild
                                      : classes.inActiveListPageChild
                                  )}
                                  onClick={() => {
                                    setCurrentPage(page.subChildren[0].name);
                                    handleSubChildCollapseOpen(child.id);
                                    handleSetCurrentDegree(child);
                                  }}
                                >
                                  <ListItemText>
                                    <span
                                      className={clsx(
                                        child.open ? classes.subChildBorder : ""
                                      )}
                                    >
                                      {child.name}
                                    </span>
                                  </ListItemText>
                                  <span>
                                    {child.open ? (
                                      <ExpandLess fontSize="small" />
                                    ) : (
                                      <ExpandMore fontSize="small" />
                                    )}
                                  </span>
                                </ListItem>
                                {/* //////////////////////// sub child collapse //////////////////////// */}
                                <Collapse
                                  key="collapse2"
                                  in={child.open}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  {page.subChildren.map(
                                    (subChild, subChildIndex) => {
                                      return (
                                        <ListItem
                                          key={subChildIndex}
                                          button
                                          className={clsx(
                                            currentPage === subChild.name
                                              ? classes.activeListPageChild
                                              : classes.inActiveListPageChild,
                                            classes.ListPageSubChild
                                          )}
                                          onClick={() => {
                                            setCurrentPage(subChild.name);
                                            handleSetCurrentDegree(child);
                                          }}
                                        >
                                          <ListItemText>
                                            <Link
                                              to={`/pages/degree/${child.id}${subChild.url}`}
                                            >
                                              <span>{subChild.name}</span>
                                            </Link>
                                          </ListItemText>
                                        </ListItem>
                                      );
                                    }
                                  )}
                                </Collapse>
                                {/* //////////////////////// end sub child collapse //////////////////////// */}
                              </div>
                            );
                          })}
                          <ListItem
                            button
                            key="add-another"
                            className={classes.addAnotherItem}
                            onClick={() => {
                              showModal();
                            }}
                          >
                            <ListItemText>
                              <span>+ {trans.AddNewDegree}</span>
                            </ListItemText>
                          </ListItem>
                        </div>
                      )}
                    </List>
                  </Collapse>
                </div>
              )
            )}
          </List>
        </Drawer>
        <main
          className={clsx(
            currentDegree !== "" ? classes.contentMargin : classes.content
          )}
        >
          {isModalVisible && (
            <AddAnotherDegreePopup
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              handleCancel={handleCancel}
              handleOk={handleOkAnotherDegree}
              modalDegreeName={modalDegreeName}
              handleModalInput={handleModalInput}
              refresh={refresh}
              setRefresh={setRefresh}
            />
          )}
          <div>{props.component}</div>
        </main>
      </div>
    </div>
  );
}

export default Pages;

const AddAnotherDegreePopup = ({
  isModalVisible,
  setIsModalVisible,
  refresh,
  setRefresh,
}) => {
  const [errors, setErrors] = useState({});
  const style = { top: "40%" };

  const { isRTL } = useLanguage();

  const handleCreateNewDegree = async (values) => {
    const formData = new FormData();
    formData.append("arabic_name", values.arabic);
    formData.append("english_name", values.english);
    formData.append("french_name", values.french);
    formData.append("urdu_name", values.urdu);

    await API.post("/admin/degree/create", formData)
      .then((res) => {
        setRefresh(!refresh);
        setIsModalVisible(false);
        message.success("Degree Created Successfully");
        setErrors({});
      })
      .catch((e) => {
        setRefresh(!refresh);
        setIsModalVisible(false);
        if (e.response.status === 400) {
          setErrors(e.response.data);
        }
      });
  };

  let rules = [
    {
      required: true,
      message: "field is required",
    },
  ];
  return (
    <div className="online-degree-modal">
      <Modal
        title={trans.AddNewDegree}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        width="511px"
        style={style}
        footer={
          <>
            <Button onClick={() => setIsModalVisible(false)}>
              {trans.Cancel}
            </Button>
            <Button type="primary" form="myForm" key="submit" htmlType="submit">
              {trans.Save}
            </Button>
          </>
        }
      >
        <Form name="myForm" onFinish={handleCreateNewDegree}>
          <div dir={isRTL ? "rtl" : "ltr"} className={isRTL ? "rtl" : "ltr"}>
            <div className="d-flex justify-content-lg-start">
              <div className="accordion-detail online-degree-modal">
                <div className="f-12 fw-400 input-heading">
                  {trans.NameInArabic}
                </div>
                <Form.Item rules={rules} name="arabic">
                  <Input type="text" placeholder={trans.Typehere} />
                </Form.Item>
                {errors?.arabic_name && (
                  <span className="text-danger">{errors?.arabic_name}</span>
                )}
              </div>
              <div className="accordion-detail online-degree-modal">
                <div className="f-12 fw-400 input-heading ">
                  {trans.NameInEnglish}
                </div>
                <Form.Item rules={rules} name="english">
                  <Input type="text" placeholder={trans.Typehere} />
                </Form.Item>
                {errors?.english_name && (
                  <span className="text-danger">{errors?.english_name}</span>
                )}
              </div>
            </div>
            <div className="d-flex justify-content-lg-start ">
              <div className="accordion-detail online-degree-modal">
                <div className="f-12 fw-400 input-heading">
                  {trans.NameInFrench}
                </div>
                <Form.Item rules={rules} name="french">
                  <Input type="text" placeholder={trans.Typehere} />
                </Form.Item>
                {errors?.french_name && (
                  <span className="text-danger">{errors?.french_name}</span>
                )}
              </div>
              <div className="accordion-detail online-degree-modal">
                <div className="f-12 fw-400 input-heading ">
                  {trans.NameInUrdu}
                </div>
                <Form.Item rules={rules} name="urdu">
                  <Input type="text" placeholder={trans.Typehere} />
                </Form.Item>
                {errors?.urdu_name && (
                  <span className="text-danger">{errors?.urdu_name}</span>
                )}
              </div>
            </div>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

// ==================================================================================
//                                        material sidebar makeStyle
// ===================================================================================

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    position: "relative",
    zIndex: "1",
  },
  drawerPaper: {
    width: drawerWidth,
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,

  content: {
    padding: "20px 20px 40px 0px",
    "[dir='ltr'] &": {
      marginRight: 80,
      marginLeft: 16,
    },

    "[dir='rtl'] &": {
      marginRight: 16,
      marginLeft: 80,
    },
    flexGrow: 1,
    backgroundColor: "white",
    width: 845,
    height: "100%",
  },
  contentMargin: {
    marginTop: 30,
    padding: "20px 20px 40px 0px",
    marginRight: 80,
    flexGrow: 1,
    backgroundColor: "white",
    marginLeft: 16,
    width: 845,

    height: "100%",
  },
  activeListPage: {
    padding: "6px 8px 6px 17px",
    color: "#343379",

    "& span": {
      fontSize: 12,
      display: "inline-block",
      fontWeight: 600,
    },
    "& .text span": {
      borderBottom: "1px solid #343379",
    },
  },

  inActiveListPage: {
    padding: "6px 8px 6px 17px",

    "& span": {
      fontSize: 12,
      fontWeight: 400,
      display: "inline-block",
    },
  },

  activeListPageChild: {
    padding: "3px 20px 3px 17px",
    color: "#343379",

    "& span": {
      fontSize: 12,
      display: "inline-block",
      fontWeight: 500,
    },
  },

  inActiveListPageChild: {
    padding: "3px 20px 3px 17px",
    "& span": {
      fontSize: 12,
      fontWeight: 300,
      display: "inline-block",
    },
  },
  ListPageSubChild: {
    paddingRight: 2,
    paddingBottom: 3,
    paddingTop: 3,
    paddingLeft: 30,
    padding: "3px 2px 3px 30px",
    backgroundColor: "#E9E9E9",
    "& span": {
      fontSize: 12,
      display: "inline-block",
      fontWeight: 400,
    },
  },
  subChildBorder: {
    borderBottom: "1px solid #343379",
  },
  addAnotherItem: {
    "& span": {
      fontSize: 12,
      fontWeight: 500,
      display: "inline-block",
      color: "#079751",
    },
  },
  routeHeading: {
    margin: 22,
  },
}));
