import React from "react";
import {Card, message, Typography} from "antd";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import {Box} from "@material-ui/core";
import {Link} from "react-router-dom";
import trans from "./../../../Languages/Languages";
import API from "../../../API";

const userCardStyle = {
  //   minWidth: 347,
  minHeight: 175,
  backgroundColor: "white",
  boxShadow: "0px 3px 6px #67646429",
  border: "1px solid #E3E3E3",
  borderRadius: 3,
  padding: 10,
};

function UserCard({ user= {}, refresh, setRefresh }) {
  const handleDelete = () => {
    API.delete(`/admin/user/${user.id}`).then(res=>{
      message.success('user Deleted Successfully');
      setRefresh(!refresh);
    }).catch(e=>{
      Object.keys(e).map(err=>{
        return message.error(e[err])
      })
    })
  };

  return (
    <Card style={userCardStyle} bodyStyle={{padding: 10}}>
      <Box display='flex' justifyContent='space-between'>
        <div>
          <Typography.Paragraph className='f-12 fw-500'>
            {trans.Name}
          </Typography.Paragraph>
          <Typography.Paragraph className='f-19'>{user.name}</Typography.Paragraph>
        </div>
        <div>
          <Link to={`/user/${user.id}`}>
            <EditIcon
              style={{color: "#46D18D", cursor: "pointer"}}
            />
          </Link>
          <DeleteIcon
            style={{color: "#F87676", cursor: "pointer"}}
            onClick={handleDelete}
          />
        </div>
      </Box>
      <div className='s-43'/>

      <Box display='flex' justifyContent='space-between'>
        <div>
          <Typography.Paragraph className='f-14 fw-500'>
            {trans.College}
          </Typography.Paragraph>
          <Typography.Paragraph className='f-17'>
            {user.college}
          </Typography.Paragraph>
        </div>

        <div>
          <Typography.Paragraph className='f-11 fw-500'>
            {trans.Language}
          </Typography.Paragraph>
          <Typography.Paragraph className='f-14 text-capitalize'>{user.language}</Typography.Paragraph>
        </div>
      </Box>
    </Card>
  );
}

export default UserCard;
