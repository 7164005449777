import React from "react";
import {Input, Modal, Button} from "antd";
import {EyeInvisibleOutlined, EyeTwoTone} from "@ant-design/icons";
import trans from "./../../../Languages/Languages";
import {useLanguage} from "./../../../Context/LanguageContext";

function ChangePassword({id, isModalVisible, setIsModalVisible}) {
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const {isRTL} = useLanguage();
  return (
    <Modal
      visible={isModalVisible}
      footer={null}
      destroyOnClose
      centered
      closable={false}
      width={600}
      style={{marginTop: 100}}
      onCancel={handleCancel}
    >
      <div>
        <div
          dir={isRTL ? "rtl" : "ltr"}
          className={isRTL ? "rtl s-27" : "s-27"}
        >
          `<p className='f-27 fw-600 text-center'>{trans.ChangePassword}</p>
          <p className='s-22 f-13 text-muted text-center'>
            {trans.EnterYourNewPasswordForLogin}
          </p>
          <div className='s-22 w-75 mx-auto'>
            <div>
              <label
                htmlFor='current_password'
                className={isRTL ? "f-14 float-right" : "f-14"}
              >
                {trans.CurrentPassword}
              </label>
              <Input.Password
                name='current_password'
                id='current_password'
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              ></Input.Password>
            </div>
            <div className='s-15'>
              <label
                htmlFor='new_password'
                className={isRTL ? "f-14 float-right" : "f-14"}
              >
                {trans.NewPassword}
              </label>
              <Input.Password
                name='new_password'
                id='new_password'
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              ></Input.Password>
            </div>
            <div className='s-15'>
              <label
                htmlFor='confirm_password'
                className={isRTL ? "f-14 float-right" : "f-14"}
              >
                {trans.ConfirmPassword}
              </label>
              <Input.Password
                name='confirm_password'
                id='confirm_password'
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              ></Input.Password>
            </div>

            <div className='d-flex justify-content-center s-27'>
              <Button
                type='primary'
                htmlType='submit'
                style={{minWidth: 252, minHeight: 39}}
                className='f-16'
                onClick={handleOk}
              >
                {trans.Confirm}
              </Button>
            </div>
            <div className='d-flex justify-content-center mt-2'>
              <Button
                type='text'
                htmlType='reset'
                style={{minWidth: 252, minHeight: 39}}
                className='f-16 text-muted'
                onClick={handleCancel}
              >
                {trans.Cancel}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ChangePassword;
