// ES6 module syntax
import LocalizedStrings from "react-localization";

let trans = new LocalizedStrings({
  ar: {
    MyProfile: "ملفي",
    CurrentRates: "التصنيفات الحالية",
    year: "عام",
    Course: "مسار",
    AddNewSection: "إضافة قسم جديد",
    Delete: "حذف",
    time: "زمن",
    text: "نص",
    Dashboard: "لوحة القيادة",
    WelcometowebsiteAdminPanel: "مرحبًا بك في لوحة إدارة الموقع",
    Customizeyourwebsitecontentfromthispanelwithease:
      "تخصيص محتوى موقع الويب الخاص بك من هذه اللوحة بسهولة",
    Visitors: "الزائرين",
    UserName: "اسم االمستخدم",
    YourProfile: "ملفك الشخصي",
    Logout: "تسجيل خروج",
    Pages: "الصفحات",
    Gallery: "صالة عرض",
    Users: "المستخدمون",
    Home: "منزل، بيت",
    HomeCarousel: "الصفحة الرئيسية كاروسيل",
    Uploadimage: "تحميل الصور",
    Maxsize: "اقصى حجم",
    RecommendedResolution: "الحجم الأقصى القرار الموصى به",
    CarouselText: "نص دائري",
    Normal: "طبيعي",
    Savesection: "قسم الحفظ",
    Interactivelectures: "محاضرات تفاعلية",
    Downloadablecontent: "المحتوى القابل للتنزيل",
    VirtualclassroomparticularGuide: "دليل خاص بالفصول الدراسية الافتراضية",
    Remove: "إزالة",
    Typehere: "أكتب هنا",
    Uploadfile: "رفع ملف",
    Cancel: "يلغي",
    Addanother: "أضف آخر",
    AcademicCalendar: "التقويم الاكاديمى",
    EnterAcademicYear: "أدخل العام الدراسي",
    From: "من عند",
    To: "ل",
    StudentsGuide: "دليل الطالب",
    AboutUs: "معلومات عنا",
    Changefile: "تغيير الملف",
    Savechanges: "احفظ التغييرات",
    Vision: "رؤية",
    Counters: "عدادات",
    Title: "Title",
    Count: "عدد",
    Future: "مستقبل",
    Save: "حفظ",
    KIUVisionMissions: " KIU مهام الرؤية",
    KIUDirectors: " KIU المدراء",
    Boardoftrustees: "مجلس الأمناء",
    Faculities: "ملكات",
    Collegesinstitutes: "معاهد الكليات",
    Newsevents: "الأخبار و الأحداث",
    FAQs: "أسئلة وأجوبة",
    Contactus: "اتصل بنا",
    InstitutionAndEvidence: "المؤسسة والأدلة",
    StatementsExecutiveDirector: "بيانات المدير التنفيذي (الأمين العام)",
    BoardTrustees: "مجلس الأمناء واللجان المنبثقة عنه",
    RegulationsAndPolicies: "اللوائح والسياسات",
    FinancialStatementDisclosure: "إفصاح البيانات المالية",
    AnnualReports: "التقارير السنوية",
    StrategicPlan: "الخطة الاستراتيجية",
    DirectorofKIU: " KIU مدير",
    FirstNameBold: "الاسم الأول بولد",
    LastNameRegular: "الاسم الأخير عادي",
    Description: "وصف",
    Directorsmessage: "رسالة المخرج",
    Boardmembers: "أعضاء مجلس الإدارة",
    ScholarsforArabicCourses: "العلماء لدورات اللغة العربية",
    Name: "اسم",
    ScholarsfornonArabicCourses: "علماء الدورات غير العربية",
    Colleges: "الكليات",
    Question: "سؤال",
    Answer: "إجابه",
    KnowledgeInternationalUniversity: "جامعة المعرفة الدولية",
    AdministratorAddress: "عنوان المسؤول",
    POBox: "صندوق البريد",
    City: "مدينة",
    KingdomofSaudiArabia: "المملكة العربية السعودية",
    Email: "البريد الإلكتروني",
    Fax: "فاكس",
    Edit: "تعديل",
    Islamicstudies: "الدراسات الإسلامية",
    UrduLiterature: "الأدب الأردية",
    FrenchLiterature: "الأدب الفرنسي",
    EnglishLiterature: "أدب إنجليزي",
    AboutDegree: "حول الدرجة",
    Syllabusbysemester: "المنهج حسب الفصل الدراسي",
    TuitionFees: "رسوم دراسية",
    Academichelp: "المساعدة الأكاديمية",
    AddNewDegree: "إضافة درجة جديدة",
    Add: "يضيف",
    Studyacourse: "ادرس دورة",
    Coursesinclude: "تشمل الدورات",
    VideoLectures: "محاضرات الفيديو",
    Value: "قيمة",
    Livesessions: "جلسات مباشرة",
    Coursematerial: "مواد الدورة",
    Assignments: "تعيينات",
    Discussionforum: "منتدى للنقاش",
    FinalExam: "إمتحان نهائي",
    Courses: "الدورات",
    OnlineDegree: "شهادة عبر الإنترنت",
    Coursecategories: "فئات الدورة",
    FeeStructure: "هيكل الرسوم",
    Onlinefreecourses: "دورات مجانية على الإنترنت",
    Coursecatalogue: "كتالوج الدورة",
    Coursetitle: "عنوان الدورة",
    Credithours: "الساعات المعتمدة",
    Instructor: "مدرب",
    Required: "مطلوب",
    Objectives: "الأهداف",
    Topicstocover: "موضوعات للتغطية",
    Addnewsubject: "أضف موضوعًا جديدًا",
    KIUFees: "رسوم جامعة الكويت",
    Currency: "Currency",
    ApplicationFees: "رسوم التقديم",
    Fulltimestudents: "طلاب بدوام كامل",
    Onecoursemodule: "وحدة مقرر واحد",
    ScholarshipEntitlement: "استحقاق المنحة",
    Paymentinstructions: "تعليمات الدفع",
    Paymentdetails: "بيانات الدفع",
    Bank: "بنك",
    Branch: "فرع",
    Beneficiarysname: "أسم المستفيد",
    AccountNo: "رقم الحساب",
    Swiftcode: "رمز السرعة",
    KIUFreecourselectures: "KIU محاضرات دورة مجانية",
    Coursename: "اسم الدورة التدريبية",
    Accesslink: "اكسيسلينك",
    Lecturer: "محاضر",
    Addnewcourse: "إضافة دورة جديدة",
    Howitworks: "كيف تعمل",
    Studyoverview: "نظرة عامة على الدراسة",
    Studentportal: "بوابة الطالب",
    Studymaterial: "المواد الدراسية",
    Videolectures: "محاضرات الفيديو",
    KIUstudentforum: " KIU منتدى الطلاب",
    Assessment: "تقييم",
    Insummaryeachmodulecomprisesof: "باختصار ، تتكون كل وحدة من",
    Currentlysemester1ismadeupoffollowingsubjects:
      "يتكون الفصل الدراسي الأول حاليًا من الموضوعات التالية",
    Subject: "موضوعات",
    Addnew: "اضف جديد",
    SessionTest: "اختبار الجلسة",
    Passmarks: "علامة مرور",
    Midtermexam: "إختبار نصف الفصل",
    Finalexam: "إمتحان نهائي",
    Attendance: "الحضور",
    Requirements: "المتطلبات",
    Enrollmentprocess: "عملية التسجيل",
    Admissionsdeadline: "الموعد النهائي للقبول",
    WhyKIU: "KIU لماذا",
    Informationbox: "صندوق المعلومات",
    Postaddress: "عنوان البريد",
    EnterthecontactdetailsbelowforstudentstosubmitApplication:
      "أدخل تفاصيل الاتصال أدناه للطلاب لتقديم الطلب",
    AutumnFallsemester: "فصل الخريف الخريف",
    Startingdate: "تاريخ البدء",
    Lastdateofsubmission: "آخر موعد للتقديم",
    FinalExams: "الامتحانات النهائية",
    Springsemester: "فصل الربيع",
    WhoshouldstudyatKIU: " KIU من يجب أن يدرس في",
    BenefitofKIUdegree: "الاستفادة من KIU الدرجة",
    AdmissionFee: "رسوم القبول",
    Scholarships: "المنح الدراسية",
    AdmisionAndRegistration: "القبول والتسجيل",
    Image: "صورة",
    Date: "تاريخ",
    PersonalInformation: "معلومات شخصية",
    Password: "كلمه السر",
    Replace: "يستبدل",
    ChangePassword: "تغيير كلمة المرور",
    EnterYourNewPasswordForLogin: "أدخل كلمة المرور الجديدة لتسجيل الدخول",
    CurrentPassword: "كلمة السر الحالية",
    newPassword: "كلمة السر الجديدة",
    ConfirmPassword: "تأكيد كلمة المرور",
    Confirm: "يتأكد",
    College: "كلية",
    Language: "لغة",
    ForgotPassword: "هل نسيت كلمة السر",
    YouWillReceiveAPasswordResetLinkOnYourEmail:
      "سوف تتلقى رابط إعادة تعيين كلمة المرور على بريدك الإلكتروني",
    EmailAddress: "عنوان البريد الإلكتروني",
    KIUWebsiteAdminPanel: "لوحة إدارة موقع جامعة الملك عبد العزيز",
    ManageYouWebsiteWithEase: "إدارة الموقع بسهولة",
    Login: "تسجيل الدخول",
    ResetPassword: "إعادة تعيين كلمة المرور",
    EnterYourNewPasswordToLogin: "أدخل كلمة المرور الجديدة لتسجيل الدخول",
    PhoneNumber: "رقم الهاتف",
    UserInformation: "معلومات المستخدم",
    CreateUser: "إنشاء مستخدم",
    Arabic: "عربى",
    English: "الإنجليزية",
    French: "الفرنسية",
    urdu: "الأردية",
    NameInArabic: "الاسم بالعربي",
    NameInEnglish: "الاسم بالانجليزية",
    NameInUrdu: "الاسم باللغة الأردية",
    NameInFrench: "الاسم بالفرنسية",
    Search: "يبحث",
    Create: "يخلق",
    AppendixAndRegulations: "الملحق واللوائح",
    TeachingMethodologies: "منهجيات التدريس",
    Statistics: "إحصائيات",
    Testimonial: "شهادة",
    GraduatedStudents: "الطلاب المتخرجين",
    NumberOfColleges: "عدد الكليات",
    FacultyInNumbers: "الكلية بالأرقام",
    DegreePrograms: "برامج الشهادة",
    NumberOfScholarships: "عدد المنح الدراسية",
    PersonName: "اسم الشخص",
    JobTitle: "المسمى الوظيفي/المهنة",
    IndividualPicture: "الصورة الفردية",
    Heading: "عنوان",
  },
  ///////////////////////////////////////////////
  /////////////////// English ///////////////////
  en: {
    NameInUrdu: "Name In Urdu:",
    NameInArabic: "Name in Arabic",
    UserInformation: "User Information",
    EnterYourNewPasswordToLogin: "Enter your new password to login",
    Login: "Login",
    KIUWebsiteAdminPanel: "KIU Website Admin Panel",
    YouWillReceiveAPasswordResetLinkOnYourEmail:
      "You will receive a password reset link on your email",
    Language: "Language",
    Confirm: "Confirm",
    newPassword: "New Password",
    EnterYourNewPasswordForLogin: "Enter your new password for login",
    Replace: "Replace",
    PersonalInformation: "Personal Information",
    MyProfile: "My Profile",
    CurrentRates: "Current Rates",
    year: "year",
    Course: "Course",
    AddNewSection: "Add new section",
    Delete: "Delete",
    time: "Time",
    Date: "Date",
    text: "Text",
    Dashboard: "Dashboard",
    WelcometowebsiteAdminPanel: "Welcome to website Admin Panel",
    Customizeyourwebsitecontentfromthispanelwithease:
      "Customize your website content from this panel with ease",
    Visitors: "Visitors",
    UserName: "Username",
    YourProfile: "Your Profile",
    Logout: "Logout",
    Pages: "Pages",
    Gallery: "Gallery",
    Users: "Users",
    Home: "Home",
    HomeCarousel: "Home Carousel",
    Uploadimage: "Uploadimage",
    Maxsize: "Max size",
    RecommendedResolution: "Recommended Resolution",
    CarouselText: "Carousel Text",
    Normal: "Normal",
    Savesection: "Save Section",
    Interactivelectures: "Interactive lectures",
    Downloadablecontent: "Downloadable Content",
    VirtualclassroomparticularGuide: "Virtual classroom particular Guide",
    Remove: "Remove",
    Typehere: "Type here",
    Uploadfile: "Upload file",
    Addanother: "Add another",
    AcademicCalendar: "Academic Calendar",
    EnterAcademicYear: "Enter Academic Year",
    From: "From",
    To: "To",
    StudentsGuide: "Students Guide",
    AboutUs: "About Us",
    Changefile: "Change file",
    Savechanges: "Save Changes",
    Vision: "Vision",
    Counters: "Counters",
    Title: "Title",
    Count: "Count",
    Future: "Future",
    Save: "Save",
    KIUVisionMissions: "KIU Vision & Missions",
    KIUDirectors: "KIU Directors",
    Boardoftrustees: "Board of Trustees",
    Faculities: "Faculities",
    Collegesinstitutes: "Colleges & Institutes",
    Newsevents: "News & Events",
    FAQs: "FAQ's",
    Contactus: "Contact Us",
    InstitutionAndEvidence: "Institution & Evidence",
    StatementsExecutiveDirector:
      "Statements of the Executive Director (Secretary-General)",
    BoardTrustees: "The Board of Trustees and Its Committees",
    RegulationsAndPolicies: "Regulations and policies",
    FinancialStatementDisclosure: "Financial Statement Disclosure",
    AnnualReports: "Annual Reports",
    StrategicPlan: "Strategic Plan",
    DirectorofKIU: "Director of KIU",
    FirstNameBold: "First Name (Bold)",
    LastNameRegular: "Last Name (Regular)",
    Description: "Description",
    Directorsmessage: "Directors message",
    Boardmembers: "Board members",
    ScholarsforArabicCourses: "Scholars for Arabic Courses",
    Name: "Name",
    ScholarsfornonArabicCourses: "Scholars for non-Arabic Courses",
    Colleges: "Colleges",
    Question: "Question",
    Answer: "Answer",
    KnowledgeInternationalUniversity: "Knowledge International University",
    AdministratorAddress: "Administrator Address",
    POBox: "PO Box",
    City: "City",
    KingdomofSaudiArabia: "Kingdom of Saudi Arabia",
    Email: "Email",
    Fax: "Fax",
    Edit: "Edit",
    Islamicstudies: "Islamic studies",
    UrduLiterature: "Urdu Literature",
    FrenchLiterature: "French Literature",
    EnglishLiterature: "English Literature",
    AboutDegree: "About Degree",
    Syllabusbysemester: "Syllabus by semester",
    TuitionFees: "Tuition Fees",
    Academichelp: "Academic Help",
    AddNewDegree: "Add New Degree",
    Cancel: "Cancel",
    Add: "Add",
    Studyacourse: "Study a course",
    Coursesinclude: "Courses include",
    VideoLectures: "Video Lectures",
    Value: "Value",
    Livesessions: "Live sessions",
    Coursematerial: "Course material",
    Assignments: "Assignments",
    Discussionforum: "Discussionforum",
    FinalExam: "Final Exam",
    Courses: "Courses",
    OnlineDegree: "Online Degree",
    Coursecategories: "Course categories",
    FeeStructure: "Fee Structure",
    Onlinefreecourses: "Online free courses",
    Coursecatalogue: "Course catalogue",
    Coursetitle: "Course title",
    Credithours: "Credit hours",
    Instructor: "Instructor",
    Required: "Required",
    Objectives: "Objectives",
    Topicstocover: "Topics to cover",
    Addnewsubject: "Add new subject",
    KIUFees: "KIU Fees",
    Currency: "Currency",
    ApplicationFees: "Application Fees",
    Fulltimestudents: "Full time students",
    Onecoursemodule: "One course module",
    ScholarshipEntitlement: "Scholarship Entitlement",
    Paymentinstructions: "Payment instructions",
    Paymentdetails: "Payment details",
    Bank: "Bank",
    Branch: "Branch",
    Beneficiarysname: "Beneficiary's name",
    AccountNo: "Account No",
    Swiftcode: "Swift code",
    KIUFreecourselectures: "KIU Free course lectures",
    Coursename: "Course name",
    Accesslink: "Access link",
    Lecturer: "Lecturer",
    Addnewcourse: "Add new course",
    Howitworks: "How it works",
    Studyoverview: "Study overview",
    Studentportal: "Student portal",
    Studymaterial: "Study material",
    Videolectures: "Video lectures",
    KIUstudentforum: "KIU student forum",
    Assessment: "Assessment",
    Insummaryeachmodulecomprisesof: "In summary each module comprises of",
    Currentlysemester1ismadeupoffollowingsubjects:
      "Currently semester1 is made up of following subjects",
    Subject: "Subject",
    Addnew: "Add new",
    SessionTest: "Session Test",
    Passmarks: "Pass marks",
    Midtermexam: "Mid term exam",
    Finalexam: "Final exam",
    Attendance: "Attendance",
    Requirements: "Requirements",
    Enrollmentprocess: "Enrollment process",
    Admissionsdeadline: "Admissions deadline",
    WhyKIU: "Why KIU",
    Informationbox: "Information box",
    Postaddress: "Post address",
    EnterthecontactdetailsbelowforstudentstosubmitApplication:
      "Enter the contact details below for students to submit Application",
    AutumnFallsemester: "Autumn/Fall semester",
    Startingdate: "Starting date",
    Lastdateofsubmission: "Last date of submission",
    FinalExams: "Final Exams",
    Springsemester: "Spring semester",
    WhoshouldstudyatKIU: "Who should study at KIU",
    BenefitofKIUdegree: "Benefit of KIU degree",
    AdmissionFee: "Admission Fees",
    Scholarships: "Scholarships",
    AdmisionAndRegistration: "Admission & Registration",
    Image: "Image",
    Password: "Password",
    ChangePassword: "Change Password",
    CurrentPassword: "Current Password",
    ConfirmPassword: "Confirm Password",
    College: "College",
    ForgotPassword: "Forgot Password",
    EmailAddress: "Email Address",
    ManageYouWebsiteWithEase: "Manage you website with ease",
    ResetPassword: "Reset Password",
    PhoneNumber: "Phone Number",
    CreateUser: "Create User",
    Arabic: "Arabic",
    English: "English",
    French: "French",
    urdu: "Urdu",
    NameInEnglish: "Name In English",
    NameInFrench: "Name In French",
    Search: "Search",
    Create: "Create",
    Semester: "Semester",
    Browse: "Browse",
    AppendixAndRegulations: "Appendix & Regulations",
    TeachingMethodologies: "Teaching Methodologies",
    Statistics: "Statistics",
    Testimonial: "Testimonial",
    GraduatedStudents: "Graduated Students",
    NumberOfColleges: "Number Of Colleges",
    FacultyInNumbers: "Faculty In Numbers",
    DegreePrograms: "Degree Programs",
    NumberOfScholarships: "Number Of Scholarships",
    PersonName: "Person Name",
    JobTitle: "Job/Profession Title",
    IndividualPicture: "Individual Picture",
    Heading: "Heading",
  },
  ///////////////////////////////////////////////
  /////////////////// Urdu ///////////////////
  ur: {
    newPassword: "نیا پاس ورڈ",
    NameInUrdu: "اردو میں نام",
    NameInArabic: "عربی میں نام",
    UserInformation: "صارف کی معلومات",
    EnterYourNewPasswordToLogin: "لاگ ان کے لئے اپنا نیا پاس ورڈ درج کریں",
    Login: "لاگ ان کریں",
    KIUWebsiteAdminPanel: "KIU ویب سائٹ ایڈمن پینل",
    YouWillReceiveAPasswordResetLinkOnYourEmail:
      "آپ کو اپنے ای میل پر پاس ورڈ دوبارہ ترتیب دینے والا لنک موصول ہوگا",
    Language: "زبان",
    Confirm: "تصدیق کریں",
    mewPassword: "نیا پاس ورڈ",
    EnterYourNewPasswordForLogin: "لاگ ان کے لئے اپنا نیا پاس ورڈ درج کریں",
    PersonalInformation: "ذاتی معلومات",
    MyProfile: "میری پروفائل",
    CurrentRates: "موجودہ شرحیں",
    year: "عام",
    Course: "کورس",
    AddNewSection: "نیا سیکشن شامل کریں",
    Delete: "حذف کریں",
    time: "وقت",
    Date: "تاریخ",
    text: "متن",
    Dashboard: "ڈیش بورڈ",
    WelcometowebsiteAdminPanel: "ویب سائٹ ایڈمن پینل میں خوش آمدید",
    Customizeyourwebsitecontentfromthispanelwithease:
      "آسانی سے اس ویب سائٹ سے اپنی ویب سائٹ کے مواد کو اپنی مرضی کے مطابق بنائیں",
    Visitors: "زائرین",
    UserName: "صارف نام",
    YourProfile: "آپ کی پروفائل",
    Logout: "لاگ آوٹ",
    Pages: "صفحات",
    Gallery: "گیلری",
    Users: "صارفین",
    Home: "گھر",
    HomeCarousel: "ہوم سلائیڈر",
    Uploadimage: "تصویر انٹرنیٹ پر ڈالنا",
    Maxsize: "زیادہ سے زیادہ سائز",
    RecommendedResolution: "تجویز کردہ قرارداد",
    CarouselText: "سلائیڈر متن",
    Normal: "عام",
    Savesection: "سیکشن کو بچانے کے",
    Interactivelectures: "انٹرایکٹو لیکچرز",
    Downloadablecontent: "ڈاؤن لوڈ کے قابل مواد",
    VirtualclassroomparticularGuide: "ورچوئل کلاس روم سے متعلق خاص گائیڈ",
    Remove: "دور",
    Typehere: "ٹائپہیر",
    Uploadfile: "اپ لوڈ فائل",
    Cancel: "منسوخ کریں",
    Addanother: "ایک اور شامل کریں",
    AcademicCalendar: "تعلیمی تقویم",
    EnterAcademicYear: "تعلیمی سال میں داخل ہوں",
    From: "سے",
    To: "کرنے کے لئے",
    StudentsGuide: "طلباء کے لئے رہنما",
    AboutUs: "ہمارے بارے میں",
    Changefile: "فائل کو تبدیل کریں",
    Savechanges: "تبدیلیاں محفوظ کرو",
    Vision: "اولین مقصد",
    Counters: "کاؤنٹرز",
    Title: "عنوان",
    Count: "شمار",
    Future: "مستقبل",
    Save: "محفوظ کریں",
    KIUVisionMissions: "KIU وژن مشنز",
    KIUDirectors: "KIU ڈائریکٹر",
    Boardoftrustees: "بورڈ آف ٹرسٹیز",
    Faculities: "اساتذہ",
    Collegesinstitutes: "کالجز انسٹی ٹیوٹ",
    Newsevents: "خبروں کے واقعات",
    FAQs: "عمومی سوالنامہ",
    Contactus: "رسالة من المدير",
    InstitutionAndEvidence: "ادارہ اور ثبوت",
    StatementsExecutiveDirector: "ایگزیکٹو ڈائریکٹر (سیکرٹری جنرل) کے بیانات",
    BoardTrustees: "بورڈ آف ٹرسٹیز اور اس کی کمیٹیاں",
    RegulationsAndPolicies: "ضابطے اور پالیسیاں",
    FinancialStatementDisclosure: "مالی بیان کا انکشاف",
    AnnualReports: "سالانہ رپورٹس",
    StrategicPlan: "اسٹریٹجک پلان",
    DirectorofKIU: "KIU کا ڈائریکٹر",
    FirstNameBold: "پہلا نام بولڈ",
    LastNameRegular: "آخری نام باقاعدہ",
    Description: "تفصیل",
    Directorsmessage: "ڈائریکٹر کا پیغام",
    Boardmembers: "بورڈ کے اراکین",
    ScholarsforArabicCourses: "عربی کورسز کے لئے اسکالرز",
    Name: "نام",
    ScholarsfornonArabicCourses: "غیر عربی کورسز کے لئے اسکالرز",
    Colleges: "کالجوں",
    Question: "سوال",
    Answer: "جواب",
    KnowledgeInternationalUniversity: "نالج انٹرنیشنل یونیورسٹی",
    AdministratorAddress: "ایڈمنسٹریٹر کا پتہ",
    POBox: "پی. او. باکس",
    City: "شہر",
    KingdomofSaudiArabia: "مملکت سعودی عرب",
    Email: "ای میل",
    Fax: "فیکس",
    Edit: "ترمیم",
    Islamicstudies: "اسلامی علوم",
    UrduLiterature: "اردو ادب",
    FrenchLiterature: "فرانسیسی ادب",
    EnglishLiterature: "انگریزی ادب",
    AboutDegree: "ڈگری کے بارے میں",
    Syllabusbysemester: "نصاب بذریعہ سمسٹر",
    TuitionFees: "ٹیوشن فیس",
    Academichelp: "تعلیمی مدد",
    AddNewDegree: "نیا ڈگری شامل کریں",
    Add: "شامل کریں",
    Studyacourse: "ایک کورس کا مطالعہ کریں",
    Coursesinclude: "کورسز بھی شامل ہیں",
    VideoLectures: "ویڈیو لیکچرز",
    Value: "قدر",
    Livesessions: "براہ راست سیشن",
    Coursematerial: "کورس مواد",
    Assignments: "اسائنمنٹس",
    Discussionforum: "ڈسکشن فورم",
    FinalExam: "حتمی امتحان",
    Courses: "کورسز",
    OnlineDegree: "آن لائن ڈگری",
    Coursecategories: "کورس کیٹیگریز",
    FeeStructure: "فیس کا ڈھانچہ",
    Onlinefreecourses: "آن لائن مفت کورسز",
    Coursecatalogue: "کورس کا کیٹلاگ",
    Coursetitle: "کورس کا عنوان",
    Credithours: "کریڈٹ اوقات",
    Instructor: "انسٹرکٹر",
    Required: "ضروری",
    Objectives: "مقاصد",
    Topicstocover: "عنوانات احاطہ کرنے کے لئے",
    Addnewsubject: "نیا مضمون شامل کریں",
    KIUFees: "KIU فیس",
    Currency: "کرنسی",
    ApplicationFees: "درخواست کی فیس",
    Fulltimestudents: "کل وقتی طلبہ",
    Onecoursemodule: "ایک کورس ماڈیول",
    ScholarshipEntitlement: "وظیفے کا اختیار",
    Paymentinstructions: "ادائیگی کی ہدایات",
    Paymentdetails: "ادائیگی کی تفصیلات",
    Bank: "بینک",
    Branch: "شاخ",
    Beneficiarysname: "فائدہ اٹھانے والا نام",
    AccountNo: "اکاؤنٹ کا نمبر",
    Swiftcode: "سوفٹ کوڈ",
    KIUFreecourselectures: "مفت کورس لیکچرز KIU",
    Coursename: "کورس کا نام",
    Accesslink: "رسائی لنک",
    Lecturer: "لیکچرر",
    Addnewcourse: "نیا کورس شامل کریں",
    Howitworks: "یہ کیسے کام کرتا ہے",
    Studyoverview: "مطالعہ کا جائزہ",
    Studentportal: "طلباء پورٹل",
    Studymaterial: "مطالعہ کا مواد",
    Videolectures: "ویڈیو لیکچرز",
    KIUstudentforum: "طلباء فورم KIU",
    Assessment: "تشخیص کے",
    Insummaryeachmodulecomprisesof: "خلاصہ میں ہر ماڈیول پر مشتمل ہوتا ہے",
    Currentlysemester1ismadeupoffollowingsubjects:
      "فی الحال سمسٹر 1 مندرجہ ذیل مضامین پر مشتمل ہے",
    Subject: "مضمون",
    Addnew: "نیا شامل کریں",
    SessionTest: "سیشن ٹیسٹ",
    Passmarks: "پاس مارکس",
    Midtermexam: "درمیانی مدت کے امتحانات",
    Finalexam: "حتمی امتحان",
    Attendance: "حاضری",
    Requirements: "تقاضے",
    Enrollmentprocess: "اندراج کے عمل",
    Admissionsdeadline: "داخلے کی آخری تاریخ",
    WhyKIU: "کیوں؟ KIU",
    Informationbox: "انفارمیشن باکس",
    Postaddress: "پوسٹ ایڈریس",
    EnterthecontactdetailsbelowforstudentstosubmitApplication:
      "طلبہ کو درخواست جمع کروانے کے لئے نیچے رابطے کی تفصیلات درج کریں",
    AutumnFallsemester: "موسم خزاں کے موسم خزاں کے سمسٹر",
    Startingdate: "شروع ہونے والی تاریخ",
    Lastdateofsubmission: "گذارش کی آخری تاریخ",
    FinalExams: "آخری امتحانات",
    Springsemester: "بہار سمسٹر",
    WhoshouldstudyatKIU: "کون پڑھنا چاہئے",
    BenefitofKIUdegree: "ڈگری کا فائدہ KIU",
    AdmissionFee: "داخلہ فیس",
    Scholarships: "وظائف",
    AdmisionAndRegistration: "داخلہ اور اندراج",
    Image: "تصویر",
    Password: "پاس ورڈ",
    Replace: "بدل دیں",
    ChangePassword: "پاس ورڈ تبدیل کریں",
    CurrentPassword: "موجودہ خفیہ لفظ",
    ConfirmPassword: "پاس ورڈ کی تصدیق کریں",
    College: "کالج",
    ForgotPassword: "پاسورڈ بھول گے",
    EmailAddress: "ای میل اڈریس",
    ManageYouWebsiteWithEase: "مینیج یوٹ ویب سائٹ کے ساتھ",
    ResetPassword: "پاس ورڈ ری سیٹ",
    PhoneNumber: "فون نمبر",
    CreateUser: "صارف بنائیں",
    Arabic: "عربی",
    English: "انگریزی",
    French: "فرانسیسی",
    urdu: "اردو",
    NameInEnglish: "انگریزی میں نام",
    NameInFrench: "نام فرانسیسی میں",
    Search: "تلاش کریں",
    Create: "بنانا",
    Semester: "سمسٹر",
    Browse: "براؤز کریں",
    AppendixAndRegulations: "ضمیمہ اور ضوابط",
    TeachingMethodologies: "تدریسی طریقہ کار",
    Statistics: "اعداد و شمار",
    Testimonial: "تعریف",
    GraduatedStudents: "فارغ التحصیل طلباء",
    NumberOfColleges: "کالجوں کی تعداد",
    FacultyInNumbers: "تعداد میں فیکلٹی",
    DegreePrograms: "ڈگری پروگرامز",
    NumberOfScholarships: "وظائف کی تعداد",
    PersonName: "شخص کا نام",
    JobTitle: "ملازمت/پیشہ کا عنوان",
    IndividualPicture: "انفرادی تصویر",
    Heading: "سرخی",
  },
  ///////////////////////////////////////////////
  /////////////////// French ///////////////////
  fr: {
    newPassword: "nouveau mot de passe",
    NameInUrdu: "Nom en ourdou",
    NameInArabic: "Nom en arabe",
    NameInEnglish: "Nom en anglais",
    NameInFrench: "Nom en français",
    Arabic: "arabe",
    English: "Anglais",
    French: "français",
    urdu: "ourdou",
    CreateUser: "Créer un utilisateur",
    UserInformation: "informations de l'utilisateur",
    PhoneNumber: "Numéro de téléphone",
    EnterYourNewPasswordToLogin:
      "Entrez votre nouveau mot de passe pour vous connecter",
    ResetPassword: "Réinitialiser le mot de passe",
    Login: "Connexion",
    ManageYouWebsiteWithEase: "ManageYouWebsiteWithEase",
    KIUWebsiteAdminPanel: "Panneau d'administration du site Web KIU",
    EmailAddress: "adresse e-mail",
    YouWillReceiveAPasswordResetLinkOnYourEmail:
      "Vous recevrez un lien de réinitialisation du mot de passe sur votre e-mail",
    ForgotPassword: "Mot de passe oublié",
    Language: "Langue",
    College: "Université",
    Confirm: "Confirmer",
    ConfirmPassword: "Confirmez le mot de passe",
    mewPassword: "nouveau mot de passe",
    CurrentPassword: "Mot de passe actuel",
    EnterYourNewPasswordForLogin:
      "Entrez votre nouveau mot de passe pour vous connecter",
    Password: "Mot de passe",
    PersonalInformation: "Renseignements personnels",
    MyProfile: "Mon profil",
    CurrentRates: "Les taux courants",
    year: "an",
    Course: "Cours",
    AddNewSection: "Ajouter une nouvelle section",
    Delete: "Effacer",
    time: "temps",
    Date: "Date",
    text: "text",
    Image: "Image",
    AdmisionAndRegistration: "Admission et inscription",
    Scholarships: "Bourses d'études",
    AdmissionFee: "Frais d'admission",
    Dashboard: "Tableau de bord",
    WelcometowebsiteAdminPanel:
      "Bienvenue dans le panneau d'administration du site Web",
    Customizeyourwebsitecontentfromthispanelwithease:
      "Personnalisez facilement le contenu de votre site Web à partir de ce panneau",
    Visitors: "Visiteurs",
    UserName: "Nom d'utilisateur",
    YourProfile: "Votre profil",
    Logout: "Se déconnecter",
    Pages: "des pages",
    Gallery: "Galerie",
    Users: "utilisateurs",
    Home: "Domicile",
    HomeCarousel: "Carrousel d'accueil",
    Uploadimage: "Télécharger l'image",
    Maxsize: "Taille max",
    RecommendedResolution: "Résolution recommandée",
    CarouselText: "Texte du carrousel",
    Normal: "Normal",
    Savesection: "Enregistrer la section",
    Interactivelectures: "Conférences interactives",
    Downloadablecontent: "contenu téléchargeable",
    VirtualclassroomparticularGuide: "Guide particulier de la classe virtuelle",
    Remove: "supprimer",
    Typehere: "Écrivez ici",
    Uploadfile: "Téléverser un fichier",
    Cancel: "Annuler",
    Addanother: "ajouter un autre",
    AcademicCalendar: "calendrier académique",
    EnterAcademicYear: "Entrez l'année académique",
    From: "De",
    To: "à",
    StudentsGuide: "Guide des étudiants",
    AboutUs: "À propos de nous",
    Changefile: "changer de fichier",
    Savechanges: "Sauvegarder les modifications",
    Vision: "vision",
    Counters: "Compteurs",
    Title: "Titre",
    Count: "compter",
    Future: "futur",
    Save: "sauvegarder",
    KIUVisionMissions: "Missions de vision KIU",
    KIUDirectors: "Directeurs KIU",
    Boardoftrustees: "Conseil d'administration",
    Faculities: "Faculités",
    Collegesinstitutes: "Collèges et instituts",
    Newsevents: "Nouvelles et événements",
    FAQs: "FAQ",
    Contactus: "Contactez-nous",
    InstitutionAndEvidence: "Institution et preuves",
    StatementsExecutiveDirector:
      "Déclarations du Directeur exécutif (Secrétaire général)",
    BoardTrustees: "Le conseil d'administration et ses comités",
    RegulationsAndPolicies: "Règlements et politiques",
    FinancialStatementDisclosure: "Divulgation des états financiers",
    AnnualReports: "Rapport annuel",
    StrategicPlan: "Plan stratégique",
    DirectorofKIU: "Directeur de KIU",
    FirstNameBold: "Prénom (gras)",
    LastNameRegular: "Nom de famille (régulier)",
    Description: "la description",
    Directorsmessage: "Message du réalisateur",
    Boardmembers: "membres du conseil d'administration",
    ScholarsforArabicCourses: "Boursiers pour les cours d'arabe",
    Name: "Nom",
    ScholarsfornonArabicCourses: "Boursiers pour les cours non arabes",
    Colleges: "collèges",
    Question: "question",
    Answer: "répondre",
    KnowledgeInternationalUniversity:
      "Université internationale de la connaissance",
    AdministratorAddress: "Adresse de l'administrateur",
    POBox: "boîte postale",
    City: "ville",
    KingdomofSaudiArabia: "Royaume d'Arabie Saoudite",
    Email: "e-mail",
    Fax: "fax",
    Edit: "Éditer",
    Islamicstudies: "études islamiques",
    UrduLiterature: "Littérature ourdou",
    FrenchLiterature: "Littérature française",
    EnglishLiterature: "littérature anglaise",
    AboutDegree: "À propos du diplôme",
    Syllabusbysemester: "Programme par semestre",
    TuitionFees: "frais de scolarité",
    Academichelp: "Aide académique",
    AddNewDegree: "Ajouter un nouveau diplôme",
    Add: "ajouter",
    Studyacourse: "Étudiez un cours",
    Coursesinclude: "Les cours comprennent",
    VideoLectures: "Conférences vidéo",
    Value: "valeur",
    Livesessions: "Sessions en direct",
    Coursematerial: "Course material",
    Assignments: "Affectations",
    Discussionforum: "forum de discussion",
    FinalExam: "Examen final",
    Courses: "cours",
    OnlineDegree: "diplôme en ligne",
    Coursecategories: "Catégories de cours",
    FeeStructure: "Structure de frais",
    Onlinefreecourses: "Cours gratuits en ligne",
    Coursecatalogue: "Catalogue des cours",
    Coursetitle: "titre de cours",
    Credithours: "des heures de credit",
    Instructor: "Instructeur",
    Required: "Obligatoire",
    Objectives: "objectifs",
    Topicstocover: "Thèmes à couvrir",
    Addnewsubject: "Ajouter un nouveau sujet",
    KIUFees: "Frais KIU",
    Currency: "Devise",
    ApplicationFees: "Frais de candidature",
    Fulltimestudents: "Étudiants à temps plein",
    Onecoursemodule: "Un module de cours",
    ScholarshipEntitlement: "Droit aux bourses",
    Paymentinstructions: "Instructions de paiement",
    Paymentdetails: "Détails de paiement",
    Bank: "Banque",
    Branch: "Branche",
    Beneficiarysname: "Nom du bénéficiaire",
    AccountNo: "N ° de compte",
    Swiftcode: "Code rapide",
    KIUFreecourselectures: "Cours de cours gratuits KIU",
    Coursename: "Nom du cours",
    Accesslink: "Lien d'accès",
    Lecturer: "Maître de conférences",
    Addnewcourse: "Ajouter un nouveau cours",
    Howitworks: "Comment ça fonctionne",
    Studyoverview: "Aperçu de l'étude",
    Studentportal: "Portail étudiant",
    Studymaterial: "Matériel d'étude",
    Videolectures: "Conférences vidéo",
    KIUstudentforum: "Forum étudiant KIU",
    Assessment: "Évaluation",
    Insummaryeachmodulecomprisesof: "En résumé, chaque module comprend",
    Currentlysemester1ismadeupoffollowingsubjects:
      "Actuellement le semestre1 est composé des matières suivantes",
    Subject: "Matière",
    Addnew: "Ajouter un nouveau",
    SessionTest: "Test de session",
    Passmarks: "Notes de passage",
    Midtermexam: "Examen de mi-saison",
    Finalexam: "Examen final",
    Attendance: "Présence",
    Requirements: "Conditions",
    Enrollmentprocess: "Processus d'inscription",
    Admissionsdeadline: "Date limite d'admission",
    WhyKIU: "Pourquoi KIU",
    Informationbox: "Boîte d'information",
    Postaddress: "Adresse postale",
    EnterthecontactdetailsbelowforstudentstosubmitApplication:
      "Entrez les coordonnées ci-dessous pour que les étudiants soumettent leur candidature",
    AutumnFallsemester: "Semestre d'automne / automne",
    Startingdate: "date de début",
    Lastdateofsubmission: "Dernière date de soumission",
    FinalExams: "Examens finaux",
    Springsemester: "semestre de printemps",
    WhoshouldstudyatKIU: "Qui devrait étudier à KIU",
    BenefitofKIUdegree: "Bénéficier du diplôme KIU",
    Replace: "Remplacer",
    ChangePassword: "Changer le mot de passe",
    Search: "Rechercher",
    Create: "Créer",
    Semester: "Semestre",
    Browse: "Parcourir",
    AppendixAndRegulations: "Annexe et Règlement",
    TeachingMethodologies: "Méthodologies d'enseignement",
    Statistics: "statistiques",
    Testimonial: "Témoignage",
    GraduatedStudents: "Étudiants diplômés",
    NumberOfColleges: "Nombre de collèges",
    FacultyInNumbers: "Faculté en chiffres",
    DegreePrograms: "Programmes d'études",
    NumberOfScholarships: "Nombre de bourses",
    PersonName: "Nom d'une personne",
    JobTitle: "Titre d’emploi/profession",
    IndividualPicture: "Photo individuelle",
    Heading: "Titre",
  },
});
export default trans;
