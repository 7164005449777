import React, { useState, useEffect } from "react";
import { Card, message, Button, Skeleton, Form, Input } from "antd";
import { createURLById } from "../../../Constants/globals";
import { withStyles } from "@material-ui/core/styles";
import Editor from "../../Components/common/Editor";
import AccordionContent from "../../Components/common/AccordionContent";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import MuiAccordion from "@material-ui/core/Accordion";
import UploadImageHeader from "./../../Components/common/UploadImageHeader";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import trans from "../../../Languages/Languages";
import API from "../../../API";
import AccordionCalendarContent from "./../../Components/Home/AccordionCalendarContent";
import TeachingMethodologyAccordion from "../../Components/Home/TeachingMethodologyAccordion";
import TestimonialAccordion from "../../Components/Home/TestimonialAccordion";

function Home() {
  const [homeData, setHomeData] = useState();
  const [interactiveData, setInteractiveData] = React.useState([]);
  const [newInteractiveData, setNewInteractiveData] = React.useState([]);
  const [studentGuideData, setStudentGuideData] = React.useState([]);
  const [newStudentGuideData, setNewStudentGuideData] = React.useState([]);
  const [academicCalendarData, setAcademicCalendarData] = React.useState();
  const [appendixData, setAppendixData] = React.useState([]);
  const [newAppendixData, setNewAppendixData] = React.useState([]);
  const [statisticsData, setStatisticsData] = React.useState({});
  const [expanded, setExpanded] = React.useState();
  const [currentPagePagination, setCurrentPagePagination] = React.useState(0);
  const [aboutEditorHtml, setAboutEditorHtml] = React.useState("");
  const [loading, setLoading] = useState(false);
  const [carouselLoading, setCarouselLoading] = useState(false);
  const [interactiveLoading, setInteractiveLoading] = useState(false);
  const [appendixLoading, setAppendixLoading] = useState(false);
  const [studentGuideLoading, setStudentGuideLoading] = useState(false);
  const [aboutSectionLoading, setAboutSectionLoading] = useState(false);
  const [statisticsSectionLoading, setStatisticsSectionLoading] =
    useState(false);
  const [refresh, setRefresh] = useState(true);
  const [errors, setErrors] = useState({});

  const [images, setImages] = useState([
    { image: "" },
    { image: "" },
    { image: "" },
    { image: "" },
  ]);

  ///////////////////////////////////////////////////////////////////////////////////////////////
  ///                            Use Effect                                                   ///
  ///////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    setLoading(true);

    const init = () => {
      API.get("/admin/home/content")
        .then((res) => {
          setHomeData(res.data);
          setLoading(false);
          setAboutEditorHtml(res.data.about.about);
        })
        .catch((err) => {
          setLoading(false);
          setErrors("Something Went Wrong");
        });
    };

    init();

    // get carousels data
    const about = () => {
      API.get("/admin/home/about")
        .then((res) => {
          setAboutEditorHtml(res.data.about);
        })
        .catch((err) => {
          setLoading(false);
          setErrors("Something Went Wrong");
        });
    };

    about();

    // get academic calendar data
    const academicCalendar = () => {
      API.get("/admin/calender")
        .then((res) => {
          setLoading(false);

          setAcademicCalendarData(res.data);
        })
        .catch((err) => {
          setLoading(false);
          setErrors("Something Went Wrong");
        });
    };
    academicCalendar();

    // get interactive data
    const interactiveLecture = () => {
      API.get("/admin/interactive/lecture")
        .then((res) => {
          setInteractiveData(res.data);
        })
        .catch((err) => {
          setLoading(false);
          setErrors("Something Went Wrong");
        });
    };

    // get appendix data
    const appendixRegulations = () => {
      API.get("/admin/appendix")
        .then((res) => {
          setAppendixData(res.data);
        })
        .catch((err) => {
          setLoading(false);
          setErrors("Something Went Wrong");
        });
    };

    // get student guide
    const studentGuide = () => {
      API.get("/admin/student/guide")
        .then((res) => {
          setStudentGuideData(res.data);
        })
        .catch((err) => {
          setLoading(false);
          setErrors("Something Went Wrong");
        });
    };

    // get statistics guide
    const statistics = () => {
      API.get("/admin/statistics")
        .then((res) => {
          if (res.status === 200) {
            setStatisticsData(res.data);
          }
        })
        .catch((err) => {
          setLoading(false);
          setErrors("Something Went Wrong");
        });
    };

    interactiveLecture();
    appendixRegulations();
    studentGuide();
    statistics();
  }, [refresh]);

  // handle accordion change
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleCurrentPagePagination = (currentPage) => {
    setCurrentPagePagination(currentPage);
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////
  ///                  Accordion Add Another/Delete methods
  ////////////////////////////////////////////////////////////////////////////////////////////////
  const handleInteractiveAnother = () => {
    const newData = [
      ...interactiveData,
      {
        title: "",
        file: "",
        new: true,
      },
    ];
    setInteractiveData(newData);

    const newRecord = [
      ...newInteractiveData,
      {
        title: "",
        file: "",
        new: true,
      },
    ];
    setNewInteractiveData(newRecord);
  };
  const handleStudentGuideAnother = () => {
    const newData = [
      ...studentGuideData,
      {
        title: "",
        file: "",
        new: true,
      },
    ];
    setStudentGuideData(newData);

    const newRecord = [
      ...newStudentGuideData,
      {
        title: "",
        file: "",
        new: true,
      },
    ];
    setNewStudentGuideData(newRecord);
  };

  const handleAppendixAnother = () => {
    const newData = [
      ...appendixData,
      {
        title: "",
        file: "",
        new: true,
      },
    ];
    setAppendixData(newData);

    const newRecord = [
      ...newAppendixData,
      {
        title: "",
        file: "",
        new: true,
      },
    ];
    setNewAppendixData(newRecord);
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////
  ///                 handle Interactive and student guide delete
  ////////////////////////////////////////////////////////////////////////////////////////////////
  const handleInteractiveDelete = (index) => {
    const newData = interactiveData.filter((item, i) => {
      return index !== i;
    });

    setInteractiveData(newData);
  };
  const handleStudentGuideDelete = (index) => {
    const newData = studentGuideData.filter((item, i) => {
      return index !== i;
    });
    setStudentGuideData(newData);
  };

  const handleAppendixDelete = (index) => {
    const newData = appendixData.filter((item, i) => {
      return index !== i;
    });

    setAppendixData(newData);
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////
  ///                 update carousals data
  ////////////////////////////////////////////////////////////////////////////////////////////////
  const handleHomeCarouselSaveSection = async () => {
    let isError = false;
    homeData.carosal_data.forEach((element) => {
      if (element.image === "") {
        isError = true;
      }
    });

    if (!isError) {
      setCarouselLoading(true);
      const formData = new FormData();
      homeData.carosal_data.forEach((e, index) => {
        formData.append(`data[${index}][image_id]`, e.id);
        if (images[index].image === "") {
          formData.append(`data[${index}][image]`, e.image);
        } else formData.append(`data[${index}][image]`, images[index].image);
        formData.append(`data[${index}][image_text]`, e.image_text);
      });

      await API.post("/admin/home/content/update", formData)
        .then((res) => {
          message.success("Carousel Updated Successfully", 3);
          setCarouselLoading(false);
        })
        .catch((err) => {
          Object.keys(err.response.data).map((e) => {
            message.error(err.response.data[e]);
          });
          setCarouselLoading(false);
        });
    } else message.error("All 4 carousel images required");
  };
  // end accordion

  const handleAboutEditorChange = (html) => {
    setAboutEditorHtml(html);
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////
  ///                 update about section
  ////////////////////////////////////////////////////////////////////////////////////////////////
  const handleSaveAboutUs = async () => {
    setAboutSectionLoading(true);
    const formData = new FormData();
    formData.append("about", aboutEditorHtml);

    await API.post("/admin/home/about/update", formData)
      .then((res) => {
        setAboutSectionLoading(false);
        message.success("About section Updated Successfully", 3);
      })
      .catch((err) => {
        message.error("Something went wrong, please try again", 3);
        setAboutSectionLoading(false);
      });
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////
  ///                 update statistics section
  ////////////////////////////////////////////////////////////////////////////////////////////////
  const handleStatisticsSave = async (values) => {
    setStatisticsSectionLoading(true);
    const formData = new FormData();

    formData.append(
      "graduated_students",
      values.graduated_students || statisticsData.graduated_students
    );
    formData.append(
      "number_of_colleges",
      values.number_of_colleges || statisticsData.number_of_colleges
    );
    formData.append(
      "faculty_in_numbers",
      values.faculty_in_numbers || statisticsData.faculty_in_numbers
    );
    formData.append(
      "degree_programs",
      values.degree_programs || statisticsData.degree_programs
    );
    formData.append(
      "number_of_scholarships",
      values.number_of_scholarships || statisticsData.number_of_scholarships
    );

    await API.post("/admin/statistics/update", formData)
      .then((res) => {
        setStatisticsSectionLoading(false);
        setRefresh(!refresh);
        message.success("Statistics Section Updated Successfully", 3);
      })
      .catch((err) => {
        setStatisticsSectionLoading(false);
        message.error("Something went wrong, please try again", 3);
      });
  };

  return (
    <div className="pages-container">
      <div className="content-wrapper">
        <Skeleton loading={loading} active></Skeleton>
        <Skeleton loading={loading} active></Skeleton>
        <Skeleton loading={loading} active></Skeleton>
        <Skeleton style={{ minHeight: 600 }} loading={loading} active>
          <Card
            style={{ minHeight: 600 }}
            bordered={false}
            className="p-0"
            // loading={loading}
          >
            <div className="bg-content-container">
              <div className="fw-600 h-heading">{trans.HomeCarousel}</div>
              {/* //////// pagination //////// */}
              {homeData?.carosal_data.map((image, index) => {
                return (
                  <div
                    key={index}
                    className={
                      currentPagePagination === index
                        ? "h-pagination h-pagination-active"
                        : "h-pagination h-pagination-inActive"
                    }
                    onClick={() => {
                      handleCurrentPagePagination(index);
                    }}
                  >
                    <p>{index + 1}</p>
                  </div>
                );
              })}
              <Card loading={carouselLoading} bordered={false}>
                {homeData && (
                  <UploadImageHeader
                    images={images}
                    setImages={setImages}
                    record={homeData}
                    currentPagePagination={currentPagePagination}
                    setHomeData={setHomeData}
                    setLoading={setCarouselLoading}
                  />
                )}
              </Card>
              <div className="d-flex justify-content-end save-section">
                {/* save changes */}
                <div
                  className="btn-save-section fw-500"
                  onClick={handleHomeCarouselSaveSection}
                >
                  <span>{trans.Savesection}</span>
                </div>
              </div>

              {/* end */}
            </div>

            {/* accordion Interactive */}
            <div className="h-accordion-container">
              {interactiveData && (
                <AccordionUpload
                  key={1}
                  id={0}
                  name={trans.Interactivelectures}
                  expanded={expanded}
                  handleChange={handleChange}
                  handleDelete={handleInteractiveDelete}
                  handleAnother={handleInteractiveAnother}
                  data={interactiveData}
                  setData={setInteractiveData}
                  newData={newInteractiveData}
                  setNewData={setNewInteractiveData}
                  setLoading={setInteractiveLoading}
                  refresh={refresh}
                  setRefresh={setRefresh}
                  loading={interactiveLoading}
                />
              )}
            </div>

            {academicCalendarData && academicCalendarData.length > 0 && (
              <>
                {/* accordion academic calendar */}
                <div className="h-accordion-container">
                  <AccordionUploadCalendar
                    key="accordionUpload4"
                    name={trans.AcademicCalendar}
                    expanded={expanded}
                    data={academicCalendarData}
                    setData={setAcademicCalendarData}
                    handleChange={handleChange}
                    setLoading={setLoading}
                    loading={loading}
                    refresh={refresh}
                    setRefresh={setRefresh}
                  />
                </div>
              </>
            )}

            {/* accordion Teaching Methodologies */}
            <div className="h-accordion-container">
              <AccordionUploadTeaching
                key="accordionUpload5"
                name={trans.TeachingMethodologies}
                expanded={expanded}
                handleChange={handleChange}
                setLoading={setLoading}
                loading={loading}
                refresh={refresh}
                setRefresh={setRefresh}
              />
            </div>

            {/* accordion Appendix & Regulations */}
            <div className="h-accordion-container">
              {appendixData && (
                <AccordionUpload
                  key={2}
                  id={1}
                  name={trans.AppendixAndRegulations}
                  expanded={expanded}
                  handleChange={handleChange}
                  handleAnother={handleAppendixAnother}
                  handleDelete={handleAppendixDelete}
                  data={appendixData}
                  setData={setAppendixData}
                  newData={newAppendixData}
                  setNewData={setNewAppendixData}
                  setLoading={setAppendixLoading}
                  refresh={refresh}
                  setRefresh={setRefresh}
                  loading={appendixLoading}
                />
              )}
            </div>

            {/* accordion student guide */}
            <div className="h-accordion-container">
              {studentGuideData && (
                <AccordionUpload
                  key={3}
                  id={2}
                  name={trans.StudentsGuide}
                  expanded={expanded}
                  handleChange={handleChange}
                  handleDelete={handleStudentGuideDelete}
                  handleAnother={handleStudentGuideAnother}
                  data={studentGuideData}
                  setData={setStudentGuideData}
                  setLoading={setStudentGuideLoading}
                  refresh={refresh}
                  setRefresh={setRefresh}
                  newData={newStudentGuideData}
                  setNewData={setNewStudentGuideData}
                  loading={studentGuideLoading}
                />
              )}
            </div>

            {/* accordion Testimonial */}
            <div className="h-accordion-container">
              <AccordionUploadTestimonial
                key="accordionUpload6"
                name={trans.Testimonial}
                expanded={expanded}
                handleChange={handleChange}
                setLoading={setLoading}
                loading={loading}
                refresh={refresh}
                setRefresh={setRefresh}
              />
            </div>

            {/* about us text Area */}
            <div className="bg-content-container">
              <div className={{ marginBottom: 100 }}>
                <div className="fw-600 carousel-text-heading mb-2">
                  {trans.AboutUs}
                </div>
                <Card
                  bordered={false}
                  className="p-0"
                  loading={aboutSectionLoading}
                >
                  <div style={{ marginBottom: 23 }}>
                    <Editor
                      key="HomeTextAreas"
                      editorHtml={aboutEditorHtml}
                      setEditorHtml={setAboutEditorHtml}
                      handleChange={handleAboutEditorChange}
                    />
                  </div>
                </Card>

                {/* save changes */}
                <div className="d-flex justify-content-end">
                  <div
                    className="btn-save-section fw-500 float-right"
                    onClick={handleSaveAboutUs}
                  >
                    <span>{trans.Savesection}</span>
                  </div>
                </div>
              </div>
            </div>

            {/* Statistics */}
            <div className="bg-content-container">
              <div className={{ marginBottom: 100 }}>
                <div className="fw-600 carousel-text-heading mb-2">
                  {trans.Statistics}
                </div>
                <Card
                  bordered={false}
                  className="p-0"
                  loading={statisticsSectionLoading}
                >
                  <div style={{ marginBottom: 23 }}>
                    <Form onFinish={handleStatisticsSave}>
                      <div
                        className={
                          "d-flex align-items-center justify-content-between"
                        }
                      >
                        <div className="accordion-detail d-input-name mr-5">
                          <div className="f-12 fw-500 input-heading">
                            {trans.GraduatedStudents}
                          </div>
                          <Form.Item
                            name="graduated_students"
                            initialValue={statisticsData?.graduated_students}
                            className="m-0"
                          >
                            <Input placeholder={trans.Typehere} />
                          </Form.Item>
                          {errors?.first_name && (
                            <span className="text-danger">
                              {errors?.first_name}
                            </span>
                          )}
                        </div>

                        <div className="accordion-detail d-input-name mr-5">
                          <div className="f-12 fw-500 input-heading">
                            {trans.NumberOfColleges}
                          </div>
                          <Form.Item
                            name="number_of_colleges"
                            initialValue={statisticsData?.number_of_colleges}
                            className="m-0"
                          >
                            <Input placeholder={trans.Typehere} />
                          </Form.Item>
                          {errors?.first_name && (
                            <span className="text-danger">
                              {errors?.first_name}
                            </span>
                          )}
                        </div>

                        <div className="accordion-detail d-input-name mr-5">
                          <div className="f-12 fw-500 input-heading">
                            {trans.FacultyInNumbers}
                          </div>
                          <Form.Item
                            name="faculty_in_numbers"
                            initialValue={statisticsData?.faculty_in_numbers}
                            className="m-0"
                          >
                            <Input placeholder={trans.Typehere} />
                          </Form.Item>
                          {errors?.first_name && (
                            <span className="text-danger">
                              {errors?.first_name}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className={"d-flex align-items-center"}>
                        <div className="accordion-detail d-input-name mr-5">
                          <div className="f-12 fw-500 input-heading">
                            {trans.DegreePrograms}
                          </div>
                          <Form.Item
                            name="degree_programs"
                            initialValue={statisticsData?.degree_programs}
                            className="m-0"
                          >
                            <Input placeholder={trans.Typehere} />
                          </Form.Item>
                          {errors?.first_name && (
                            <span className="text-danger">
                              {errors?.first_name}
                            </span>
                          )}
                        </div>

                        <div className="accordion-detail d-input-name mr-5">
                          <div className="f-12 fw-500 input-heading">
                            {trans.NumberOfScholarships}
                          </div>
                          <Form.Item
                            name="number_of_scholarships"
                            initialValue={
                              statisticsData?.number_of_scholarships
                            }
                            className="m-0"
                          >
                            <Input placeholder={trans.Typehere} />
                          </Form.Item>
                          {errors?.first_name && (
                            <span className="text-danger">
                              {errors?.first_name}
                            </span>
                          )}
                        </div>
                      </div>

                      {/* save changes */}
                      <Button
                        className="btn-save-changes"
                        type="primary"
                        htmlType="submit"
                      >
                        {trans.Save}
                      </Button>
                      {/*<div className="d-flex justify-content-end">*/}
                      {/*  <div*/}
                      {/*      className="btn-save-section fw-500 float-right"*/}
                      {/*      onClick={handleStatisticsSave}*/}
                      {/*  >*/}
                      {/*    <span>{trans.Savesection}</span>*/}
                      {/*  </div>*/}
                      {/*</div>*/}
                    </Form>
                  </div>
                </Card>
              </div>
            </div>
          </Card>
        </Skeleton>
      </div>
    </div>
  );
}

export default Home;

const AccordionUpload = ({
  name,
  expanded,
  handleChange,
  handleDelete,
  handleAnother,
  data,
  setData,
  newData,
  setNewData,
  setLoading,
  refresh,
  setRefresh,
  id,
  loading,
}) => {
  ///////////////////////////////////////////////////////////////////////////////////////////////
  ///                             create new records
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const handleCreateRecords = async () => {
    let error = false;
    if (data.length > 0) {
      const formData = new FormData();
      data.forEach((element, i) => {
        if (!(element.id)) {
          formData.append(`data[${i}][title]`, element.title);
          formData.append(`data[${i}][file]`, element.file);
        }
        if (element.title === "" || element.file === "") {
          error = true;
        }
      });

      if (!error) {
        let createUrl = createURLById[id];

        await API.post(createUrl, formData)
          .then((res) => {
            message.success("Created Successfully", 3);
            setNewData([]);
            setRefresh(!refresh);
          })
          .catch((err) => {
            message.error("Something went wrong, please try again", 3);
            setNewData([]);
            setRefresh(!refresh);
          });
      } else {
        message.error("Text or File/Image field is empty, fill all fields", 3);
      }
    }
  };

  return (
    <Accordion
      square
      expanded={expanded === `panel${id}`}
      onChange={handleChange(`panel${id}`)}
    >
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <p className="f-14 fw-600 mb-0">
          {name}
          <span>
            <KeyboardArrowDownIcon className="down-arrow-accordion" />
          </span>
        </p>
      </AccordionSummary>
      <AccordionDetails className="accordion-detail">
        <Card bordered={false} className="p-0" loading={loading}>
          <div>
            <p className="f-16 fw-500">{trans.Downloadablecontent}</p>

            {data.map((item, index) => {
              return (
                <AccordionContent
                  key={index}
                  index={index}
                  data={item}
                  originData={data}
                  handleDelete={handleDelete}
                  setData={setData}
                  id={id}
                  newData={newData}
                  setNewData={setNewData}
                  setLoading={setLoading}
                  refresh={refresh}
                  setRefresh={setRefresh}
                />
              );
            })}
            <span
              className="f-12 fw-500 align-middle h-add-another"
              onClick={handleAnother}
            >
              {trans.Addanother}
              <span className="f-18 align-middle">+</span>
            </span>
            {newData.length > 0 && (
              <div className="d-flex justify-content-end">
                <Button
                  onClick={handleCreateRecords}
                  className="btn-save-changes"
                  type="primary"
                  htmlType="submit"
                >
                  {trans.Save}
                </Button>
              </div>
            )}
          </div>
        </Card>
      </AccordionDetails>
    </Accordion>
  );
};

export { AccordionUpload };
///////////////////////////////////////////////////////////////////////////////////////////////
///                  Accordion Academic Calendar
////////////////////////////////////////////////////////////////////////////////////////////////
const AccordionUploadCalendar = ({
  name,
  expanded,
  handleChange,
  data,
  setData,
  setLoading,
  loading,
  refresh,
  setRefresh,
}) => {
  return (
    <Accordion
      square
      expanded={expanded === "panel3"}
      onChange={handleChange("panel3")}
    >
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <p className="f-14 fw-600 mb-0">
          {name}
          <span>
            <KeyboardArrowDownIcon className="down-arrow-accordion" />
          </span>
        </p>
      </AccordionSummary>
      <AccordionDetails className="accordion-detail">
        <Card bordered={false} className="p-0" loading={loading}>
          <div>
            <AccordionCalendarContent
              data={data}
              setData={setData}
              setLoading={setLoading}
              refresh={refresh}
              setRefresh={setRefresh}
            />
          </div>
        </Card>
      </AccordionDetails>
    </Accordion>
  );
};

///////////////////////////////////////////////////////////////////////////////////////////////
///                  Accordion Teaching Methodology
////////////////////////////////////////////////////////////////////////////////////////////////
const AccordionUploadTeaching = ({
  name,
  expanded,
  handleChange,
  setLoading,
  loading,
}) => {
  return (
    <Accordion
      square
      expanded={expanded === "panel4"}
      onChange={handleChange("panel4")}
    >
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <p className="f-14 fw-600 mb-0">
          {name}
          <span>
            <KeyboardArrowDownIcon className="down-arrow-accordion" />
          </span>
        </p>
      </AccordionSummary>
      <AccordionDetails className="accordion-detail">
        <Card bordered={false} className="p-0" loading={loading}>
          <TeachingMethodologyAccordion />
        </Card>
      </AccordionDetails>
    </Accordion>
  );
};

///////////////////////////////////////////////////////////////////////////////////////////////
///                  Accordion Testimonial
////////////////////////////////////////////////////////////////////////////////////////////////
const AccordionUploadTestimonial = ({
  name,
  expanded,
  handleChange,
  setLoading,
  loading,
}) => {
  return (
    <Accordion
      square
      expanded={expanded === "panel5"}
      onChange={handleChange("panel5")}
    >
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <p className="f-14 fw-600 mb-0">
          {name}
          <span>
            <KeyboardArrowDownIcon className="down-arrow-accordion" />
          </span>
        </p>
      </AccordionSummary>
      <AccordionDetails className="accordion-detail">
        <Card bordered={false} className="p-0" loading={loading}>
          <TestimonialAccordion />
        </Card>
      </AccordionDetails>
    </Accordion>
  );
};

///////////////////////////////////////////////////////////////////////////////////////////////
///                  Material Accordion BoilerPlate
////////////////////////////////////////////////////////////////////////////////////////////////
const Accordion = withStyles({
  root: {
    // border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    fontWeight: 600,

    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
      border: "1px solid rgba(0, 0, 0, .125)",
      marginBottom: 10,
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "#FCFCFC",
    border: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: 16,
    minHeight: 40,

    "&$expanded": {
      marginBottom: 0,
      border: 0,

      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    backgroundColor: "#FCFCFC",
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);
