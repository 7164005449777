import React, { useState } from "react";
import { Form, Input, Button, message } from "antd";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import trans from "./../../../Languages/Languages";
import API from "../../../API";
import ImageIcon from "../../../assets/icons/image.svg";
import Subjects from "./Subjects";

const CourseCatalogue = ({
  index,
  data,
  courseCatalogueData,
  setLoading,
  refresh,
  setRefresh,
  setCourseCatalogueData,
  newCatalogueData,
  setNewCatalogueData,
}) => {
  const [edit, setEdit] = useState(false);
  const [headerImage, setHeaderImage] = useState({ image: "" });
  const [newSubjectsData, setNewSubjectsData] = useState([]);

  ///////////////////////////////////////////////////////////////////////////////////////////////
  ///                             delete new record
  ////////////////////////////////////////////////////////////////////////////////////////////////
  const handleRemoveNewCatalogue = (index) => {
    const dummyData = courseCatalogueData.filter((item, i) => {
      return index !== i;
    });
    setCourseCatalogueData(dummyData);
    let newIndex = newCatalogueData.length + index - courseCatalogueData.length;
    let newRecord = [];
    newRecord = newCatalogueData.filter((item, i) => {
      return newIndex !== i;
    });
    setNewCatalogueData(newRecord);
  };
  ///////////////////////////////////////////////////////////////////////////////////////////////
  ///                             delete  record
  ////////////////////////////////////////////////////////////////////////////////////////////////
  const handleRemoveCatalogue = async (id) => {
    await API.post(`/admin/category/catalogue/delete/${id}`)
      .then((res) => {
        setRefresh(!refresh);
        message.success("Course Catalogue Deleted Successfully");
      })
      .catch((err) => {
        message.error("Something went wrong, please try again");
      });
  };
  ///////////////////////////////////////////////////////////////////////////////////////////////
  ///                             update catalogue
  ////////////////////////////////////////////////////////////////////////////////////////////////
  const handleSave = async (values) => {
    setLoading(true);
    const formData = new FormData();

    if (headerImage.image !== "") formData.append("image", headerImage.image);
    else formData.append("image", data.image);
    formData.append("title", values.catalogueTitle);

    await API.post(`/admin/category/catalogue/update/${data.id}`, formData)
      .then((res) => {
        setRefresh(!refresh);
        setEdit(false);
        setLoading(false);
        // message.success("updated", 3);
      })
      .catch((err) => {
        message.error("All field must be filled");
        setLoading(false);
      });
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////
  ///                             create new subject
  ////////////////////////////////////////////////////////////////////////////////////////////////
  const handleAddAnotherSubject = () => {
    const dummyData = [...courseCatalogueData];
    dummyData[index].courses.push({
      course_title: "",
      credit_hours: 0,
      instructor: "",
      required: "",
      description: "",
      objectives: "",
      topics: "",
      new: true,
    });
    setCourseCatalogueData(dummyData);

    const newRecord = [
      ...newSubjectsData,
      {
        course_title: "",
        credit_hours: 0,
        instructor: "",
        required: "",
        description: "",
        objectives: "",
        topics: "",
        new: true,
      },
    ];
    setNewSubjectsData(newRecord);
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////
  ///                             create new records
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const handleCreateNew = async () => {
    setLoading(true);
    if (newSubjectsData.length > 0) {
      const formData = new FormData();
      newSubjectsData.forEach((element, i) => {
        formData.append(`course[${i}][catalogue_id]`, data.id);
        formData.append(`course[${i}][course_title]`, element.course_title);
        formData.append(`course[${i}][credit_hours]`, element.credit_hours);
        formData.append(`course[${i}][instructor]`, element.instructor);
        formData.append(`course[${i}][required]`, element.required);
        formData.append(`course[${i}][description]`, element.description);
        formData.append(`course[${i}][objectives]`, element.objectives);
        formData.append(`course[${i}][topics]`, element.topics);
      });

      await API.post("/admin/category/course/create", formData)
        .then((res) => {
          setLoading(false);
          setRefresh(!refresh);
          message.success("Course created successfully");
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const handleChange = (e) => {
    if (newCatalogueData.length + index - courseCatalogueData.length >= 0) {
      const dummyNewData = [...newCatalogueData];
      dummyNewData[
        newCatalogueData.length + index - courseCatalogueData.length
      ].title = e.target.value;
      setNewCatalogueData(dummyNewData);
    }
  };

  return (
    <div>
      <div className="fw-600 mb-2">{trans.Coursecatalogue}</div>

      <div className="bg-content-container course-categories position-relative b-container">
        {/* edit action */}
        <Form onFinish={handleSave}>
          <div style={{ height: 10 }}>
            {edit ? (
              <div className="edit-action icon-container d-block mr-1">
                <span
                  className="f-12 fw-400 cancel cursor-pointer"
                  onClick={() => setEdit(false)}
                >
                  {trans.Cancel}
                </span>

                <span className="save">
                  <Button type="" htmlType="submit">
                    {trans.Save}
                  </Button>
                </span>
              </div>
            ) : (
              <div className="icon-container">
                {!data.new && (
                  <span onClick={() => setEdit(true)}>
                    <EditIcon className="b-icon-edit cursor-pointer" />
                  </span>
                )}
                <CloseIcon
                  className="b-icon-close cursor-pointer"
                  onClick={() => {
                    data.new
                      ? handleRemoveNewCatalogue(index)
                      : handleRemoveCatalogue(data.id);
                  }}
                />
              </div>
            )}
          </div>
          {/* header Upload Image */}
          <div>
            <WideImageUpload
              record={courseCatalogueData}
              data={data}
              headerImage={headerImage}
              setHeaderImage={setHeaderImage}
              index={index}
              setData={setCourseCatalogueData}
              newData={newCatalogueData}
              setNewData={setNewCatalogueData}
              originalData={courseCatalogueData}
              edit={edit}
            />
          </div>

          <div className="accordion-detail">
            <div className="f-12 fw-500 input-heading">{trans.Title}</div>
            <Form.Item name="catalogueTitle" initialValue={data.title}>
              <Input
                disabled={edit || data.new ? "" : "disabled"}
                placeholder={trans.Typehere}
                className="w-100"
                onChange={handleChange}
              />
            </Form.Item>
          </div>
        </Form>
        {/* end image */}
        {/* description */}
        {data.courses.length > 0 && (
          <div>
            {data.courses.map((item, i) => {
              return (
                <Subjects
                  catalogueIndex={index}
                  key={i}
                  data={item}
                  index={i}
                  subjectsData={data.courses}
                  setCourseCatalogueData={setCourseCatalogueData}
                  courseCatalogueData={courseCatalogueData}
                  setLoading={setLoading}
                  refresh={refresh}
                  setRefresh={setRefresh}
                  newSubjectsData={newSubjectsData}
                  setNewSubjectsData={setNewSubjectsData}
                />
              );
            })}
          </div>
        )}
        {data.title !== "" && data.image !== "" && (
          <div className="d-flex justify-content-center">
            <span className="f-12 fw-500 ">
              <span className="mr-2 cursor-pointer" onClick={handleAddAnotherSubject}>
                {trans.Addnewsubject}
              </span>
              <span className="f-18 fw-500 align-middle">+</span>
            </span>
          </div>
        )}
        {newSubjectsData.length > 0 && (
          <div className="d-flex justify-content-end">
            {/* save changes */}
            <div onClick={handleCreateNew} className="btn-save-section fw-500">
              <span>{trans.Savesection}</span>
            </div>
          </div>
        )}
      </div>
      {/* add another */}
    </div>
  );
};

export default CourseCatalogue;

///////////////////////////// wide image

const WideImageUpload = ({
  setHeaderImage,
  index,
  record,
  setData,
  data,
  newData,
  setNewData,
  originalData,
  edit,
}) => {
  const [isActionShown, setIsActionShown] = useState(false);

  const handleChange = (e) => {
    setHeaderImage({ image: e.target.files[0] });
    const dummyData = [...record];
    record[index].image = URL.createObjectURL(e.target.files[0]);
    setData(dummyData);

    if (newData.length + index - originalData.length >= 0) {
      const dummyNewData = [...newData];
      dummyNewData[newData.length + index - originalData.length].image =
        e.target.files[0];
      setNewData(dummyNewData);
    }
  };
  const inputHeaderReplace = React.createRef();

  const handleReplace = () => {
    inputHeaderReplace.current.click();
  };

  const handleRemove = () => {
    const dummyData = [...record];
    record[index].image = "";
    setData(dummyData);
  };

  return (
    <div className="catalogue-wide-image">
      <div
        className="h-image-upload-container"
        onMouseEnter={() => setIsActionShown(true)}
        onMouseLeave={() => setIsActionShown(false)}
      >
        <div
          className={
            record.length > 0 && data.image
              ? "h-image-upload medium-img h-uploaded"
              : "h-image-upload medium-img"
          }
          style={{ position: "relative" }}
        >
          {(isActionShown || edit) && data.new && (
            <div>
              {/* image action */}
              <div className="image-action-container">
                <div className="image-action d-flex justify-content-center">
                  <div
                    className="f-9 fw-400 image-action-txt"
                    onClick={handleReplace}
                  >
                    {trans.Replace}
                  </div>
                  <div className="image-action-divider mx-2"></div>
                  <div
                    className="f-9 fw-400 image-action-txt"
                    onClick={handleRemove}
                  >
                    {trans.Remove}
                  </div>
                </div>
              </div>
            </div>
          )}

          <label className="image-box" htmlFor={`courseCatalogueImage${index}`}>
            {record.length > 0 && data.image ? (
              <div
                className="wide-image-preview"
                style={{
                  background: `url(${data.image})`,
                }}
              />
            ) : (
              <div>
                <img src={ImageIcon} alt="" className="px-2" />
                <span>Upload Image</span>
              </div>
            )}

            <input
              ref={inputHeaderReplace}
              type="file"
              name="image1"
              id={`courseCatalogueImage${index}`}
              style={{ display: "none" }}
              onChange={handleChange}
              disabled={edit || data.new ? "" : "disabled"}
            />
          </label>
        </div>
        <span className="float-left f-img-size f-12 fw-400">
          {trans.Maxsize} : 5MB {trans.RecommendedResolution} : 1920 x 1080
        </span>
      </div>
      {/*/////////////////////////////////////////////////////////////////*/}
    </div>
  );
};
