import {Card, Typography} from "antd";
import React, {useEffect} from "react";
import Chart from "../Components/Dashboard/Chart";
import trans from "./../../Languages/Languages";
import {useLanguage} from "./../../Context/LanguageContext";
const cardStyle = {
  backgroundColor: "#FFFFFF",
  boxShadow: "0px 0px 6px #8D8D8D29",
};

function Dashboard() {
  const {language} = useLanguage();
  useEffect(() => {
    trans.setLanguage(language);
  }, [language]);

  return (
    <div>
      <div className='s-22'>
        <Typography.Title className='f-20 fw-600 '>
          {trans.Dashboard}
        </Typography.Title>
      </div>
      <div className='s-22'>
        <Typography.Title className='f-27 fw-500 text-main'>
          {trans.WelcometowebsiteAdminPanel}
        </Typography.Title>
        <Typography.Paragraph className='f-15 fw-300'>
          {trans.Customizeyourwebsitecontentfromthispanelwithease}
        </Typography.Paragraph>
      </div>
      <div className='s-16'>
        <Card style={cardStyle} >
          <Typography.Title className='f-18 fw-500 px-3 pt-2'>
            {trans.Visitors}
          </Typography.Title>
          <div className='s-22'/>
          <Chart />
        </Card>
      </div>
    </div>
  );
}

export default Dashboard;
