import {Form, Input, message, Select} from "antd";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import {ArrowLeftOutlined} from "@ant-design/icons";
import {Link, useHistory, useLocation} from "react-router-dom";
import ChangePassword from "../Components/Users/ChangePassword";
import {useEffect, useState} from "react";
import trans from "./../../Languages/Languages";
import API from "../../API";
import React from "react";

const {Option} = Select;
const {Item} = Form;
const Edit = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const location = useLocation()
    const id = location.pathname.split('/').slice(-1)[0];
    const [user, setUser] = useState(null);
    const history = useHistory();

    useEffect(() => {
        API.get(`/admin/user/${id}`).then(res => {
            setUser(res.data)
        }).catch(() => {
            message.error('Can\'t get user information')
        })
    }, [id])

    const [errors, setErrors] = useState({name: "", email: "", user_name: "", phone_number: "", college: ""})

    const handleSubmit = (values) => {
        API.put(`/admin/user/${id}`, values).then(r => {
            message.success(r.data.success);
            history.push('/users');
        }).catch(e => {
            if (e.response.status === 400) {
                setErrors(e.response.data)
            } else if (e.response.status === 500) {
                message.error('Internal server error');
            }
        })
    }
    return (
        <>
            <ChangePassword
                id={id}
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
            />
            {
                user &&
                <Form initialValues={user} onFinish={handleSubmit}>

                    <div className='user-info-container'>
                        <div className='align-item-center d-flex'>
                            <Link to='/users' className='text-decoration-none'>
                                <ArrowLeftOutlined className='text-dark f-23'/>
                            </Link>
                            <span className='f-20 fw-600 mx-2'>{trans.Users}</span>
                        </div>
                        <div className='user-info-content'>
                            <div className='f-17 fw-600'>
                                {trans.UserInformation}
                            </div>
                            {/* row */}
                            <div className='d-flex justify-content-start'>
                                <div className='my-profile-input user-info-input'>
                                    <div className='f-12 fw-500 mp-input-heading mt-18'>
                                        {trans.Name}
                                    </div>
                                    <Item name={"name"} validateStatus={errors.name ? "error" : ""} help={errors.name}>
                                        <Input type='text'/>
                                    </Item>
                                </div>
                                <div className='my-profile-input user-info-input'>
                                    <div className='f-12 fw-500 mp-input-heading mt-18'>
                                        {trans.Email}
                                    </div>
                                    <Item name={"email"} validateStatus={errors.email ? "error" : ""}
                                          help={errors.email}>
                                        <Input type='text'/>
                                    </Item>
                                </div>
                                <div className='my-profile-input user-info-input'>
                                    <div className='f-12 fw-500 mp-input-heading mt-18'>
                                        {trans.PhoneNumber}
                                    </div>
                                    <Item name={"phone_number"} validateStatus={errors.phone_number ? "error" : ""}
                                          help={errors.phone_number}>
                                        <Input type='text'/>
                                    </Item>
                                </div>
                            </div>
                            {/* row */}
                            <div className='d-flex justify-content-start'>
                                <div>
                                    <div className='f-12 fw-500 mp-input-heading mt-18'>
                                        {trans.College}
                                    </div>
                                    <Item name={"college"} validateStatus={errors.college ? "error" : ""}
                                          help={errors.college}>
                                        <Select
                                            suffixIcon={
                                                <KeyboardArrowDownIcon
                                                    className='select-option-icon'
                                                    fontSize='small'
                                                />
                                            }
                                            className='user-info-collage-select'
                                            allowClear={false}
                                            disabled
                                        >
                                            {[
                                                "Arabic",
                                                "English",
                                                "Urdu",
                                                "French",
                                            ].map((y, index) => {
                                                return (
                                                    <Option value={y} key={index}>
                                                        {y}
                                                    </Option>
                                                );
                                            })}
                                        </Select>
                                    </Item>
                                </div>
                                <div>
                                    <div className='f-12 fw-500 mp-input-heading mt-18'>
                                        {trans.Language}
                                    </div>
                                    <Item name={"language"} validateStatus={errors.language ? "error" : ""}
                                          help={errors.language}>
                                        <Select
                                            style={{width: "100%"}}
                                            className='select-option-rtl'
                                        >
                                            <Select.Option value='arabic'>{trans.Arabic}</Select.Option>
                                            <Select.Option value='english'>{trans.English}</Select.Option>
                                            <Select.Option value='urdu'>{trans.urdu}</Select.Option>
                                            <Select.Option value='french'>{trans.French}</Select.Option>
                                        </Select>
                                    </Item>
                                </div>
                            </div>
                            {/* row */}
                            <div className='d-flex justify-content-start'>
                                <div className='my-profile-input user-info-input'>
                                    <div className='f-12 fw-500 mp-input-heading mt-18'>
                                        {trans.UserName}
                                    </div>
                                    <Item name={"user_name"} validateStatus={errors.user_name ? "error" : ""}
                                          help={errors.user_name}>
                                        <Input type='text'/>
                                    </Item>
                                </div>

                                <div className='user-info-btn-forget-pass'>
                                    <button
                                        className='f-14 fw-500 btn'
                                        onClick={() => setIsModalVisible(true)}
                                    >
                                        {trans.ChangePassword}
                                    </button>
                                </div>
                            </div>
                            {/* save changes */}

                            <div className={"d-flex justify-content-end"}>
                                <button type="submit" className="ant-btn ant-btn-primary">
                                    <span>{trans.Savechanges}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </Form>
            }
        </>
    );
};

export default Edit;
