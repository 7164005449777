import Editor from "../../Components/common/Editor";
import ImageIcon from "../../../assets/icons/image.svg";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import {
  DatePicker,
  Card,
  TimePicker,
  Form,
  Input,
  Button,
  message,
} from "antd";
import moment from "moment";
import trans from "./../../../Languages/Languages";
import React, { useState, useEffect } from "react";
import API from "../../../API";

const Events = () => {
  const [eventsData, setEventsData] = useState([]);
  const [newData, setNewData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const handleAnotherNews = ({ data }) => {
    setLoading(true);
    const dummyData = [
      {
        title: "",
        description: "",
        date_at: moment().format("YYYY-MM-DD"),
        time_at: moment().format("hh:mm"),
        image: "",
        bottom_text: "",
        new: true,
      },
      ...eventsData,
    ];
    setEventsData(dummyData);

    const newDummyData = [
      {
        title: "",
        description: "",
        date_at: moment().format("YYYY-MM-DD"),
        time_at: moment().format("hh:mm"),
        image: "",
        bottom_text: "",
        new: true,
      },
      ...newData,
    ];
    setNewData(newDummyData);

    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const [loading, setLoading] = useState(true);

  ///////////////////////////////////////////////////////////////////////////////////////////////
  ///                  Use Effect
  ////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    setLoading(true);

    const init = async () => {
      await API.get("/admin/events")
        .then((res) => {
          setEventsData(res.data);

          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    };

    init();
  }, [refresh]);

  // create new records
  const handleCreateNew = async () => {
    setLoading(true);
    if (newData.length > 0) {
      const formData = new FormData();
      newData.forEach((element, i) => {
        formData.append(`event[${i}][title]`, element.title);
        formData.append(`event[${i}][description]`, element.description);
        formData.append(`event[${i}][date_at]`, element.date_at);
        formData.append(`event[${i}][time_at]`, element.time_at);
        formData.append(`event[${i}][image]`, element.image);
        formData.append(`event[${i}][bottom_text]`, "text");
      });

      await API.post("/admin/event/create", formData)
        .then((res) => {
          setNewData([]);
          setLoading(false);
          setRefresh(!refresh);
          message.success("Events Created Successfully");
        })
        .catch((e) => {
          setLoading(false);
          if (e.response.status === 400) {
            message.error("Field is required");

            // error for only image size must not be greater than 5 MB.
            if (e.response.data["event.0.image"]) {
              if (e.response.data["event.0.image"][0].includes("5120")) {
                message.error("The image must not be greater than 5 MB.");
              }
            }
          }
        });
    }
  };

  return (
    <div className="pages-container events">
      <Card bordered={false} className="p-0" loading={loading}>
        {newData.length > 0 && (
          <Button
            onClick={handleCreateNew}
            className="btn-save-changes"
            type="primary"
            htmlType="submit"
          >
            {trans.Save}
          </Button>
        )}

        {/* add another */}
        {eventsData.length > 0 &&
          eventsData.map((item, i) => {
            return (
              <div className="content-wrapper news-content">
                <AddEvents
                  key={i}
                  index={i}
                  data={item}
                  originalData={eventsData}
                  setData={setEventsData}
                  refresh={refresh}
                  setRefresh={setRefresh}
                  setLoading={setLoading}
                  newData={newData}
                  setNewData={setNewData}
                />
              </div>
            );
          })}

        {/* add another button */}
        <div className="w-100 d-flex justify-content-center">
          <div className="news-add-another" onClick={handleAnotherNews}>
            <span className="f-12 fw-500">
              {trans.Addanother}
              <span className="ml-1 f-20 fw-500 align-middle">+</span>
            </span>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default Events;

const AddEvents = ({
  data,
  index,
  originalData,
  setData,
  newData,
  setNewData,
  setLoading,
  refresh,
  setRefresh,
}) => {
  const [editorHtml, setEditorHtml] = useState("");

  useEffect(() => {
    setEditorHtml(data?.bottom_text);
  }, [data.bottom_text]);

  const handleTextEditorChange = (html) => {
    setEditorHtml(html);
    if (index <= newData.length - 1) {
      const dummyNewData = [...newData];
      dummyNewData[index].bottom_text = html;
      setNewData(dummyNewData);
    }
  };

  const [edit, setEdit] = useState(false);
  const [currentImageObject, setCurrentImageObject] = useState({ image: "" });
  const handleChange = (e) => {
    const dummyImage = { ...currentImageObject };
    dummyImage.image = e.target.files[0];
    setCurrentImageObject(dummyImage);

    const dummyData = [...originalData];
    dummyData[index].image = URL.createObjectURL(e.target.files[0]);
    setData(dummyData);

    if (index <= newData.length - 1) {
      const dummyNewData = [...newData];
      dummyNewData[index].image = e.target.files[0];
      setNewData(dummyNewData);
    }
  };

  const inputReplace = React.createRef();
  const [isActionShown, setIsActionShown] = useState(false);

  // remove image
  const handleRemove = () => {
    const dummyData = [...originalData];
    originalData[index].image = "";
    setData(dummyData);
  };

  // replace image
  const handleReplace = () => {
    inputReplace.current.click();
  };

  // update record
  const handleSectionSave = async (values) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("title", values.title);
    formData.append("description", values.description);
    formData.append("date_at", moment(values.date_at).format("YYYY-MM-DD"));
    formData.append("time_at", moment(values.time_at).format("hh:mm"));
    formData.append("bottom_text", editorHtml);
    currentImageObject.image === ""
      ? formData.append("image", data.image)
      : formData.append("image", currentImageObject.image);

    await API.post(`/admin/event/update/${data.id}`, formData)
      .then((res) => {
        setRefresh(!refresh);
        setLoading(false);
        message.success("Event Updated Successfully");
      })
      .catch((e) => {
        setLoading(false);
        message.error("Field is required.");

        // error for only image size must not be greater than 5 MB.
        if (e.response.data["image"]) {
          if (e.response.data["image"][0].includes("5120")) {
            message.error("The image must not be greater than 5 MB.");
          }
        }
      });
  };

  const handleInputValueChange = ({ target }) => {
    if (index >= 0 && newData.length > 0 && data.new) {
      const dummyNewData = [...newData];
      dummyNewData[index][target.name] = target.value;
      setNewData(dummyNewData);
    }
  };

  const handleDateChange = (date, dateString) => {
    if (index >= 0 && newData.length > 0) {
      const dummyNewData = [...newData];
      dummyNewData[index].date_at = dateString;
      setNewData(dummyNewData);
    }
  };
  const handleTimeChange = (time, timeString) => {
    if (index >= 0 && newData.length > 0) {
      const dummyNewData = [...newData];
      dummyNewData[index].time_at = timeString;
      setNewData(dummyNewData);
    }
  };

  const handleNewEventsDelete = (index) => {
    const dummyData = originalData.filter((item, i) => {
      return index !== i;
    });
    setData(dummyData);
    let newIndex = newData.length + index - originalData.length;
    let newRecord = [];
    newRecord = newData.filter((item, i) => {
      return newIndex !== i;
    });
    setNewData(newRecord);
  };
  ///////////////////////////////////////////////////////////////////////////////////////////////
  ///                             delete record
  ////////////////////////////////////////////////////////////////////////////////////////////////
  const handleEventsDelete = async () => {
    setLoading(true);

    await API.post(`/admin/event/delete/${data.id}`)
      .then((res) => {
        setNewData([]);
        setRefresh(!refresh);
        setLoading(false);
        message.success("Deleted Successfully");
      })
      .catch((err) => {
        setLoading(false);
        message.error("Something went wrong, please try again.");
      });
  };

  return (
    <div className="bg-content-container faculty-container ">
      <Form onFinish={handleSectionSave}>
        {/* edit action */}
        <div style={{ height: 10 }}>
          {edit ? (
            <div className="edit-action icon-container d-block mr-1">
              <span
                className="f-12 fw-400 cancel cursor-pointer"
                onClick={() => setEdit(false)}
              >
                {trans.Cancel}
              </span>

              <span className="save">
                <Button type="" htmlType="submit">
                  {trans.Save}
                </Button>
              </span>
            </div>
          ) : (
            <div className="icon-container">
              {!data.new && (
                <span onClick={() => setEdit(true)}>
                  <EditIcon className="b-icon-edit cursor-pointer" />
                </span>
              )}
              <CloseIcon
                className="b-icon-close cursor-pointer"
                onClick={() => {
                  data.new
                    ? handleNewEventsDelete(index)
                    : handleEventsDelete(index);
                }}
              />
            </div>
          )}
        </div>
        {/* events header image upload */}
        <div
          className={
            data.image !== "" && data.image
              ? "news-image-upload-container news-image uploaded"
              : "news-image-upload-container news-image"
          }
        >
          <div
            className="news-image-upload medium-img"
            onMouseEnter={() => setIsActionShown(true)}
            onMouseLeave={() => setIsActionShown(false)}
          >
            {isActionShown && (edit || data.new) && (
              <div>
                {/* image action */}
                <div className="image-action-container">
                  <div className="image-action d-flex justify-content-center">
                    <div
                      className="f-9 fw-400 image-action-txt"
                      onClick={handleReplace}
                    >
                      {trans.Replace}
                    </div>
                    <div className="image-action-divider mx-2"></div>
                    <div
                      className="f-9 fw-400 image-action-txt"
                      onClick={handleRemove}
                    >
                      {trans.Remove}
                    </div>
                  </div>
                </div>
              </div>
            )}
            <label className="news-image-box" htmlFor={`events${index}`}>
              {data.image !== "" && data.image ? (
                <div
                  className="wide-image-preview"
                  style={{
                    background: `url(${data.image})`,
                    height: 152,
                  }}
                />
              ) : (
                <div className="cursor-pointer">
                  <img src={ImageIcon} alt="" className="pl-1 pr-1" />
                  <span className="f-12 fw-400 pr-1">Upload Image</span>
                </div>
              )}

              <input
                disabled={edit || data.new ? "" : "disabled"}
                ref={inputReplace}
                type="file"
                name="image1"
                id={`events${index}`}
                style={{ display: "none" }}
                onChange={handleChange}
              />
            </label>
          </div>
          <span className="float-left f-img-size f-12 fw-400">
            {trans.Maxsize} : 5MB {trans.RecommendedResolution} : 1920 x 1080
          </span>
        </div>
        {/* end */}
        <div>
          <div className="accordion-detail ">
            <div className="f-12 fw-500 faculty-heading">{trans.Name}</div>
            <Form.Item name="title" initialValue={data.title}>
              <Input
                onChange={handleInputValueChange}
                name="title"
                type="text"
                placeholder={trans.Typehere}
                disabled={edit || data.new ? "" : "disabled"}
              />
            </Form.Item>
          </div>
          <div className="accordion-detail  college-text-area">
            <div className="f-12 fw-500 faculty-heading">
              {trans.Description}
            </div>
            <Form.Item name="description" initialValue={data.description}>
              <Input.TextArea
                onChange={handleInputValueChange}
                name="description"
                disabled={edit || data.new ? "" : "disabled"}
              ></Input.TextArea>
            </Form.Item>
          </div>
          <div className="d-flex justify-content-start events-date-time">
            <div className="accordion-detail p-date ">
              <div className="f-12 fw-500 faculty-heading">{trans.Date}</div>
              <Form.Item
                name="date_at"
                initialValue={
                  data.date_at !== ""
                    ? moment(data.date_at, "YYYY-MM-DD")
                    : moment()
                }
              >
                <DatePicker
                  disabled={edit || data.new ? "" : "disabled"}
                  onChange={handleDateChange}
                  name="date_at"
                />
              </Form.Item>
            </div>
            <div className="accordion-detail ">
              <div className="f-12 fw-500 faculty-heading ">{trans.time}</div>
              <Form.Item
                name="time_at"
                initialValue={
                  data.time_at !== "" ? moment(data.time_at, "hh:mm") : moment()
                }
              >
                <TimePicker
                  disabled={edit || data.new ? "" : "disabled"}
                  format="hh:mm"
                  onChange={handleTimeChange}
                  name="time_at"
                  defaultOpenValue={moment("00:00:00", "HH:mm:ss")}
                />
              </Form.Item>
            </div>
          </div>
        </div>

        {/* Faculties text Area */}

        <div className="f-12 fw-500  mb-2">{trans.text}</div>
        <div className={edit || data.new ? "" : "quill-readonly"}>
          <Editor
            key={`events${index}`}
            editorHtml={editorHtml}
            setEditorHtml={setEditorHtml}
            handleChange={handleTextEditorChange}
            readonly={!edit && !data.new}
          />
        </div>
      </Form>
    </div>
  );
};
