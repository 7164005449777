import Editor from "../../Components/common/Editor";
import trans from "./../../../Languages/Languages";
import {Form, Input, Select, Card, InputNumber, Button, message} from "antd";
import {useState, useEffect} from "react";
import API from "../../../API";
const FeeStructure = () => {
  const [feeEditorHtml, setFeeEditorHtml] = useState("");
  const handleFeeEditorChange = (html) => {
    setFeeEditorHtml(html);
  };
  const [scholarshipEditorHtml, setScholarshipEditorHtml] = useState("");
  const handleScholarshipEditorChange = (html) => {
    setScholarshipEditorHtml(html);
  };
  const [paymentEditorHtml, setPaymentEditorHtml] = useState("");
  const handlePaymentEditorChange = (html) => {
    setPaymentEditorHtml(html);
  };
  const [textEditorHtml, setTextEditorHtml] = useState("");
  const handleTextEditorChange = (html) => {
    setTextEditorHtml(html);
  };

  const {Option} = Select;
  const [feeStructureData, setFeeStructureData] = useState([]);

  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(true);

  ///////////////////////////////////////////////////////////////////////////////////////////////
  ///                             Use Effect
  ////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    setLoading(true);

    const init = async () => {
      await API.get("/admin/fee")
        .then((res) => {
          setFeeStructureData(res.data);
          setFeeEditorHtml(res?.data?.about);
          setTextEditorHtml(res?.data?.bottom_text);
          setScholarshipEditorHtml(res?.data?.scholarship_entitlement);
          setPaymentEditorHtml(res?.data?.payment_instructions);
          setTextEditorHtml(res?.data?.bottom_text);
          setFeeStructureData(res?.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    };

    init();
  }, [refresh]);
  ///////////////////////////////////////////////////////////////////////////////////////////////
  ///                             update changes
  ////////////////////////////////////////////////////////////////////////////////////////////////
  const onFinish = async (values) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("account_number", values.accountInfo);
    formData.append("application_fee", values.applicationFee);
    formData.append("bank", values.bank);
    formData.append("beneficiary_name", values.beneficiaryName);
    formData.append("branch", values.branch);
    formData.append("full_time", values.fullTimeStudent);
    formData.append("one_time", values.oneCourseModule);
    formData.append("swift_code", values.swiftCode);
    formData.append("currency", values.currency);
    formData.append("scholarship_entitlement", scholarshipEditorHtml);
    formData.append("payment_instructions", paymentEditorHtml);
    formData.append("bottom_text", textEditorHtml);
    formData.append("about", feeEditorHtml);

    await API.post(`/admin/fee/update`, formData)
      .then((res) => {
        setRefresh(!refresh);
        setLoading(false);
        message.success("Updated Successfully", 3);
      })
      .catch((err) => {
        setLoading(false);
        message.error("something went wrong, please try again", 3);
      });
  };

  return (
    <div className='pages-container'>
      <div className='content-wrapper'>
        <Card loading={loading} bordered={false}>
          <div className='bg-content-container fee-structure'>
            <div className='fw-600 mb-2'>{trans.KIUFees}</div>
            <Editor
              key='feeStructures'
              editorHtml={feeEditorHtml}
              setEditorHtml={setFeeEditorHtml}
              handleChange={handleFeeEditorChange}
            />
          </div>
          <Form onFinish={onFinish}>
            {/* Fee structure container */}
            <div className='fw-600 mb-2'>{trans.FeeStructure}</div>
            <div className='bg-content-container'>
              {/* currency */}
              <div className='mb-14'>
                <div className='fw-400 f-12 mb-2'>{trans.Currency}</div>
                <Form.Item
                    name='currency'
                    initialValue={feeStructureData?.currency}
                >
                  <Select defaultValue='usd' style={{width: 70}} className='f-12'>
                    <Option value='USD'>USD</Option>
                    <Option value='SR'>SR</Option>
                    <Option value='RS'>RS</Option>
                    <Option value='EUR'>EUR</Option>
                  </Select>
                </Form.Item>
              </div>

              {/* Application feee */}
              <div className='d-flex justify-content-start mr-102 align-items-center'>
                <div className='fw-600 f-12 mr-102 mb-4' style={{width: 145}}>
                  {trans.ApplicationFees}
                </div>
                <div className='accordion-detail '>
                  <span
                    className='fw-400 f-12 mr-102'
                    style={{width: 145, marginLeft: 85}}
                  >
                    Amount
                  </span>
                  <Form.Item
                    name='applicationFee'
                    initialValue={feeStructureData?.application_fee}
                  >
                    <InputNumber style={{width: 136}} min='0' step='0.01' />
                  </Form.Item>
                </div>
              </div>
              {/* Full tine students */}
              <div className='d-flex justify-content-start mr-102 align-items-center'>
                <div className='fw-600 f-12 mr-102 mb-4' style={{width: 145}}>
                  {trans.Fulltimestudents}
                  <div className='fw-400 f-9 gray' style={{width: 145}}>
                    15-21 credit hours per semester
                  </div>
                </div>

                <div className='accordion-detail '>
                  <Form.Item
                    name='fullTimeStudent'
                    initialValue={feeStructureData?.full_time}
                  >
                    <InputNumber
                      style={{width: 136}}
                      min='0'
                      step='0.01'
                      // onChange={onChange}
                    />
                  </Form.Item>
                </div>
              </div>
              {/* Full tine students */}
              <div className='d-flex justify-content-start mr-102 align-items-center'>
                <div className='fw-600 f-12 mr-102 mb-4' style={{width: 145}}>
                  {trans.Onecoursemodule}
                  <div className='fw-400 f-9 gray' style={{width: 145}}>
                    Runs during term time
                  </div>
                </div>
                <div className='accordion-detail'>
                  <Form.Item
                    name='oneCourseModule'
                    initialValue={feeStructureData?.one_time}
                  >
                    <InputNumber
                      style={{width: 136}}
                      min='0'
                      step='0.01'
                      // onChange={onChange}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className='fw-600 mb-2'>{trans.ScholarshipEntitlement}</div>
            <div className='bg-content-container'>
              <div className='fw-400 f-14 mb-2'>{trans.text}</div>
              <Editor
                key='scjhoarlshipsFee'
                editorHtml={scholarshipEditorHtml}
                setEditorHtml={setScholarshipEditorHtml}
                handleChange={handleScholarshipEditorChange}
              />
            </div>

            <div className='fw-600 mb-2'>{trans.Paymentinstructions}</div>
            <div className='bg-content-container'>
              <div className='fw-400 f-14 mb-2'>{trans.text}</div>
              <Editor
                key='paymentFees'
                editorHtml={paymentEditorHtml}
                setEditorHtml={setPaymentEditorHtml}
                handleChange={handlePaymentEditorChange}
              />
            </div>

            <div className='fw-600 mb-2'>{trans.Paymentdetails}</div>
            {/* payment details */}
            <div className='bg-content-container'>
              {/* bank */}
              <div className='accordion-detail '>
                <div className='f-12 fw-500 input-heading'>{trans.Bank}</div>
                <Form.Item name='bank' initialValue={feeStructureData?.bank}>
                  <Input placeholder={trans.Typehere} className='w-100' />
                </Form.Item>
              </div>
              {/* Branch */}

              <div className='accordion-detail '>
                <div className='f-12 fw-500 input-heading'>{trans.Branch}</div>
                <Form.Item
                  name='branch'
                  initialValue={feeStructureData?.branch}
                >
                  <Input placeholder={trans.Typehere} className='w-100' />
                </Form.Item>
              </div>

              {/* Beneficiary's name */}

              <div className='accordion-detail '>
                <div className='f-12 fw-500 input-heading'>
                  {trans.Beneficiarysname}
                </div>
                <Form.Item
                  name='beneficiaryName'
                  initialValue={feeStructureData?.beneficiary_name}
                >
                  <Input placeholder={trans.Typehere} className='w-100' />
                </Form.Item>
              </div>

              {/* Account No */}

              <div className='accordion-detail '>
                <div className='f-12 fw-500 input-heading'>
                  {trans.AccountNo}
                </div>
                <Form.Item
                  name='accountInfo'
                  initialValue={feeStructureData?.account_number}
                >
                  <Input placeholder={trans.Typehere} className='w-100' />
                </Form.Item>
              </div>

              {/* Swift code */}

              <div className='accordion-detail '>
                <div className='f-12 fw-500 input-heading'>
                  {trans.Swiftcode}
                </div>
                <Form.Item
                  name='swiftCode'
                  initialValue={feeStructureData?.swift_code}
                >
                  <Input placeholder={trans.Typehere} className='w-100' />
                </Form.Item>
              </div>
            </div>
            <div className='bg-content-container'>
              <div className='fw-400 f-14 mb-2'>{trans.text}</div>
              <Editor
                key='bottomTextEditor'
                editorHtml={textEditorHtml}
                setEditorHtml={setTextEditorHtml}
                handleChange={handleTextEditorChange}
              />
            </div>

            {/* save changes */}
            <Button
              className='btn-save-changes'
              type='primary'
              htmlType='submit'
            >
              {trans.Save}
            </Button>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default FeeStructure;
