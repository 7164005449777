import Editor from "../../Components/common/Editor";
import trans from "./../../../Languages/Languages";
import {useState, useEffect} from "react";
import {Button, Form, Input, message, Card} from "antd";
import API from "../../../API";

const EnrollmentProcess = () => {
  const [editorHtml, setEditorHtml] = useState("");

  const handleTextEditorChange = (html) => {
    setEditorHtml(html);
  };
  const [loading, setLoading] = useState(true);
  //
  const [enrollmentData, setEnrollmentData] = useState({});
  const [errors, setErrors] = useState({});
  const [toRefreshData, setToRefreshData] = useState(false);
  ///////////////////////////////////////////////////////////////////////////////////////////////
  ///                             Use Effect
  ////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    setLoading(true);

    const init = async () => {
      await API.get("/admin/enrollment")
        .then((res) => {
          setEditorHtml(res.data.requirements);
          setEnrollmentData(res.data);
          setErrors({});
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    };

    init();
  }, [toRefreshData]);

  const onFinish = (values) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("requirements", editorHtml);
    formData.append("email", values.email);
    formData.append("fax", values.fax);
    formData.append("post_address", values.address);
    formData.append("po_box", values.poBox);
    API.post("/admin/enrollment/update", formData)
      .then((res) => {
        setLoading(false);
        setToRefreshData(!toRefreshData);
        message.success("Updated Successfully", 3);
      })
      .catch((err) => {
        setLoading(false);
        setErrors(err.response.data);
      });
  };
  return (
    <div className='pages-container'>
      <Card loading={loading} bordered={false}>
        <div className='content-wrapper'>
          <div className='bg-content-container'>
            <div className='fw-600 h-heading '>{trans.Requirements}</div>
            <Editor
              key='enrollment'
              editorHtml={editorHtml}
              setEditorHtml={setEditorHtml}
              handleChange={handleTextEditorChange}
            />
          </div>
          <Form onFinish={onFinish}>
            <div className='bg-content-container'>
              <div className='fw-600 '>{trans.Informationbox}</div>
              <div className='f-12 fw-500'>
                {
                  trans.EnterthecontactdetailsbelowforstudentstosubmitApplication
                }
              </div>
              {/* Email */}
              <div className='accordion-detail ep-email-input'>
                <div className='f-12 fw-500 input-heading '>{trans.Email}</div>
                <Form.Item name='email' initialValue={enrollmentData.email} style={{
                  marginBottom: "4px",
                }}>
                  <Input type='text' />
                </Form.Item>
                {errors?.email && (
                    <span className="text-danger">{errors?.email}</span>
                )}
              </div>

              {/* Fax */}
              <div className='accordion-detail ep-email-fax'>
                <div className='f-12 fw-500 input-heading '>{trans.Fax}</div>
                <Form.Item name='fax' initialValue={enrollmentData.fax}>
                  <Input type='text' />
                </Form.Item>
                {errors?.fax && (
                    <span className="text-danger">{errors?.fax}</span>
                )}
              </div>

              {/* post Address */}
              <div className='accordion-detail ep-post-address-input'>
                <div className='f-12 fw-500 input-heading '>
                  {trans.Postaddress}
                </div>
                <Form.Item
                  name='address'
                  initialValue={enrollmentData.post_address}
                >
                  <Input type='text' />
                </Form.Item>
                {errors?.post_address && (
                    <span className="text-danger">{errors?.post_address}</span>
                )}
              </div>

              {/* PO BOX */}
              <div className='accordion-detail ep-po-input'>
                <div className='f-12 fw-500 input-heading'>{trans.POBox}</div>
                <Form.Item name='poBox' initialValue={enrollmentData.po_box}>
                  <Input type='text' />
                </Form.Item>
                {errors?.po_box && (
                    <span className="text-danger">{errors?.po_box}</span>
                )}
              </div>
            </div>
            {/* save changes */}

            <Button
              className='btn-save-changes'
              type='primary'
              htmlType='submit'
            >
              {trans.Save}
            </Button>
          </Form>
        </div>
      </Card>
    </div>
  );
};

export default EnrollmentProcess;
