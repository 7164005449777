import Editor from "../../Components/common/Editor";
import trans from "./../../../Languages/Languages";
import {useState, useEffect} from "react";
import {Button, Card, message, InputNumber, Form} from "antd";
import API from "../../../API";

const Assessment = () => {
  const [editorHtml, setEditorHtml] = useState("");

  const handleTextEditorChange = (html) => {
    setEditorHtml(html);
  };
  // eslint-disable-next-line
  const [assessmentData, setAssessmentData] = useState([
    { name: trans.SessionTest, key: "session_test" },
    { name: trans.Passmarks, key: "passing_marks" },
    { name: trans.Midtermexam, key: "mid_term" },
    { name: trans.Assignments, key: "assignments" },
    { name: trans.FinalExam, key: "final_exam" },
    { name: trans.Attendance, key: "attendence" },
  ]);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [toRefreshData, setToRefreshData] = useState(false);

  ///////////////////////////////////////////////////////////////////////////////////////////////
  ///                             Use Effect
  ////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    setLoading(true);

    const init = async () => {
      await API.get("/admin/assessments")
        .then((res) => {
          if (res.status === 200) {
            setData(res.data);
            setEditorHtml(res.data.about);
          }
        })
        .catch((err) => {
        }).finally(() => {
          setLoading(false);
        });
    };

    init();
  }, [toRefreshData]);

  const handleSave = (values) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("about", editorHtml);
    formData.append("assignments", values.assignments || data.assignments);
    formData.append("attendence", values.attendance || data.attendence);
    formData.append("final_exam", values.final_exam || data.final_exam);
    formData.append("mid_term", values.mid_term || data.mid_term);
    formData.append(
      "passing_marks",
      values.passing_marks || data.passing_marks
    );
    formData.append("session_test", values.session_test || data.session_test);

    API.post("/admin/assessments/update", formData)
      .then((res) => {
        setLoading(false);
        setToRefreshData(!toRefreshData);
        message.success("Updated Successfully");
      })
      .catch((err) => {
        setLoading(false);

        message.error("something went wrong");
      });
  };

  return (
    <div className="pages-container">
      <Card loading={loading} bordered={false} className="position-static">
        {!loading &&
        <div className="content-wrapper">
          <div className="bg-content-container">
            <div className="fw-600 mb-2">{trans.Assessment}</div>
            <Editor
              key="assesments1"
              editorHtml={editorHtml}
              setEditorHtml={setEditorHtml}
              handleChange={handleTextEditorChange}
            />
          </div>
          {/* assessment details */}
          <div className="fw-600 mb-2">{trans.Assessment}</div>
          <Form onFinish={handleSave}>
            <>
              <div className="bg-content-container d-flex justify-content-center">
                <div className="assessment-details row justify-content-start mt-4 align-items-center">
                  {/*  assessment percentage */}
                  {assessmentData.map((assessment, index) => {
                    return (
                      <div
                        className="col-6 assessment-details d-flex justify-content-center align-items-center"
                        key={index}
                      >
                        <Form.Item>
                          <div className="f-12 fw-500 assessment-content-heading">
                            {assessment.name}
                          </div>
                        </Form.Item>
                        <span>
                          <Form.Item name={assessment.key} initialValue={data[assessment.key]}>
                            <InputNumber
                              className="assessment-input d-inline-block mb-0"
                              max={100}
                              formatter={(value) => `${value}%`}
                              parser={(value) => value.replace("%", "")}
                              // onChange={onChange}
                            />
                          </Form.Item>
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
              {/* save changes */}

              <Button
                className="btn-save-changes"
                type="primary"
                htmlType="submit"
              >
                {trans.Save}
              </Button>
            </>
          </Form>
        </div>
        }
      </Card>
    </div>
  );
};

export default Assessment;
