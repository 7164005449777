import Editor from "../../Components/common/Editor";
import trans from "./../../../Languages/Languages";
import {Button, Form, Input, Card, message} from "antd";
import {useEffect, useState} from "react";
import API from "../../../API";

const Courses = () => {
  // eslint-disable-next-line
  const [courseIncludes, setCourseIncludes] = useState([
    { name: trans.VideoLectures },
    { name: trans.Livesessions },
    { name: trans.Coursematerial },
    { name: trans.Assignments },
    { name: trans.Discussionforum },
    { name: trans.FinalExam },
  ]);
  const [studyEditorHtml, setStudyEditorHtml] = useState("");
  const [bottomEditorHtml, setBottomEditorHtml] = useState("");
  const [courseData, setCourseData] = useState();

  const handleStudyEditorChange = (html) => {
    setStudyEditorHtml(html);
  };
  const handleBottomEditorChange = (html) => {
    setBottomEditorHtml(html);
  };

  let inputValueNames = [
    {
      key: "videoLectures",
      text: "video_lecture_text",
      value: "video_lectures",
    },
    {
      key: "liveSession",
      text: "live_sessions_text",
      value: "live_sessions",
    },
    {
      key: "courseMaterial",
      text: "course_material_text",
      value: "course_material",
    },
    { key: "assignments", text: "assignments_text", value: "assignments" },
    {
      key: "discussionForm",
      text: "discussion_forum_text",
      value: "discussion_forum",
    },
    { key: "finalExams", text: "final_exam_text", value: "final_exam" },
  ];

  const [loading, setLoading] = useState(true);
  //
  const [refresh, setRefresh] = useState(false);
  const [textEditorHtml, setTextEditorHtml] = useState([
    { value: "" },
    { value: "" },
    { value: "" },
    { value: "" },
    { value: "" },
    { value: "" },
  ]);

  ///////////////////////////////////////////////////////////////////////////////////////////////
  ///                  Use Effect
  ////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    setLoading(true);

    const init = async () => {
      await API.get("/admin/course")
        .then((res) => {
          // Extract properties with '_text' suffix and create an array of objects
          const newTextEditorHtml = Object.keys(res.data)
              .filter(key => key.endsWith('_text'))
              .map(key => ({ value: res.data[key] }));

          setTextEditorHtml(newTextEditorHtml);
          setCourseData(res.data);
          setStudyEditorHtml(res.data.about);
          setBottomEditorHtml(res.data.bottom_text);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    };

    init();
  }, [refresh]);
  //////////////////////////////////////////////////////////////////////////////////////////////
  ///                  handle save
  ////////////////////////////////////////////////////////////////////////////////////////////////
  const handleFinish = (values) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("about", studyEditorHtml);
    formData.append("video_lectures", values.videoLecturesValue);
    formData.append("video_lecture_text", textEditorHtml[0].value);
    formData.append("live_sessions", values.liveSessionValue);
    formData.append("live_sessions_text", textEditorHtml[1].value);
    formData.append("course_material", values.courseMaterialValue);
    formData.append("course_material_text", textEditorHtml[2].value);
    formData.append("assignments", values.assignmentsValue);
    formData.append("assignments_text", textEditorHtml[3].value);
    formData.append("discussion_forum", values.discussionFormValue);
    formData.append("discussion_forum_text", textEditorHtml[4].value);
    formData.append("final_exam", values.finalExamsValue);
    formData.append("final_exam_text", textEditorHtml[5].value);
    formData.append("bottom_text", bottomEditorHtml);

    API.post("/admin/course/update", formData)
      .then((res) => {
        message.success("Updated Succesfully", 3);
        setRefresh(!refresh);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);

        message.error("something went wrong", 3);
      });
  };

  return (
    <div className="pages-container">
      <Card bordered={false} className="p-0" loading={loading}>
        {courseData && (
          <Form onFinish={handleFinish}>
            <div className="content-wrapper">
              <div className="bg-content-container">
                <div className="fw-600 mb-2">{trans.Studyacourse}</div>
                <Editor
                  key="courseStudys"
                  editorHtml={studyEditorHtml}
                  setEditorHtml={setStudyEditorHtml}
                  handleChange={handleStudyEditorChange}
                />
              </div>
              {/* course include */}
              <div className="fw-600 mb-2">{trans.Coursesinclude}</div>
              {courseIncludes.map((courseInclude, index) => {
                return (
                  <CourseInclude
                    index={index}
                    inputValueNames={inputValueNames}
                    courseData={courseData}
                    courseInclude={courseInclude}
                    textEditorHtml={textEditorHtml}
                    setTextEditorHtml={setTextEditorHtml}
                  />
                );
              })}
            </div>
            <div className="bg-content-container">
              <div className="fw-600 mb-2">{trans.text}</div>
              <Editor
                key="bottomText"
                editorHtml={bottomEditorHtml}
                setEditorHtml={setBottomEditorHtml}
                handleChange={handleBottomEditorChange}
              />
            </div>
            {/* save changes */}

            <Button
              className="btn-save-changes"
              type="primary"
              htmlType="Submit"
            >
              {trans.Save}
            </Button>
          </Form>
        )}
      </Card>
    </div>
  );
};

export default Courses;

const CourseInclude = ({
  index,
  inputValueNames,
  courseData,
  courseInclude,
  textEditorHtml,
  setTextEditorHtml,
}) => {
  // useEffect(() => {
  //   const data = [...textEditorHtml];
  //   data[index].value = courseData[inputValueNames[index].text];
  // }, [courseData, textEditorHtml, index, inputValueNames]);

  const handleTextEditorChange = (html) => {
    const data = [...textEditorHtml];
    data[index].value = html;
    setTextEditorHtml([...data]);
  };

  return (
    <div className="bg-content-container" key={index}>
      <div className="fw-600 mb-2">{courseInclude.name}</div>
      <div className="fw-400 f-12 mb-2">{trans.Value}</div>
      <div className="accordion-detail course-include-value">
        <Form.Item
          name={`${inputValueNames[index].key}Value`}
          initialValue={courseData[inputValueNames[index].value]}
        >
          <Input type="text" placeholder="26" />
        </Form.Item>
      </div>
      <div className="fw-400 f-12 mb-2">{trans.text} </div>
      <Editor
        key={index}
        editorHtml={
          textEditorHtml[index].value !== ""
            ? textEditorHtml[index].value
            : courseData[inputValueNames[index].text]
        }
        handleChange={handleTextEditorChange}
      />
    </div>
  );
};
