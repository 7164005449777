import React, { useState, useEffect } from "react";
import API from "../../../API";
import { Form, Input, Button, message } from "antd";
import { Card } from "antd";
import trans from "../../../Languages/Languages";
import Editor from "../../Components/common/Editor";
import ImageIcon from "../../../assets/icons/image.svg";

const KIUDirector = () => {
  message.config({
    top: 80,
  });
  const [directorMessage, setDirectorMessage] = useState("");
  const [text, setText] = useState("");
  const [kiuDirectorData, setKiuDirectorData] = useState([]);
  const [headerImage, setHeaderImage] = useState({ image: "" });
  const [loading, setLoading] = useState(false);
  const [Refresh, setRefresh] = useState(false);
  const [errors, setErrors] = useState({});

  const handleDirectorMessageChange = (html) => {
    setDirectorMessage(html);
  };
  const handleSecondTextEditorChange = (html) => {
    setText(html);
  };

  // Use Effect
  useEffect(() => {
    setLoading(true);
    const init = () => {
      API.get("/admin/director")
        .then((res) => {
          setKiuDirectorData(res.data);
          setDirectorMessage(res.data[0].message);
          setText(res.data[0].text);

          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    };
    init();

    // getCounters();
  }, [Refresh]);

  const handleSave = (values) => {
    const formData = new FormData();

    formData.append("first_name", values.firstName);
    formData.append("last_name", values.lastName);
    formData.append("description", values.description);
    headerImage.image !== ""
      ? formData.append("image", headerImage.image)
      : formData.append("image", kiuDirectorData[0].image);
    formData.append("message", directorMessage);
    formData.append("text", text);

    setLoading(true);
    API.post(`/admin/director/update`, formData)
      .then((res) => {
        message.success("Updated Successfully", 3);
        setRefresh(!Refresh);
        setErrors({});
        setLoading(false);
      })
      .catch((e) => {
        if (e.response.status === 400) {
          setErrors(e.response.data);
        }

        setLoading(false);
      });
  };

  return (
    <div className="pages-container">
      <div className="content-wrapper director-detail">
        <Card bordered={false} loading={loading}>
          {kiuDirectorData.length > 0 && (
            <>
              <WideImageUpload
                record={kiuDirectorData}
                headerImage={headerImage}
                setHeaderImage={setHeaderImage}
                setData={setKiuDirectorData}
              />
              {errors?.image && (
                <span className="text-danger">
                  The image must not be greater than 5 MB.
                </span>
              )}

              <Form onFinish={handleSave} initialValues={kiuDirectorData[0]}>
                <div className="bg-content-container director-input">
                  <div className="fw-600 h-heading ">{trans.DirectorofKIU}</div>
                  {/* header Upload Image */}

                  {/* content */}

                  <div className="d-flex justify-content-start ">
                    {/* First Name */}

                    <div className="accordion-detail d-input-name mr-5">
                      <div className="f-12 fw-500 input-heading">
                        {trans.FirstNameBold}
                      </div>
                      <Form.Item
                        name="firstName"
                        initialValue={kiuDirectorData[0].first_name}
                        className="m-0"
                      >
                        <Input placeholder={trans.Typehere} />
                      </Form.Item>
                      {errors?.first_name && (
                        <span className="text-danger">
                          {errors?.first_name}
                        </span>
                      )}
                    </div>

                    {/* Last Name */}

                    <div className="accordion-detail d-input-name">
                      <div className="f-12 fw-500 input-heading ">
                        {trans.LastNameRegular}
                      </div>
                      <Form.Item
                        name="lastName"
                        initialValue={kiuDirectorData[0].last_name}
                        className="m-0"
                      >
                        <Input
                          placeholder={trans.Typehere}
                          defaultValue="initial"
                        />
                      </Form.Item>
                      {errors?.last_name && (
                        <span className="text-danger">{errors?.last_name}</span>
                      )}
                    </div>
                  </div>
                  {/* description */}
                  <div className="accordion-detail">
                    <div className="f-12 fw-500 input-heading ">
                      {trans.Description}
                    </div>
                    <Form.Item
                      name="description"
                      initialValue={kiuDirectorData[0].description}
                      className="m-0"
                    >
                      <Input
                        placeholder={trans.Typehere}
                        className="w-100"
                        defaultValue="initial"
                      />
                    </Form.Item>
                    {errors?.description && (
                      <span className="text-danger">{errors?.description}</span>
                    )}
                  </div>
                  {/* about us text Area */}
                  <div className="f-12 fw-500 carousel-text-heading mb-2">
                    {trans.Directorsmessage}
                  </div>
                  <Editor
                    key="directorMessage"
                    editorHtml={directorMessage}
                    setEditorHtml={setDirectorMessage}
                    handleChange={handleDirectorMessageChange}
                  />
                  {errors?.message && (
                    <span className="text-danger">{errors?.message}</span>
                  )}
                </div>

                {/* end */}

                {/* about us text Area */}

                <div className="bg-content-container">
                  <div className="mb-5">
                    <div className="fw-400 f-15  mb-2">{trans.text}</div>
                    <Editor
                      key="second"
                      editorHtml={text}
                      setEditorHtml={setText}
                      handleChange={handleSecondTextEditorChange}
                    />
                    {errors?.text && (
                      <span className="text-danger">{errors?.text}</span>
                    )}
                  </div>
                </div>
                {/* save changes */}

                <Button
                  className="btn-save-changes"
                  type="primary"
                  htmlType="submit"
                >
                  {trans.Save}
                </Button>
              </Form>
            </>
          )}
        </Card>
      </div>
    </div>
  );
};

export default KIUDirector;

///////////////////////////////////////////////////////////////////////////////////////////////
////////////                             Wide image
///////////////////////////////////////////////////////////////////////////////////////////////

const WideImageUpload = ({ record, headerImage, setHeaderImage, setData }) => {
  const [isActionShown, setIsActionShown] = useState(false);

  // eslint-disable-next-line
  const handleTextAreaChange = ({ event }) => {};

  useEffect(() => {});

  const handleChange = (e) => {
    setHeaderImage({ image: e.target.files[0] });
    const dummyData = [...record];
    dummyData[0].image = URL.createObjectURL(e.target.files[0]);
    setData(dummyData);
  };
  const inputHeaderReplace = React.createRef();

  const handleReplace = () => {
    inputHeaderReplace.current.click();
  };

  const handleRemove = () => {
    setHeaderImage({ image: "" });
    const dummyData = [...record];
    dummyData[0].image = "";
    setData(dummyData);
  };

  return (
    <div>
      <div
        className="h-image-upload-container"
        onMouseEnter={() => setIsActionShown(true)}
        onMouseLeave={() => setIsActionShown(false)}
      >
        <div
          className={
            record.length > 0 && record[0].image
              ? "h-image-upload medium-img h-uploaded"
              : "h-image-upload medium-img"
          }
          style={{ position: "relative" }}
        >
          {isActionShown && (
            <div>
              {/* image action */}
              <div className="image-action-container">
                <div className="image-action d-flex justify-content-center">
                  <div
                    className="f-9 fw-400 image-action-txt"
                    onClick={handleReplace}
                  >
                    {trans.Replace}
                  </div>
                  <div className="image-action-divider mx-2"></div>
                  <div
                    className="f-9 fw-400 image-action-txt"
                    onClick={handleRemove}
                  >
                    {trans.Remove}
                  </div>
                </div>
              </div>
            </div>
          )}

          <label className="image-box" htmlFor="image1">
            {record.length > 0 && record[0].image ? (
              <>
                {/* <img src={record[0].image} alt='' /> */}
                <div
                  className="images-background-config"
                  style={{
                    width: "100%",
                    height: 182,
                    background: `url(${record[0]?.image})`,
                  }}
                ></div>
              </>
            ) : (
              <div className="cursor-pointer">
                <img src={ImageIcon} alt="" className="px-2" />
                <span>Upload Image</span>
              </div>
            )}

            <input
              ref={inputHeaderReplace}
              type="file"
              name="image1"
              id="image1"
              style={{ display: "none" }}
              onChange={handleChange}
            />
          </label>
        </div>
        <span className="float-left f-img-size f-12 fw-400">
          {trans.Maxsize} : 5MB {trans.RecommendedResolution} : 1920 x 1080
        </span>
      </div>
      {/*/////////////////////////////////////////////////////////////////*/}
    </div>
  );
};
