import axios from "axios";

const user = JSON.parse(sessionStorage.getItem("user"));

if (user?.token)
  (function () {
    let token = user?.token;
    if (token) {
      axios.defaults.headers.common["locale"] =
        user.user?.college.slice(0, 2) ?? "ar";
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } else {
      axios.defaults.headers.common["Authorization"] = null;
    }
  })();
export default axios.create({
  baseURL: `https://site-api.kiu.org/api`,
  // baseURL: `http://kiu-website-backend.test/api`,
});
