import { Upload, DatePicker, Form, Input, message, Button } from "antd";
import trans from "../../../Languages/Languages";
import React, { useState } from "react";
import moment from "moment";
import API from "../../../API";
const dateFormat = "YYYY-MM-DD";

const AccordionCalendarContent = ({
  data,
  setData,
  setLoading,
  refresh,
  setRefresh,
}) => {
  const handleRemoveFile = () => {};
  const handleChangeFile = () => {};

  const [fromDate, setFromDate] = useState(data[0].from);
  const [toDate, setToDate] = useState(data[0].to);

  const handleDateChange = (dateString, name) => {
    name === 0 ? setToDate(dateString) : setFromDate(dateString);
  };

  const handleSaveAcademicCalender = (values) => {
    setLoading(true);
    const formData = new FormData();
    formData.append(
      "from",
      moment(values.academicYearFrom).format("YYYY-MM-DD")
    );
    formData.append("to", moment(values.academicYearTo).format("YYYY-MM-DD"));
    formData.append("content", values.content);
    formData.append("file", values?.file?.file?.originFileObj ?? values?.file);

    API.post(`/admin/calender/update/${data[0].id}`, formData)
      .then((res) => {
        const dummyData = [...data];
        dummyData[0].from = values.academicYearFrom;
        dummyData[0].to = values.academicYearTo;
        dummyData[0].content = values.content;
        dummyData[0].file = values.file;
        setData(dummyData);
        message.success(" Updated Successfully", 1);
        setLoading(false);
        setRefresh(!refresh);
      })
      .catch((err) => {
        message.error("Something went wrong, please try again", 1);
        setLoading(false);
      });
  };

  return (
    <div className="mb-3">
      <Form
        onFinish={handleSaveAcademicCalender}
        initialValues={{
          ...data[0],
          academicYearFrom: moment(fromDate),
          academicYearTo: moment(toDate),
        }}
      >
        <div style={{ marginBottom: 29 }}>
          <div className="row">
            <div className="col-2"></div>
            <div className="col-2 f-12 fw-400">From</div>
            <div className="col-2 f-12 fw-400">To</div>
          </div>
          <div className="row">
            <div className="col-2">
              <div className="f-15 mr-4 fw-400">Enter Academic Year</div>
            </div>
            <div className="col-2">
              <Form.Item name="academicYearFrom">
                <DatePicker
                  name="to"
                  onChange={(date, dateString) =>
                    handleDateChange(dateString, 1)
                  }
                  format={dateFormat}
                />
              </Form.Item>
            </div>
            <div className="col-2">
              <Form.Item name="academicYearTo">
                <DatePicker
                  name="to"
                  onChange={(date, dateString) =>
                    handleDateChange(dateString, 0)
                  }
                  format={dateFormat}
                />
              </Form.Item>
            </div>
          </div>
        </div>
        <div className="mb-2">
          <p className="f-16 fw-500">{trans.Downloadablecontent}</p>
          <div className="d-flex justify-content-start">
            <Form.Item name="content">
              <Input className="d-inline-block" />
            </Form.Item>
            <Form.Item name="file">
              <Upload
                className="d-inline-block"
                maxCount={1}
                showUploadList={false}
                customRequest=""
                onRemove={handleRemoveFile}
                onChange={handleChangeFile}
              >
                {data[0].file === "" ? (
                  <span className="btn f-12 fw-400 upload-file ml-2">
                    Upload
                  </span>
                ) : (
                  <span className="btn f-12 fw-400 upload-file ml-2">
                    Replace
                  </span>
                )}
              </Upload>
            </Form.Item>
          </div>
          {data[0].file && (
            <a
              href={data[0].file}
              target="_blank"
              rel="noreferrer"
              style={{ color: "#079751 !important" }}
            >
              Download File
            </a>
          )}
        </div>
        {/* save changes */}
        <Button
          htmlType="submit"
          style={{ marginBottom: 23 }}
          className="btn-save-section-no-Line fw-500 float-right"
        >
          <span>{trans.Savesection}</span>
        </Button>
      </Form>
    </div>
  );
};

export default AccordionCalendarContent;
