import Editor from "../../Components/common/Editor";
import React, {useState, useEffect} from "react";

import {Button, Input, Form, Card, message} from "antd";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import ImageIcon from "../../../assets/icons/image.svg";
import trans from "../../../Languages/Languages";
import API from "../../../API";

const BenefitOfKIUDegree = () => {
  message.config({
    top: 80,
  });

  const [benefitData, setBenefitData] = useState([]);
  const [newData, setNewData] = useState([]);

  const handleAnotherRecord = ({data}) => {
    const dummyData = [...benefitData, {image: "", subject: "", new: true}];
    setBenefitData(dummyData);

    const newRecord = [...newData, {image: "", subject: "", new: true}];
    setNewData(newRecord);
  };

  const [editorHtml, setEditorHtml] = useState("");

  const handleTextEditorChange = (html) => {
    setEditorHtml(html);
  };

  const [loading, setLoading] = useState(true);
  const [aboutSectionLoading, setAboutSectionLoading] = useState(false);

  const [refresh, setRefresh] = useState(true);
  //
  ///////////////////////////////////////////////////////////////////////////////////////////////
  ///                             Use Effect
  ////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    setLoading(true);

    const init = async () => {
      await API.get("/admin/benefits")
        .then((res) => {
          setEditorHtml(res.data.about ? res.data.about.about : "");
          setBenefitData(res.data.subjects);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    };

    init();
  }, [refresh]);
  ///////////////////////////////////////////////////////////////////////////////////////////////
  ///                             create new records
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const handleCreateNew = async () => {
    // let error = false;
    setLoading(true);
    if (newData.length > 0) {
      const formData = new FormData();
      newData.forEach((element, i) => {
        // if (element.image === "" || element.subject === "") {
        //   error = true;
        // }
        formData.append(`data[${i}][subject]`, element.subject);
        formData.append(`data[${i}][image]`, element.image);
      });

      await API.post("/admin/benefits/create", formData)
        .then((res) => {
          setNewData([]);
          message.success("Created Successfullyy", 3);
          setLoading(false);
          setRefresh(!refresh);
        })
        .catch((err) => {
          message.error("All fields must be filled");
          setLoading(false);
          // Object.keys(err.response.data).map((e) => {
          //   message.error(err.response.data[e]);
          // });
        });
    }
  };
  ///////////////////////////////////////////////////////////////////////////////////////////////
  ///                             update about section
  ////////////////////////////////////////////////////////////////////////////////////////////////
  const handleAboutSave = async () => {
    // setAboutSectionLoading(true);
    const formData = new FormData();
    formData.append("about", editorHtml);

    await API.post(`/admin/benefits/about/update`, formData)
      .then((res) => {
        setAboutSectionLoading(false);
        message.success("About Section Updated Successfully", 3);
      })
      .catch((err) => {
        setAboutSectionLoading(false);
        message.error("Something went wrong, please try again", 3);
      });
  };
  return (
    <div className='pages-container'>
      <Card loading={loading} bordered={false}>
        <div className='content-wrapper'>
          <Card loading={aboutSectionLoading} bordered={false}>
            <div className='bg-content-container'>
              <div className='fw-600 mb-2'>{trans.BenefitofKIUdegree}</div>
              <Editor
                key='benefitOfKIUs'
                editorHtml={editorHtml}
                setEditorHtml={setEditorHtml}
                handleChange={handleTextEditorChange}
              />
              <div className='d-flex justify-content-end save-section'>
                {/* save changes */}
                <div
                  className='btn-save-section fw-500'
                  onClick={handleAboutSave}
                >
                  <span>{trans.Savesection}</span>
                </div>
              </div>
            </div>
          </Card>

          {benefitData.map((benefit, index) => {
            return (
              <Benefit
                benefit={benefit}
                index={index}
                benefitData={benefitData}
                setBenefitData={setBenefitData}
                refresh={refresh}
                setRefresh={setRefresh}
                newData={newData}
                setNewData={setNewData}
                setLoading={setLoading}
              />
            );
          })}

          {/* add another */}

          <div className='d-flex justify-content-center'>
            <div onClick={handleAnotherRecord}>
              <span className='f-12 fw-500 cursor-pointer'>
                <span className='mr-2'> {trans.AddNewSection}</span>
                <span className='f-18 fw-500 align-middle'>+</span>
              </span>
            </div>
          </div>

          {/* save changes */}

          {newData.length > 0 && (
            <Button
              onClick={handleCreateNew}
              className='btn-save-changes'
              type='primary'
              htmlType='submit'
            >
              {trans.Save}
            </Button>
          )}
        </div>
      </Card>
    </div>
  );
};

export default BenefitOfKIUDegree;

const Benefit = ({
  benefit,
  index,
  benefitData,
  setBenefitData,
  refresh,
  setRefresh,
  newData,
  setNewData,
}) => {
  const [headerImage, setHeaderImage] = useState({image: ""});
  const [loading, setLoading] = useState(false);

  ///////////////////////////////////////////////////////////////////////////////////////////////
  ///                             delete new record
  ////////////////////////////////////////////////////////////////////////////////////////////////
  const handleNewBenefitDelete = (index) => {
    const dummyData = benefitData.filter((item, i) => {
      return index !== i;
    });
    setBenefitData(dummyData);
    let newIndex = newData.length + index - benefitData.length;
    let newRecord = [];
    newRecord = newData.filter((item, i) => {
      return newIndex !== i;
    });
    setNewData(newRecord);
  };
  ///////////////////////////////////////////////////////////////////////////////////////////////
  ///                             delete record
  ////////////////////////////////////////////////////////////////////////////////////////////////
  const handleBenefitDelete = async (index) => {
    setLoading(true);

    await API.post(`/admin/benefits/delete/${benefitData[index].id}`)
      .then((res) => {
        setRefresh(!refresh);
        setEdit(false);
        setLoading(false);
        message.success("Deleted Successfully", 3);
      })
      .catch((err) => {
        message.error("Something went wrong, please try again", 3);
        setLoading(false);
      });
  };
  const [edit, setEdit] = useState(false);
  ///////////////////////////////////////////////////////////////////////////////////////////////
  ///                             update record
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const handleSave = async (values) => {
    // setLoading(true);

    const formData = new FormData();
    if (headerImage.image !== "") formData.append("image", headerImage.image);
    else formData.append("image", benefit.image);
    formData.append("subject", values.subject);

    await API.post(`/admin/benefits/update/${benefit.id}`, formData)
      .then((res) => {
        // setRefresh(!refresh);
        setEdit(false);
        setLoading(false);
        message.success("Updated Successfully", 3);
      })
      .catch((err) => {
        setLoading(false);
        Object.keys(err.response.data).map((e) => {
          message.error(err.response.data[e]);
        });
      });
  };

  const handleInputValueChange = ({target}) => {
    if (newData.length + index - benefitData.length >= 0) {
      const dummyNewData = [...newData];
      dummyNewData[newData.length + index - benefitData.length].subject =
        target.value;
      setNewData(dummyNewData);
    }
  };
  let rules = [
    {
      required: true,
      message: "field is required",
    },
  ];
  return (
    <div className='bg-content-container b-container'>
      {/* edit action */}
      <Card
        loading={loading}
        bordered={false}
        className='p-0 -0 position-static'
      >
        <Form onFinish={handleSave}>
          <div style={{height: 10}}>
            {edit ? (
              <div className='edit-action icon-container d-block mr-1'>
                <span
                  className='f-12 fw-400 cancel '
                  onClick={() => setEdit(false)}
                >
                  {trans.Cancel}
                </span>

                <span className='save'>
                  <Button type='' htmlType='submit'>
                    {trans.Save}
                  </Button>
                </span>
              </div>
            ) : (
              <div className='icon-container'>
                {!benefit.new && (
                  <span onClick={() => setEdit(true)}>
                    <EditIcon className='b-icon-edit' />
                  </span>
                )}
                <CloseIcon
                  className='b-icon-close'
                  onClick={() => {
                    benefit.new
                      ? handleNewBenefitDelete(index)
                      : handleBenefitDelete(index);
                  }}
                />
              </div>
            )}
          </div>
          {/* header Upload Image */}
          <div>
            <WideImageUpload
              record={benefitData}
              headerImage={headerImage}
              setHeaderImage={setHeaderImage}
              index={index}
              setData={setBenefitData}
              data={benefit}
              setNewData={setNewData}
              newData={newData}
              edit={edit}
            />
          </div>
          {/* end image */}
          {/* description */}
          <div className='accordion-detail'>
            <div className='f-12 fw-500 input-heading'>{trans.Subject}</div>
            <Form.Item
              rule={rules}
              name='subject'
              initialValue={benefit.subject}
            >
              <Input
                disabled={edit || benefit.new ? "" : "disabled"}
                placeholder={trans.Typehere}
                className='w-100'
                onChange={handleInputValueChange}
              />
            </Form.Item>
          </div>
        </Form>
      </Card>
    </div>
  );
};

///////////////////////////////////////////////////////////////////////////////////////////////
////////////                             Wide image
///////////////////////////////////////////////////////////////////////////////////////////////

const WideImageUpload = ({
  headerImage,
  setHeaderImage,
  index,
  record,
  setData,
  data,
  newData,
  setNewData,
  edit,
}) => {
  const [isActionShown, setIsActionShown] = useState(false);

  useEffect(() => {});

  const handleChange = (e) => {
    setHeaderImage({image: e.target.files[0]});

    const dummyData = [...record];
    record[index].image = URL.createObjectURL(e.target.files[0]);
    setData(dummyData);
    if (newData.length + index - record.length >= 0) {
      const dummyNewData = [...newData];
      dummyNewData[newData.length + index - record.length].image =
        e.target.files[0];

      setNewData(dummyNewData);
    }
  };
  const inputHeaderReplace = React.createRef();

  const handleReplace = () => {
    inputHeaderReplace.current.click();
  };

  const handleRemove = () => {
    setHeaderImage({image: ""});
    const dummyData = [...record];
    record[index].image = "";
    setData(dummyData);
  };

  return (
    <div className='catalogue-wide-image'>
      <div
        className='h-image-upload-container'
        onMouseEnter={() => setIsActionShown(true)}
        onMouseLeave={() => setIsActionShown(false)}
      >
        <div
          className={
            record.length > 0 && data.image
              ? "h-image-upload medium-img h-uploaded"
              : "h-image-upload medium-img"
          }
          style={{position: "relative"}}
        >
          {isActionShown && (edit || data.new) && (
            <div>
              {/* image action */}
              <div className='image-action-container'>
                <div className='image-action d-flex justify-content-center'>
                  <div
                    className='f-9 fw-400 image-action-txt'
                    onClick={handleReplace}
                  >
                    {trans.Replace}
                  </div>
                  <div className='image-action-divider mx-2'></div>
                  <div
                    className='f-9 fw-400 image-action-txt'
                    onClick={handleRemove}
                  >
                    {trans.Remove}
                  </div>
                </div>
              </div>
            </div>
          )}

          <label className='image-box' htmlFor={`courseCatalogueImage${index}`}>
            {record.length > 0 && data.image ? (
              <img src={data.image} alt='' />
            ) : (
              <div>
                <img src={ImageIcon} alt='' className='px-2' />
                <span>Upload Image</span>
              </div>
            )}

            <input
              disabled={edit || data.new ? "" : "disabled"}
              ref={inputHeaderReplace}
              type='file'
              name='image1'
              id={`courseCatalogueImage${index}`}
              style={{display: "none"}}
              onChange={handleChange}
            />
          </label>
        </div>
        <span className='float-left f-img-size f-12 fw-400'>
          {trans.Maxsize} : 5MB {trans.RecommendedResolution} : 380 x 110
        </span>
      </div>
    </div>
  );
};
