import Editor from "../../Components/common/Editor";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import ImageIcon from "../../../assets/icons/image.svg";
import { Form, Input, Button, Card, message } from "antd";
import trans from "../../../Languages/Languages";
import React, { useEffect, useState } from "react";
import API from "../../../API";

const BoardOfTrustees = () => {
  message.config({
    top: 80,
  });
  const [boardMembersData, setBoardMembersData] = useState([]);
  const [newData, setNewData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [botLoading, setBotLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [errors, setErrors] = useState({});

  const handleAnotherBoardMember = ({ data }) => {
    const dummyData = [
      ...boardMembersData,
      { first_name: "", last_name: "", description: "", image: "", new: true },
    ];
    setBoardMembersData(dummyData);

    const newRecord = [
      ...newData,
      { first_name: "", last_name: "", description: "", image: "", new: true },
    ];
    setNewData(newRecord);
  };

  const [editorHtml, setEditorHtml] = useState("");

  const handleTextEditorChange = (html) => {
    setEditorHtml(html);
  };
  ///////////////////////////////////////////////////////////////////////////////////////////////
  ///                  Use Effect
  ////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    const init = async () => {
      // setLoading(true);
      await API.get("/admin/bots")
        .then((res) => {
          setBoardMembersData(res.data.bots);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    };

    init();

    const botAbout = async () => {
      await API.get("/admin/bot/get/about")
        .then((res) => {
          setEditorHtml(res.data.about);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    };

    botAbout();
  }, [refresh]);

  const handleSectionSave = async () => {
    const formData = new FormData();
    formData.append("about", editorHtml);
    await API.post("/admin/bot/about/update", formData)
      .then((res) => {
        message.success("Updated Successfully", 3);
        setRefresh(!refresh);
      })
      .catch((err) => {
        message.error("Something went wrong, please try again", 3);
      });
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////
  ///                             create new records
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const handleCreateNew = async () => {
    setLoading(true);
    if (newData.length > 0) {
      const formData = new FormData();
      newData.forEach((element, i) => {
        formData.append(`bot[${i}][first_name]`, element.first_name);
        formData.append(`bot[${i}][last_name]`, element.last_name);
        formData.append(`bot[${i}][description]`, element.description);
        formData.append(`bot[${i}][image]`, element.image);
      });

      await API.post("/admin/bot/create", formData)
        .then((res) => {
          setNewData([]);
          message.success("Board Member Created Successfully");
          setErrors({});
          setLoading(false);
          setRefresh(!refresh);
        })
        .catch((err) => {
          if (err.response.status === 400) {
            setErrors(err.response.data);

            Object.keys(err.response.data).map((e) => {
              message.error(err.response.data[e]);
            });
          }

          setLoading(false);
        });
    }
  };

  return (
    <div className="pages-container">
      <div className="content-wrapper">
        <Card
          style={{ minHeight: 768 }}
          bordered={false}
          className="p-0 m-0 position-static"
          loading={loading}
        >
          {/* about us text Area */}
          <Card bordered={false} className="p-0 m-0 position-static">
            <div className="bg-content-container">
              <div className="fw-600 f-14  mb-2">{trans.text}</div>
              <Editor
                key="trustees"
                editorHtml={editorHtml}
                setEditorHtml={setEditorHtml}
                handleChange={handleTextEditorChange}
              />
              <div className="d-flex justify-content-end save-section">
                {/* save changes */}
                <div
                  onClick={handleSectionSave}
                  className="btn-save-section fw-500"
                  // onClick={handleHomeCarouselSaveSection}
                >
                  <span>{trans.Savesection}</span>
                </div>
              </div>
            </div>
          </Card>
          {/* board members */}
          <Card
            bordered={false}
            className="p-0 m-0 position-static"
            loading={botLoading}
          >
            {boardMembersData && (
              <div>
                <div className="fw-500 f-14 mb-2">{trans.Boardmembers}</div>
                {boardMembersData.map((item, index) => {
                  return (
                    <BoardMembers
                      key={index}
                      data={item}
                      index={index}
                      originalData={boardMembersData}
                      setData={setBoardMembersData}
                      refresh={refresh}
                      setRefresh={setRefresh}
                      newData={newData}
                      setNewData={setNewData}
                      setLoading={setBotLoading}
                      errors={errors}
                      setErrors={setErrors}
                    />
                  );
                })}
              </div>
            )}
          </Card>

          {/* end board member */}

          {/* add another */}
          <div className="position-relative">
            <div className="b-add-another" onClick={handleAnotherBoardMember}>
              {trans.Addanother} <span className="f-18 align-middle">+</span>
            </div>
          </div>
          {/* save changes */}

          {newData.length > 0 && (
            <Button
              onClick={handleCreateNew}
              className="btn-save-changes"
              type="primary"
              htmlType="submit"
            >
              {trans.Save}
            </Button>
          )}
        </Card>
      </div>
    </div>
  );
};

export default BoardOfTrustees;

const BoardMembers = ({
  originalData,
  data,
  setData,
  index,
  setRefresh,
  refresh,
  newData,
  setNewData,
  setMainLoading,
  setLoading,
  errors,
  setErrors,
}) => {
  const [edit, setEdit] = useState(false);
  const [currentImageObject, setCurrentImageObject] = useState({ image: "" });

  const handleChange = (e) => {
    setCurrentImageObject({ image: e.target.files[0] });

    const dummyData = [...originalData];
    dummyData[index].image = URL.createObjectURL(e.target.files[0]);
    setData(dummyData);

    if (newData.length + index - originalData.length >= 0) {
      const dummyNewData = [...newData];
      dummyNewData[newData.length + index - originalData.length].image =
        e.target.files[0];
      setNewData(dummyNewData);
    }
  };

  // update record
  const handleSectionSave = async (values) => {
    const formData = new FormData();
    formData.append("first_name", values.first_name);
    formData.append("last_name", values.last_name);
    formData.append("description", values.description);
    currentImageObject.image === ""
      ? formData.append("image", data.image)
      : formData.append("image", currentImageObject.image);

    await API.post(`/admin/bot/update/${data.id}`, formData)
      .then((res) => {
        message.success("Board Member Updated Successfully");
        setEdit(false);
        setRefresh(!refresh);
      })
      .catch((err) => {
        if (err.response.status === 400) {
          setErrors(err.response.data);

          Object.keys(err.response.data).map((e) => {
            message.error(err.response.data[e]);
          });
        }
      });
  };

  const inputReplace = React.createRef();
  const [isActionShown, setIsActionShown] = useState(false);

  // remove image
  const handleRemove = (index) => {
    const UpdatedData = originalData.map((item, i) => {
      let temp = item;
      if (i === index) temp.image = "";
      return temp;
    });
    setData(UpdatedData);
  };

  const handleReplace = () => {
    inputReplace.current.click();
  };
  const handleInputValueChange = ({ target }) => {
    if (newData.length + index - originalData.length >= 0) {
      const dummyNewData = [...newData];
      dummyNewData[newData.length + index - originalData.length][target.name] =
        target.value;
      setNewData(dummyNewData);
    }
  };

  const handleRemoveNewBOT = () => {
    const dummyData = originalData.filter((item, i) => {
      return index !== i;
    });

    setData(dummyData);
    let newIndex = newData.length + index - originalData.length;
    let newRecord = [];
    newRecord = newData.filter((item, i) => {
      return newIndex !== i;
    });
    setNewData(newRecord);
  };
  ///////////////////////////////////////////////////////////////////////////////////////////////
  ///                             delete record
  ////////////////////////////////////////////////////////////////////////////////////////////////
  const handleRemoveBOT = async () => {
    setLoading(true);

    await API.post(`/admin/bot/delete/${data.id}`)
      .then((res) => {
        const dummyData = originalData.filter((item, i) => {
          return index !== i;
        });
        setData(dummyData);
        message.success("Board Member Deleted Successfully", 3);
        setLoading(false);
      })
      .catch((err) => {
        message.error("Something went wrong, please try again", 3);
        setLoading(false);
      });
  };
  return (
    <div className="bg-content-container b-container b-container-input">
      <Form onFinish={handleSectionSave} initialValues={data}>
        {/* edit action */}
        <div style={{ height: 10 }}>
          {edit ? (
            <div className="edit-action icon-container d-block mr-1">
              <span
                className="f-12 fw-400 cancel cursor-pointer"
                onClick={() => setEdit(false)}
              >
                {trans.Cancel}
              </span>

              <span className="save">
                <Button type="" htmlType="submit">
                  {trans.Save}
                </Button>
              </span>
            </div>
          ) : (
            <div className="icon-container">
              {!data.new && (
                <span onClick={() => setEdit(true)}>
                  <EditIcon className="b-icon-edit cursor-pointer" />
                </span>
              )}
              <CloseIcon
                className="b-icon-close cursor-pointer"
                onClick={() => {
                  data.new ? handleRemoveNewBOT(index) : handleRemoveBOT(index);
                }}
              />
            </div>
          )}
        </div>

        <div className="b-bg-content-container d-flex justify-content-between w-100">
          <div>
            <div className="accordion-detail d-block">
              <div className="f-12 fw-500 b-input-heading">
                {trans.FirstNameBold}
                <Form.Item name="first_name" className="m-0">
                  <Input
                    disabled={edit || data.new ? "" : "disabled"}
                    name="first_name"
                    onChange={handleInputValueChange}
                    type="text"
                    placeholder={trans.Typehere}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="accordion-detail ">
              <div className="f-12 fw-500 b-input-heading">
                {trans.LastNameRegular}
                <Form.Item name="last_name" className="m-0">
                  <Input
                    disabled={edit || data.new ? "" : "disabled"}
                    name="last_name"
                    onChange={handleInputValueChange}
                    type="text"
                    placeholder={trans.Typehere}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="accordion-detail ">
              <div className="f-12 fw-500 b-input-heading">
                {trans.Description}
                <Form.Item name="description" className="m-0">
                  <Input
                    disabled={edit || data.new ? "" : "disabled"}
                    name="description"
                    onChange={handleInputValueChange}
                    type="text"
                    placeholder={trans.Typehere}
                  />
                </Form.Item>
              </div>
            </div>
            {/* image */}
          </div>
          <div
            className={
              data.image !== "" && data.image
                ? "future-image-upload-container bot-image uploaded"
                : "future-image-upload-container bot-image"
            }
            key={index}
          >
            {/* medium images */}
            <div
              className="future-image-upload medium-img"
              onMouseEnter={() => setIsActionShown(true)}
              onMouseLeave={() => setIsActionShown(false)}
            >
              {isActionShown && (edit || data.new) && (
                <div>
                  {/* image action */}
                  <div className="image-action-container">
                    <div className="image-action d-flex justify-content-center">
                      <div
                        className="f-9 fw-400 image-action-txt"
                        onClick={handleReplace}
                      >
                        {trans.Replace}
                      </div>
                      <div className="image-action-divider mx-2"></div>
                      <div
                        className="f-9 fw-400 image-action-txt"
                        onClick={() => handleRemove(index)}
                      >
                        {trans.Remove}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <label className="bot-image-box" htmlFor={`botImage${index}`}>
                {data.image !== "" && data.image ? (
                  <img src={data.image} alt="" />
                ) : (
                  <div className="cursor-pointer">
                    <img src={ImageIcon} alt="" className="pl-1 pr-1" />
                    <span className="f-12 fw-400 pr-1">Upload Image</span>
                  </div>
                )}

                <input
                  disabled={edit || data.new ? "" : "disabled"}
                  ref={inputReplace}
                  type="file"
                  name="image1"
                  id={`botImage${index}`}
                  style={{ display: "none" }}
                  onChange={handleChange}
                />
              </label>
            </div>
            <div className="d-flex flex-column">
              <span className="float-right f-img-size f-12 fw-400 align-self-end">
                {trans.Maxsize} : 5MB
              </span>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};
