import Editor from "../../Components/common/Editor";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import { Button, Form, Input, Card, message } from "antd";
import { useState, useEffect } from "react";
import trans from "../../../Languages/Languages";
import React from "react";
import ImageIcon from "../../../assets/icons/image.svg";
import API from "../../../API";

const Faculties = () => {
  message.config({
    top: 100,
  });
  /////////////////////   arabic member /////////////////////
  const [arabicFacultyData, setArabicFacultyData] = useState([]);
  const [newArabicFacultyData, setNewArabicFacultyData] = useState([]);
  const handleArabicFacultyMember = () => {
    const dummyData = [
      ...arabicFacultyData,
      {
        name: "",
        description: "",
        image: "",
        new: true,
      },
    ];
    setArabicFacultyData(dummyData);

    const newRecord = [
      ...newArabicFacultyData,
      {
        name: "",
        description: "",
        image: "",
        new: true,
      },
    ];
    setNewArabicFacultyData(newRecord);
  };

  ///////////////////// non  arabic member /////////////////////
  const [nonArabicFacultyData, setNonArabicFacultyData] = useState([]);
  const [newNonArabicFacultyData, setNewNonArabicFacultyData] = useState([]);
  const handleNonArabicFacultyMember = ({ data }) => {
    const dummyData = [
      ...nonArabicFacultyData,
      {
        name: "",
        description: "",
        image: "",
        new: true,
      },
    ];
    setNonArabicFacultyData(dummyData);

    const newRecord = [
      ...newNonArabicFacultyData,
      {
        name: "",
        description: "",
        image: "",
        new: true,
      },
    ];
    setNewNonArabicFacultyData(newRecord);
  };

  const [facultyEditorHtml, setFacultyEditorHtml] = useState("");

  const handleFacultyTextChange = (html) => {
    setFacultyEditorHtml(html);
  };
  const [loading, setLoading] = useState(true);
  const [aboutLoading, setAboutLoading] = useState(false);
  const [arabicLoading, setArabicLoading] = useState(false);
  const [nonArabicLoading, setNonArabicLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [errors, setErrors] = useState({});

  ///////////////////////////////////////////////////////////////////////////////////////////////
  ///                  Use Effect
  ////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    const arabicArray = [];
    const nonArabicArray = [];
    setLoading(true);

    const init = async () => {
      await API.get("/admin/faculty")
        .then((res) => {
          res.data.forEach((element) => {
            if (element.scholar === "arabic") {
              arabicArray.push(element);
            }
            if (element.scholar === "nonArabic") {
              nonArabicArray.push(element);
            }
          });
          setArabicFacultyData(arabicArray);
          setNonArabicFacultyData(nonArabicArray);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    };

    init();
    const about = async () => {
      await API.get("/admin/faculty/about")
        .then((res) => {
          setFacultyEditorHtml(res.data.about);
        })
        .catch((err) => {
          setLoading(false);
        });
    };

    about();
  }, [refresh]);

  // create new faculties
  const handleCreateNewFaculty = async (scholar) => {
    setLoading(true);

    scholar === 1 ? setArabicLoading(true) : setNonArabicLoading(true);

    let dataArray =
      scholar === 1 ? [...newArabicFacultyData] : [...newNonArabicFacultyData];

    if (dataArray.length > 0) {
      const formData = new FormData();
      dataArray.forEach((element, i) => {
        formData.append(`scholar[${i}][name]`, element.name);
        formData.append(`scholar[${i}][description]`, element.description);
        formData.append(`scholar[${i}][image]`, element.image);
        formData.append(`scholar[${i}][scholar]`, scholar);
      });

      await API.post("/admin/faculty/create", formData)
        .then((res) => {
          scholar === 1
            ? setNewArabicFacultyData([])
            : setNewArabicFacultyData([]);
          message.success("Faculty Created Successfully", 3);
          setErrors({});
          scholar === 1 ? setArabicLoading(false) : setNonArabicLoading(false);
          setRefresh(!refresh);
        })
        .catch((e) => {
          if (e.response.status === 400) {
            setErrors(e.response.data);
            message.error("Field is required");

            // error for only image size must not be greater than 5 MB.
            if (e.response.data["scholar.0.image"]) {
              if (e.response.data["scholar.0.image"][0].includes("5120")) {
                message.error("The image must not be greater than 5 MB.");
              }
            }
          }
          scholar === 1 ? setArabicLoading(false) : setNonArabicLoading(false);
          setLoading(false);
        });
    }
  };

  const handleSaveAboutUs = async () => {
    setAboutLoading(true);
    const formData = new FormData();
    formData.append("about", facultyEditorHtml);

    await API.post("/admin/faculty/about/update", formData)
      .then((res) => {
        message.success("About Section Updated Successfully", 3);
        setAboutLoading(false);
      })
      .catch((err) => {
        setAboutLoading(false);
      });
  };

  return (
    <div className="pages-container">
      <div className="content-wrapper">
        <Card
          bordered={false}
          className="p-0 m-0 position-static"
          loading={loading}
        >
          {/* Faculties text Area */}

          <Card loading={aboutLoading}>
            <div className="bg-content-container">
              <div className="fw-600 mb-2">{trans.Faculities}</div>
              <Editor
                key="facultiesEditorHtml"
                editorHtml={facultyEditorHtml}
                setEditorHtml={setFacultyEditorHtml}
                handleChange={handleFacultyTextChange}
              />
              <div className="d-flex justify-content-end save-section">
                {/* save changes */}
                <div
                  className="btn-save-section fw-500"
                  onClick={handleSaveAboutUs}
                >
                  <span>{trans.Savesection}</span>
                </div>
              </div>
            </div>
          </Card>

          {/* /////////////////// add Arabic scholars /////////////////// */}
          <Card
            bordered={false}
            className="p-0 m-0 position-static"
            loading={arabicLoading}
          >
            <div className="position-relative mb-5">
              <div className="fw-600 mb-2">
                {trans.ScholarsforArabicCourses}
              </div>
              {arabicFacultyData.length > 0 &&
                arabicFacultyData.map((item, i) => {
                  return (
                    <AddScholars
                      key={i}
                      index={i}
                      id={1}
                      data={item}
                      name="arabic-faculty"
                      originalData={arabicFacultyData}
                      setData={setArabicFacultyData}
                      newData={newArabicFacultyData}
                      setNewData={setNewArabicFacultyData}
                      setLoading={setArabicLoading}
                      refresh={refresh}
                      setRefresh={setRefresh}
                      setMainLoading={setLoading}
                      errors={errors}
                      setErrors={setErrors}
                    />
                  );
                })}
              {/* add another */}
              {newArabicFacultyData.length === 0 &&
                <div>
                  <div
                    className="faculty-add-another"
                    onClick={handleArabicFacultyMember}
                  >
                    <span className="f-12 fw-500">
                      {trans.Addanother}
                      <span className="f-20 fw-500 align-middle">+</span>
                    </span>
                  </div>
                </div>
              }

              {/* save changes */}
              {newArabicFacultyData.length > 0 && (
                <div style={{ position: "relative", zIndex: 99 }}>
                  <Button
                    onClick={() => handleCreateNewFaculty(1)}
                    className="btn-save-changes"
                    type="primary"
                  >
                    {trans.Save}
                  </Button>
                </div>
              )}
            </div>
          </Card>

          {/* ////////////////////// end */}

          {/* /////////////////// non Arabic scholars /////////////////// */}
          <Card
            bordered={false}
            className="p-0 m-0 position-static"
            loading={nonArabicLoading}
          >
            <>
              <div className="position-relative pt-5">
                <div className="fw-600 mb-2">
                  {trans.ScholarsfornonArabicCourses}
                </div>

                {nonArabicFacultyData.length > 0 &&
                  nonArabicFacultyData.map((item, i) => {
                    return (
                      <AddScholars
                        key={i}
                        index={i}
                        id={2}
                        data={item}
                        originalData={nonArabicFacultyData}
                        name="non-arabic-faculty"
                        setData={setNonArabicFacultyData}
                        newData={newNonArabicFacultyData}
                        setNewData={setNewNonArabicFacultyData}
                        setLoading={setNonArabicLoading}
                        refresh={refresh}
                        setRefresh={setRefresh}
                        setMainLoading={setLoading}
                      />
                    );
                  })}

                {/* add another */}
                {newNonArabicFacultyData.length === 0 &&
                  <div>
                    <div
                      className="faculty-add-another"
                      onClick={handleNonArabicFacultyMember}
                    >
                      <span className="f-12 fw-500">
                        {trans.Addanother}
                        <span className="f-20 fw-500 align-middle">+</span>
                      </span>
                    </div>
                  </div>
                }
              </div>
              {/* ////////////////////// end */}
              {/* save changes */}
              {newNonArabicFacultyData.length > 0 && (
                <Button
                  onClick={() => handleCreateNewFaculty(2)}
                  className="btn-save-changes"
                  type="primary"
                >
                  {trans.Save}
                </Button>
              )}
            </>
          </Card>
        </Card>
      </div>
    </div>
  );
};

export default Faculties;

const AddScholars = ({
  data,
  index,
  name,
  originalData,
  setData,
  newData,
  setNewData,
  refresh,
  setRefresh,
  setMainLoading,
  id,
  errors,
  setErrors,
}) => {
  const [currentImageObject, setCurrentImageObject] = useState({ image: "" });
  const [loading, setLoading] = useState(false);
  const handleNewScholarsDelete = (index) => {
    const dummyData = originalData.filter((item, i) => {
      return index !== i;
    });
    setData(dummyData);
    let newIndex = originalData.length - 1 - index;
    let newRecord = [];
    newRecord = newData.filter((item, i) => {
      return newIndex !== i;
    });
    setNewData(newRecord);
  };

  // delete record
  const handleScholarsDelete = async () => {
    setMainLoading(true);

    await API.post(`/admin/faculty/delete/${data.id}`)
      .then((res) => {
        message.success("Faculty Deleted Successfully");
        setRefresh(!refresh);
        setMainLoading(false);
      })
      .catch((err) => {
        message.error("Something went wrong, please try again", 3);
        setMainLoading(false);
      });
  };

  const [edit, setEdit] = useState(false);
  const inputReplace = React.createRef();
  const [isActionShown, setIsActionShown] = useState(false);

  const handleRemove = (index) => {
    const UpdatedData = originalData.map((item, i) => {
      let temp = item;
      if (i === index) temp.image = "";
      return temp;
    });
    setData(UpdatedData);
  };

  const handleReplace = () => {
    inputReplace.current.click();
  };

  const handleChange = (e) => {
    setCurrentImageObject({ image: e.target.files[0] });

    const dummyData = [...originalData];
    dummyData[index].image = URL.createObjectURL(e.target.files[0]);
    setData(dummyData);

    if (newData.length + index - originalData.length >= 0) {
      const dummyNewData = [...newData];
      dummyNewData[newData.length + index - originalData.length].image =
        e.target.files[0];
      setNewData(dummyNewData);
    }
  };

  // update record
  const handleFacultySave = async (values) => {
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("description", values.description);
    formData.append("scholar", data.scholar);
    currentImageObject.image === ""
      ? formData.append("image", data.image)
      : formData.append("image", currentImageObject.image);

    await API.post(`/admin/faculty/update/${data.id}`, formData)
      .then((res) => {
        message.success("Faculty Updated Successfully", 3);
        setErrors({});
        setLoading(false);
        setEdit(false);
      })
      .catch((e) => {
        if (e.response.status === 400) {
          setErrors(e.response.data);
          message.error("Field is required");

          // error for only image size must not be greater than 5 MB.
          if (e.response.data["image"]) {
            if (e.response.data["image"][0].includes("5120")) {
              message.error("The image must not be greater than 5 MB.");
            }
          }
        }
        setLoading(false);
      });
  };

  const handleInputValueChange = ({ target }) => {
    if (newData.length + index - originalData.length >= 0) {
      const dummyNewData = [...newData];
      dummyNewData[newData.length + index - originalData.length][target.name] =
        target.value;
      setNewData(dummyNewData);
    }
  };

  return (
    <div className="bg-content-container faculty-container">
      <Card
        bordered={false}
        className="p-0 m-0 position-static"
        loading={loading}
      >
        <Form onFinish={handleFacultySave}>
          {/* edit action */}
          <div style={{ height: 10 }} className="mb-2">
            {edit ? (
              <div className="edit-action icon-container d-block mr-2">
                <span
                  className="f-12 fw-400 cancel cursor-pointer"
                  onClick={() => setEdit(false)}
                >
                  {trans.Cancel}
                </span>

                <span className="save">
                  <Button type="" htmlType="submit">
                    {trans.Save}
                  </Button>
                </span>
              </div>
            ) : (
              <div className="icon-container">
                {!data.new && (
                  <span onClick={() => setEdit(true)}>
                    <EditIcon className="b-icon-edit cursor-pointer" />
                  </span>
                )}
                <CloseIcon
                  className="b-icon-close cursor-pointer"
                  onClick={() => {
                    data.new
                      ? handleNewScholarsDelete(index)
                      : handleScholarsDelete(index);
                  }}
                />
              </div>
            )}
          </div>
          <div className=" d-flex justify-content-between">
            <div>
              <div className="accordion-detail ">
                <div className="f-12 fw-500 faculty-heading">{trans.Name}</div>
                <Form.Item name="name" initialValue={data.name}>
                  <Input
                    name="name"
                    onChange={handleInputValueChange}
                    disabled={edit || data.new ? "" : "disabled"}
                    type="text"
                    placeholder={trans.Typehere}
                  />
                </Form.Item>
              </div>
              <div className="accordion-detail  ">
                <div className="f-12 fw-500 faculty-heading">
                  {trans.Description}
                </div>
                <Form.Item name="description" initialValue={data.description}>
                  <Input
                    name="description"
                    onChange={handleInputValueChange}
                    disabled={edit || data.new ? "" : "disabled"}
                    type="text"
                    placeholder={trans.Typehere}
                  />
                </Form.Item>
              </div>
            </div>
            {/* image */}
            <div
              className={
                data.image !== "" && data.image
                  ? "future-image-upload-container faculty-image uploaded"
                  : "future-image-upload-container faculty-image"
              }
            >
              {/* medium images */}
              <div
                className="future-image-upload medium-img "
                onMouseEnter={() => setIsActionShown(true)}
                onMouseLeave={() => setIsActionShown(false)}
              >
                {isActionShown && (edit || data.new) && (
                  <div>
                    {/* image action */}
                    <div className="image-action-container">
                      <div className="image-action d-flex justify-content-center">
                        <div
                          className="f-9 fw-400 image-action-txt"
                          onClick={handleReplace}
                        >
                          {trans.Replace}
                        </div>
                        <div className="image-action-divider mx-2"></div>
                        <div
                          className="f-9 fw-400 image-action-txt"
                          onClick={() => handleRemove(index)}
                        >
                          {trans.Remove}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <label
                  className="faculty-image-box"
                  htmlFor={`${name}${index}`}
                >
                  {data.image !== "" && data.image ? (
                    <img src={data.image} alt="" />
                  ) : (
                    <div className="cursor-pointer">
                      <img src={ImageIcon} alt="" className="pl-1 pr-1" />
                      <span className="f-12 fw-400 pr-1">Upload Image</span>
                    </div>
                  )}

                  <input
                    disabled={edit || data.new ? "" : "disabled"}
                    ref={inputReplace}
                    type="file"
                    name="image1"
                    id={`${name}${index}`}
                    style={{ display: "none" }}
                    onChange={handleChange}
                  />
                </label>
              </div>
              <span className="float-right f-img-size f-12 fw-400">
                {trans.Maxsize} : 5MB
              </span>
            </div>
          </div>
        </Form>
      </Card>
    </div>
  );
};
