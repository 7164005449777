import {EyeInvisibleOutlined, EyeTwoTone} from "@ant-design/icons";
import {Input, Select, Form, message} from "antd";
import loginLogo from "../../assets/logo/slide_logo-KIU.png";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import trans from "./../../Languages/Languages";
import React from "react";
import {useUserContext} from "../../Context/UserContext";
import API from "../../API";
import {useHistory} from "react-router";

const Login = () => {
  const history = useHistory();
  const {login, isLogin} = useUserContext();

  if (isLogin()) {
    history.push("/");
  }

  const LanguageDropdownStyle = {
    width: 120,
    zIndex: 99999999,
  };

  const handleFinish = (values) => {
    
    const formData = new FormData();
    formData.append("email", values.login);
    formData.append("password", values.password);
    formData.append("college", values.language);

    API.post("/admin/user/login", formData)
      .then((res) => {
        login(res.data);
        window.location.reload();
      })
      .catch((err) => {
        message.error("something went wrong please try again");
      });
  };
  return (
    <div className='login-wrapper'>
      <Form onFinish={handleFinish} onFinishFailed='' initialValues={{language:'ar'}}>
        <div className='login-h'>
          <div className='text-center f-29 fw-600 login-heading'>
            {trans.KIUWebsiteAdminPanel}
          </div>
          <div className='text-center f-15 fw-400'>
            {trans.ManageYouWebsiteWithEase}
          </div>
        </div>
        <div className='login-container'>
          {/* logo */}
          <img src={loginLogo} alt='' />
          <div className='f-12 fw-400 login-lang-text'>{trans.Language}</div>
          <div>
            <Form.Item name='language'>
              <Select
                className='app-bar-select-option login-lang'
                dropdownStyle={LanguageDropdownStyle}
                style={LanguageDropdownStyle}
                suffixIcon={
                  <KeyboardArrowDownIcon
                    className='select-option-icon'
                    fontSize='small'
                  />
                }
              >
                <Select.Option key='1' value='ar'>
                  Arabic
                </Select.Option>
                <Select.Option key='2' value='en'>
                  English
                </Select.Option>
                <Select.Option key='3' value='ur'>
                  Urdu
                </Select.Option>
                <Select.Option key='4' value='fr'>
                  French
                </Select.Option>
              </Select>
            </Form.Item>
          </div>

          <div className='mb-15'>
            <label htmlFor=''>
              {trans.UserName} or {trans.Email}
            </label>
            <Form.Item name='login'>
              <Input className='login-input' type='text' />
            </Form.Item>
          </div>
          <div>
            <label htmlFor=''>{trans.Password}</label>
            <Form.Item name='password'>
              <Input.Password
                className='login-input'
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>
          </div>
          <div className='f-10 fw-400 login-forget-text float-right d-block'>
            {trans.ForgotPassword}?
          </div>
          <div>
            <button className='w-100 btn-login f-16 fw-500'>
              {trans.Login}
            </button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default Login;
