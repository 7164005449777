import React, { useState, useEffect } from "react";
import Editor from "../../Components/common/Editor";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import MuiAccordion from "@material-ui/core/Accordion";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import trans from "../../../Languages/Languages";
import API from "../../../API";
import FutureImages from "./../../Components/vision/FutureImages";
import ImageIcon from "../../../assets/icons/image.svg";
import { Button, Card, message, Input, InputNumber, Form } from "antd";

const KIUVision = () => {
  message.config({
    top: 80,
  });
  const [visionEditorHtml, setVisionEditorHtml] = useState("");
  const [FutureEditorHtml, setFutureEditorHtml] = useState("");
  const [headerImage, setHeaderImage] = useState({ image: "" });
  const [kiuVisionData, setKiuVisionData] = useState({ image: "", vision: "" });
  const [futureData, setFutureData] = useState({});
  const [countersData, setCountersData] = useState([]);
  const [futureImages, setFutureImages] = useState([
    { image: "" },
    { image: "" },
    { image: "" },
  ]);

  const handleCarouselEditorHtml = (html) => {
    setVisionEditorHtml(html);
  };

  const handleFutureEditorChange = (html) => {
    setFutureEditorHtml(html);
  };
  const [expanded, setExpanded] = React.useState();
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const [loading, setLoading] = useState(false);
  const [visionLoading, setVisionLoading] = useState(false);
  const [countersLoading, setCountersLoading] = useState(false);
  const [futureLoading, setFutureLoading] = useState(false);
  const [errors, setErrors] = useState({});

  // Use Effect
  useEffect(() => {
    setLoading(true);
    const init = () => {
      API.get("/admin/vision")
        .then((res) => {
          setKiuVisionData(res.data);
          setVisionEditorHtml(res.data.vision ?? "<p></p>");
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    };

    init();
    const getCounters = () => {
      API.get("/admin/vision/counters")
        .then((res) => {
          setCountersData(res.data ?? []);
        })
        .catch((err) => {});
    };

    getCounters();
    const getFutureData = () => {
      API.get("/admin/future")
        .then((res) => {
          setFutureData(res.data);
          setFutureEditorHtml(res.data?.description ?? "");
        })
        .catch((err) => {});
    };
    getFutureData();
  }, []);

  // create and update vision
  const handleVisionSave = async () => {
    setVisionLoading(true);
    const formData = new FormData();

    formData.append("vision", visionEditorHtml);

    if (headerImage.image === "") {
      formData.append("vision_image", kiuVisionData.image);
    } else {
      formData.append("vision_image", headerImage.image);
    }

    if (kiuVisionData?.id) {
      formData.append("id", kiuVisionData.id);
    }

    await API.post("/admin/vision/create", formData)
      .then((res) => {
        if (res.status === 200) {
          setErrors({});
        }
        message.success(
          `Vision Section ${
            kiuVisionData?.id ? "Updated" : "Created"
          } Successfully`
        );
        setVisionLoading(false);
      })
      .catch((e) => {
        if (e.response.status === 400) {
          setErrors(e.response.data);
        }
        setVisionLoading(false);
      });
  };

  // update future
  const handleFutureSave = async () => {
    setFutureLoading(true);
    const formData = new FormData();
    formData.append("future", FutureEditorHtml);

    futureImages.forEach((element, i) => {
      if (element.image === "")
        formData.append(`data[${i}][image]`, futureData.images[i].image);
      else formData.append(`data[${i}][image]`, element.image);
      formData.append(`data[${i}][image_id]`, futureData.images[i].id);
    });

    await API.post(`/admin/future/update`, formData)
      .then((res) => {
        message.success("Future Section Updated Successfully");
        setFutureLoading(false);
      })
      .catch((e) => {
        if (e.response.status === 400) {
          setErrors(e.response.data);
          message.error("The image must not be greater than 5MB.");
        }
        setFutureLoading(false);
      });
  };

  // update counters
  const handleCountersFinish = async (values) => {
    setCountersLoading(true);
    const formData = new FormData();
    const dummyData = [...countersData];
    [1, 2, 3, 4].forEach((e, i) => {
      formData.append(`data[${i}][counter_id]`, countersData[i].id);
      formData.append(`data[${i}][title]`, values[`counterName${i}`]);
      formData.append(`data[${i}][count]`, values[`counterValue${i}`]);

      dummyData[i].title = values[`counterName${i}`];
      dummyData[i].count = values[`counterValue${i}`];
    });
    setCountersData(dummyData);

    await API.post(`/admin/vision/counters/update`, formData)
      .then((res) => {
        message.success("Counters Updated Successfully");
        if (res.status === 200) {
          setErrors({});
        }
        setCountersLoading(false);
      })
      .catch((e) => {
        if (e.response.status === 400) {
          setErrors(e.response.data);
          message.error("Field is required");
        }
        setCountersLoading(false);
      });
  };

  return (
    <div className="pages-container">
      <Card bordered={false} className="p-0" loading={loading}>
        <>
          <Card bordered={false} className="p-0" loading={visionLoading}>
            <div className="content-wrapper">
              <div className="bg-content-container">
                <div className="fw-600 h-heading">{trans.Image}</div>
                {/* header Upload Image */}
                <WideImageUpload
                  record={kiuVisionData}
                  headerImage={headerImage}
                  setHeaderImage={setHeaderImage}
                  setData={setKiuVisionData}
                />
                {errors?.vision_image && (
                  <span className="text-danger">{errors?.vision_image}</span>
                )}

                {/* end */}
                {/* carousel text */}
                <div className="fw-600 carousel-text-heading mb-2">
                  {trans.Vision}
                </div>
                <Editor
                  key="carouselKIUVision"
                  editorHtml={visionEditorHtml}
                  setEditorHtml={setVisionEditorHtml}
                  handleChange={handleCarouselEditorHtml}
                />
                {errors?.vision && (
                  <span className="text-danger">{errors?.vision}</span>
                )}

                <div className="d-flex justify-content-end save-section">
                  {/* save changes */}
                  <div
                    className="btn-save-section fw-500"
                    onClick={handleVisionSave}
                  >
                    <span>{trans.Savesection}</span>
                  </div>
                </div>
              </div>
            </div>
          </Card>
          <div className="content-wrapper">
            {/* Counters */}

            {countersData.length > 0 && (
              <Accordion square expanded={expanded} onChange={handleChange}>
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  <p className="f-14 fw-600 mb-0">
                    {trans.Counters}
                    <span>
                      <KeyboardArrowDownIcon className="down-arrow-accordion" />
                    </span>
                  </p>
                </AccordionSummary>
                <AccordionDetails className="accordion-detail a-detail">
                  <Card
                    bordered={false}
                    className="p-0"
                    loading={countersLoading}
                  >
                    <Form onFinish={handleCountersFinish}>
                      <div>
                        <table>
                          <thead>
                            <tr>
                              <td>{trans.Title}</td>
                              <td>{trans.Count}</td>
                            </tr>
                          </thead>
                          <tbody>
                            {[1, 2, 3, 4].map((item, index) => {
                              return (
                                <Counters
                                  key={index}
                                  index={index}
                                  errors={errors}
                                  data={countersData}
                                />
                              );
                            })}
                          </tbody>
                        </table>
                        <div className="d-flex justify-content-end save-section">
                          {/* save changes */}
                          <Button
                            htmlType="submit"
                            style={{ marginBottom: 23 }}
                            className="btn-save-section-no-Line fw-500 float-right"
                          >
                            <span>{trans.Savesection}</span>
                          </Button>
                        </div>
                      </div>
                    </Form>
                  </Card>
                </AccordionDetails>
              </Accordion>
            )}
            {/* end */}
            <Card bordered={false} className="p-0" loading={futureLoading}>
              <>
                {/* about us text Area */}

                <div className="bg-content-container">
                  <div className="fw-600 carousel-text-heading mb-2">
                    {trans.Future}
                  </div>
                  <Editor
                    key="Future"
                    editorHtml={FutureEditorHtml}
                    setEditorHtml={setFutureEditorHtml}
                    handleChange={handleFutureEditorChange}
                  />
                </div>
                {
                  ///////////////////////////////////////////////////////////////////////////////////////////////
                  ////////////                             Future Images
                  ///////////////////////////////////////////////////////////////////////////////////////////////}
                }
                <div className="vision-container">
                  <div className="bg-content-container">
                    <div className="d-flex justify-content-md-between">
                      {[1, 2, 3].map((item, index) => {
                        return (
                          <FutureImages
                            key={index}
                            data={futureData}
                            setData={setFutureData}
                            setFutureImages={setFutureImages}
                            futureImages={futureImages}
                            index={index}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>

                {/* save changes */}

                <Button
                  onClick={handleFutureSave}
                  className="btn-save-changes"
                  type="primary"
                  htmlType="submit"
                >
                  {trans.Save}
                </Button>
              </>
            </Card>
          </div>
        </>
      </Card>
    </div>
  );
};

export default KIUVision;
///////////////////////////////////////////////////////////////////////////////////////////////
////////////                             Wide image
///////////////////////////////////////////////////////////////////////////////////////////////

const WideImageUpload = ({ record, setHeaderImage, setData }) => {
  const [isActionShown, setIsActionShown] = useState(false);

  const handleChange = (e) => {
    setHeaderImage({ image: e.target.files[0] });

    const dummyData = { ...record };
    dummyData.image = URL.createObjectURL(e.target.files[0]);
    setData(dummyData);
  };
  const inputHeaderReplace = React.createRef();

  const handleReplace = () => {
    inputHeaderReplace.current.click();
  };

  const handleRemove = () => {
    setHeaderImage({ image: "" });
    const dummyData = { ...record };
    dummyData.image = "";
    setData(dummyData);
  };

  return (
    <div>
      <div
        className="h-image-upload-container"
        onMouseEnter={() => setIsActionShown(true)}
        onMouseLeave={() => setIsActionShown(false)}
      >
        <div
          className={
            record && record.image
              ? "h-image-upload medium-img h-uploaded"
              : "h-image-upload medium-img"
          }
          style={{ position: "relative" }}
        >
          {isActionShown && (
            <div>
              {/* image action */}
              <div className="image-action-container">
                <div className="image-action d-flex justify-content-center">
                  <div
                    className="f-9 fw-400 image-action-txt"
                    onClick={handleReplace}
                  >
                    {trans.Replace}
                  </div>
                  <div className="image-action-divider mx-2"></div>
                  <div
                    className="f-9 fw-400 image-action-txt"
                    onClick={handleRemove}
                  >
                    {trans.Remove}
                  </div>
                </div>
              </div>
            </div>
          )}

          <label className="image-box" htmlFor="image1">
            {record.image && record ? (
              <div
                className="wide-image-preview"
                style={{
                  background: `url(${record?.image})`,
                  height: 183,
                }}
              />
            ) : (
              <div>
                <img src={ImageIcon} alt="" className="px-2" />
                <span>Upload Image</span>
              </div>
            )}

            <input
              ref={inputHeaderReplace}
              type="file"
              name="image1"
              id="image1"
              style={{ display: "none" }}
              onChange={handleChange}
            />
          </label>
        </div>
        <span className="float-left f-img-size f-12 fw-400">
          {trans.Maxsize} : 5MB {trans.RecommendedResolution} : 1920 x 1080
        </span>
      </div>
      {/*/////////////////////////////////////////////////////////////////*/}
    </div>
  );
};

///////////////////////////////////////////////////////////////////////////////////////////////
///                  Material Accordion BoilerPlate
////////////////////////////////////////////////////////////////////////////////////////////////

const Accordion = withStyles({
  root: {
    // border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    fontWeight: 600,

    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
      border: "1px solid rgba(0, 0, 0, .125)",
      marginBottom: 10,
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "#FCFCFC",
    border: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: 16,
    minHeight: 40,

    "&$expanded": {
      border: 0,
      minHeight: 56,
      marginBottom: 0,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    backgroundColor: "#FCFCFC",
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const Counters = ({ index, data }) => {
  return (
    <tr key={index}>
      <td>
        <Form.Item
          name={`counterName${index}`}
          initialValue={data[index].title}
        >
          <Input placeholder={trans.Typehere} />
        </Form.Item>
      </td>
      <td>
        <span>
          <Form.Item
            name={`counterValue${index}`}
            initialValue={data[index].count}
          >
            <InputNumber placeholder="00" />
          </Form.Item>
        </span>
      </td>
    </tr>
  );
};
