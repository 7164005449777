import Editor from "../../Components/common/Editor";
import trans from "./../../../Languages/Languages";
import { useState } from "react";
import ImageIcon from "../../../assets/icons/image.svg";
import React, { useEffect } from "react";
import { Button, Card, message } from "antd";
import API from "../../../API";
import { useLocation } from "react-router";

const AboutDegree = () => {
  message.config({
    top: 100,
  });
  // states
  const currentDegreeID = window.location.hash.split("/")[3];
  const [editorHtml, setEditorHtml] = useState("");
  const [aboutDegreeData, setAboutDegreeData] = useState();
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [errors, setErrors] = useState({});

  const handleTextEditorChange = (html) => {
    setEditorHtml(html);
  };

  // Use Effect
  let location = useLocation();
  useEffect(() => {
    setLoading(true);
    const init = async () => {
      await API.get(`/admin/degree/${currentDegreeID}`)
        .then((res) => {
          setAboutDegreeData(res.data);
          setEditorHtml(res.data?.description ?? "");
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    };
    init();
  }, [refresh, currentDegreeID, location]);
  const [headerImage, setHeaderImage] = useState({ image: "" });

  // update record
  const handleUpdateAboutDegree = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("description", editorHtml);
    headerImage.image === ""
      ? formData.append("image", aboutDegreeData.image)
      : formData.append("image", headerImage.image);

    await API.post(`/admin/degree/about/update/${currentDegreeID}`, formData)
      .then((res) => {
        message.success("Updated Succsesfully");
        setErrors({});
        setRefresh(!refresh);
        setLoading(false);
      })
      .catch((e) => {
        if (e.response.status === 400) {
          setErrors(e.response.data);
        }
        setLoading(false);
      });
  };
  return (
    <div className="pages-container">
      <Card bordered={false} loading={loading}>
        {aboutDegreeData && (
          <>
            <div className="content-wrapper">
              <div className="fw-600 f-15 mb-2">{trans.Image}</div>
              {/* header Upload Image */}
              <WideImageUpload
                record={aboutDegreeData}
                headerImage={headerImage}
                setHeaderImage={setHeaderImage}
                setData={setAboutDegreeData}
              />
              {errors?.image && (
                <span className="text-danger">{errors?.image}</span>
              )}
              {/* end */}

              {/*  text */}

              <div className="bg-content-container mb-0">
                <div className="fw-600 fw-15 mb-2">{trans.text}</div>

                <Editor
                  key="aboutDegree"
                  editorHtml={editorHtml}
                  setEditorHtml={setEditorHtml}
                  handleChange={handleTextEditorChange}
                />
              </div>
            </div>
            {/* save changes */}
            <Button
              onClick={handleUpdateAboutDegree}
              className="btn-save-changes"
              type="primary"
              htmlType="submit"
            >
              {trans.Save}
            </Button>{" "}
          </>
        )}
      </Card>
    </div>
  );
};

export default AboutDegree;

///////////////////////////////////////////////////////////////////////////////////////////////
////////////                             Wide image
///////////////////////////////////////////////////////////////////////////////////////////////

const WideImageUpload = ({ record, setHeaderImage, setData }) => {
  const [isActionShown, setIsActionShown] = useState(false);

  const handleChange = (e) => {
    setHeaderImage({ image: e.target.files[0] });

    const dummyData = { ...record };
    dummyData.image = URL.createObjectURL(e.target.files[0]);
    setData(dummyData);
  };
  const inputHeaderReplace = React.createRef();

  const handleReplace = () => {
    inputHeaderReplace.current.click();
  };

  const handleRemove = () => {
    const dummyData = { ...record };
    dummyData.image = "";
    setHeaderImage("");
    setData(dummyData);
  };

  return (
    <div>
      <div
        className="h-image-upload-container"
        onMouseEnter={() => setIsActionShown(true)}
        onMouseLeave={() => setIsActionShown(false)}
      >
        <div
          className={
            record && record.image
              ? "h-image-upload medium-img h-uploaded"
              : "h-image-upload medium-img"
          }
          style={{ position: "relative" }}
        >
          {isActionShown && (
            <div>
              {/* image action */}
              <div className="image-action-container">
                <div className="image-action d-flex justify-content-center">
                  <div
                    className="f-9 fw-400 image-action-txt"
                    onClick={handleReplace}
                  >
                    {trans.Replace}
                  </div>
                  <div className="image-action-divider mx-2"></div>
                  <div
                    className="f-9 fw-400 image-action-txt"
                    onClick={handleRemove}
                  >
                    {trans.Remove}
                  </div>
                </div>
              </div>
            </div>
          )}

          <label className="image-box" htmlFor="image1">
            {record && record.image ? (
              <>
                <div
                  className="images-background-config"
                  style={{
                    width: "100%",
                    height: "100%",
                    background: `url(${record.image})`,
                  }}
                ></div>
              </>
            ) : (
              <div className="cursor-pointer">
                <img src={ImageIcon} alt="" className="px-2" />
                <span>Upload Image</span>
              </div>
            )}

            <input
              ref={inputHeaderReplace}
              type="file"
              name="image1"
              id="image1"
              style={{ display: "none" }}
              onChange={handleChange}
            />
          </label>
        </div>
        <span className="float-left f-img-size f-12 fw-400">
          {trans.Maxsize} : 5MB {trans.RecommendedResolution} : 1920 x 1080
        </span>
      </div>
      {/*/////////////////////////////////////////////////////////////////*/}
    </div>
  );
};
