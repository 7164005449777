import Editor from "../../Components/common/Editor";
import React, {useEffect, useState} from "react";
import {Form, Input, Button, message} from "antd";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import trans from "./../../../Languages/Languages";
import API from "../../../API";

const Subjects = ({
  data,
  index,
  subjectsData,
  setLoading,
  refresh,
  setRefresh,
  newSubjectsData,
  setNewSubjectsData,
  catalogueIndex,
  setCourseCatalogueData,
  courseCatalogueData,
}) => {
  const [descriptionEditorHtml, setDescriptionEditorHtml] = useState("");
  const handleDescriptionEditorChange = (html) => {
    setDescriptionEditorHtml(html);
    if (newSubjectsData.length > 0) {
      const dummyNewData = [...newSubjectsData];
      dummyNewData[
        newSubjectsData.length + index - subjectsData.length
      ].description = html;
      setNewSubjectsData(dummyNewData);
    }
  };
  const [objectivesEditorHtml, setObjectivesEditorHtml] = useState("");
  const handleObjectivesEditorChange = (html) => {
    setObjectivesEditorHtml(html);
    if (newSubjectsData.length > 0) {
      const dummyNewData = [...newSubjectsData];
      dummyNewData[
        newSubjectsData.length + index - subjectsData.length
      ].objectives = html;
      setNewSubjectsData(dummyNewData);
    }
  };
  const [topicsEditorHtml, setTopicsEditorHtml] = useState("");
  const handleTopicsEditorChange = (html) => {
    setTopicsEditorHtml(html);
    if (newSubjectsData.length > 0) {
      const dummyNewData = [...newSubjectsData];
      dummyNewData[
        newSubjectsData.length + index - subjectsData.length
      ].topics = html;
      setNewSubjectsData(dummyNewData);
    }
  };

  const [edit, setEdit] = useState(false);

  ///////////////////////////////////////////////////////////////////////////////////////////////
  ///                            use effect
  ////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    setDescriptionEditorHtml(data.description);
    setObjectivesEditorHtml(data.objectives);
    setTopicsEditorHtml(data.topics);
  }, [data]);

  ///////////////////////////////////////////////////////////////////////////////////////////////
  ///                             delete new subject
  ////////////////////////////////////////////////////////////////////////////////////////////////
  const handleRemoveNewSubjects = (index) => {
    const dummyData = [...courseCatalogueData];
    let newCourses = dummyData[catalogueIndex].courses.filter((item, i) => {
      return index !== i;
    });
    dummyData[catalogueIndex].courses = newCourses;
    setCourseCatalogueData(dummyData);
    let newIndex = newSubjectsData.length + index - subjectsData.length;
    let newRecord = [];
    newRecord = newSubjectsData.filter((item, i) => {
      return newIndex !== i;
    });
    setNewSubjectsData(newRecord);
  };

  const handleRemoveSubjects = async (id) => {
    await API.post(`/admin/category/course/delete/${id}`)
      .then((res) => {
        setRefresh(!refresh);
        message.success("Subject Deleted Successfully");
      })
      .catch((err) => {
        message.error("Something went wrong, please try again");
      });
  };

  const handleInputValueChange = ({target}) => {
    if (newSubjectsData.length + index - subjectsData.length >= 0) {
      const dummyNewData = [...newSubjectsData];
      dummyNewData[newSubjectsData.length + index - subjectsData.length][
        target.name
      ] = target.value;
      setNewSubjectsData(dummyNewData);
    }
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////
  ///                             update subject
  ////////////////////////////////////////////////////////////////////////////////////////////////
  const handleSave = async (values) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("course_title", values.courseTitle);
    formData.append("credit_hours", values.creditHours);
    formData.append("instructor", values.instructor);
    formData.append("required", values.required);
    formData.append("description", descriptionEditorHtml);
    formData.append("objectives", objectivesEditorHtml);
    formData.append("topics", topicsEditorHtml);

    await API.post(`/admin/category/course/update/${data.id}`, formData)
      .then((res) => {
        setRefresh(!refresh);
        setEdit(false);
        setLoading(false);
        message.success("Updated Successfully", 3);
      })
      .catch((err) => {
        message.error("All field must be filled");
        setLoading(false);
      });
  };
  return (
    <>
      <div className='bg-content-container b-container'>
        {/* edit action */}
        <Form onFinish={handleSave}>
          <div style={{height: 10}}>
            {edit ? (
              <div className='edit-action icon-container d-block mr-1'>
                <span
                  className='f-12 fw-400 cancel '
                  onClick={() => setEdit(false)}
                >
                  {trans.Cancel}
                </span>

                <span className='save'>
                  <Button type='' htmlType='submit'>
                    {trans.Save}
                  </Button>
                </span>
              </div>
            ) : (
              <div className='icon-container'>
                {!data.new && (
                  <span onClick={() => setEdit(true)}>
                    <EditIcon className='b-icon-edit cursor-pointer' />
                  </span>
                )}
                <CloseIcon
                  className='b-icon-close cursor-pointer'
                  onClick={() => {
                    data.new
                      ? handleRemoveNewSubjects(index)
                      : handleRemoveSubjects(data.id);
                  }}
                />
              </div>
            )}
          </div>

          <div className='d-flex justify-content-lg-start'>
            <div className='accordion-detail course-catalogue-input'>
              <div className='f-12 fw-500 input-heading'>
                {trans.Coursetitle}
              </div>
              <Form.Item name='courseTitle' initialValue={data.course_title}>
                <Input
                  onChange={handleInputValueChange}
                  name='course_title'
                  disabled={edit || data.new ? "" : "disabled"}
                  placeholder={trans.Typehere}
                />
              </Form.Item>
            </div>
            <div className='accordion-detail course-catalogue-credit-hours-input'>
              <div className='f-12 fw-500 input-heading'>
                {trans.Credithours}
              </div>
              <Form.Item name='creditHours' initialValue={data.credit_hours}>
                <Input
                  name='credit_hours'
                  onChange={handleInputValueChange}
                  disabled={edit || data.new ? "" : "disabled"}
                  placeholder={trans.Typehere}
                />
              </Form.Item>
            </div>
          </div>
          <div className='accordion-detail '>
            <div className='f-12 fw-500 input-heading'>{trans.Instructor}</div>
            <Form.Item name='instructor' initialValue={data.instructor}>
              <Input
                name='instructor'
                onChange={handleInputValueChange}
                disabled={edit || data.new ? "" : "disabled"}
                placeholder={trans.Typehere}
                className='w-100'
              />
            </Form.Item>
          </div>
          <div className='accordion-detail '>
            <div className='f-12 fw-500 input-heading'>{trans.Required}</div>
            <Form.Item name='required' initialValue={data.required}>
              <Input
                name='required'
                onChange={handleInputValueChange}
                disabled={edit || data.new ? "" : "disabled"}
                placeholder={trans.Typehere}
                className='w-100'
              />
            </Form.Item>
          </div>
          {/* description */}

          <div className='f-12 fw-500 input-heading'>{trans.Description}</div>
          <div className={edit || data.new ? "" : "quill-readonly"}>
            <Editor
              key='descriptionCourseCartegory'
              editorHtml={descriptionEditorHtml}
              setEditorHtml={setDescriptionEditorHtml}
              handleChange={handleDescriptionEditorChange}
            />
          </div>
          {/* objectives */}

          <div className='f-12 fw-500  input-heading'>{trans.Objectives}</div>
          <div className={edit || data.new ? "" : "quill-readonly"}>
            <Editor
              disabled={true}
              key=' objectuives'
              editorHtml={objectivesEditorHtml}
              setEditorHtml={setObjectivesEditorHtml}
              handleChange={handleObjectivesEditorChange}
            />
          </div>
          {/* Topics to cover */}

          <div className='f-12 fw-500 input-heading'>{trans.Topicstocover}</div>
          <div className={edit || data.new ? "" : "quill-readonly"}>
            <Editor
              key='topics'
              editorHtml={topicsEditorHtml}
              setEditorHtml={setTopicsEditorHtml}
              handleChange={handleTopicsEditorChange}
            />
          </div>
        </Form>
      </div>
    </>
  );
};
export default Subjects;
