export const getDegrees = (originalData, pages, setPages) => {
  const data = [...pages];
  let degrees = [];
  originalData.forEach((element, i) => {
    degrees.push({
      name: element.english_name,
      id: element.id,
      open: false,
    });
  });
  data[2].children = degrees;
  setPages(data);
};
