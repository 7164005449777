import Editor from "../../Components/common/Editor";
import React, {useEffect, useState} from "react";
import {Card, Button, message} from "antd";
import trans from "./../../../Languages/Languages";
import API from "../../../API";
import CourseCatalogue from "./../../Components/courses/CourseCatalogue";

const CoursesCategories = () => {
  const [courseCategoryEditorHtml, setCourseCategoryEditorHtml] = useState("");
  const handleCourseCategoryEditorChange = (html) => {
    setCourseCategoryEditorHtml(html);
  };
  const [courseCatalogueData, setCourseCatalogueData] = useState([]);
  const [newCatalogueData, setNewCatalogueData] = useState([]);
  const handleAnotherCourseCatalogueAdded = () => {
    const dummyData = [
      ...courseCatalogueData,
      {image: "", title: "", courses: [], new: true},
    ];
    setCourseCatalogueData(dummyData);

    const newRecord = [
      ...newCatalogueData,
      {image: "", courses: [], title: "", new: true},
    ];
    setNewCatalogueData(newRecord);
  };

  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(true);

  ///////////////////////////////////////////////////////////////////////////////////////////////
  ///                  Use Effect
  ////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    setLoading(true);

    const init = async () => {
      await API.get("/admin/category")
        .then((res) => {
          setCourseCatalogueData(res.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    };

    init();

    API.get(`/admin/category/about`)
      .then((res) => {
        setCourseCategoryEditorHtml(res?.data?.about);
      })
      .catch((err) => {});
  }, [refresh]);

  ///////////////////////////////////////////////////////////////////////////////////////////////
  ///                             create new records
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const handleCreateNew = async () => {
    setLoading(true);
    if (newCatalogueData.length > 0) {
      const formData = new FormData();
      newCatalogueData.forEach((element, i) => {
        formData.append(`data[${i}][title]`, element.title);
        formData.append(`data[${i}][image]`, element.image);
      });

      await API.post("/admin/category/create", formData)
        .then((res) => {
          setNewCatalogueData([]);
          setLoading(false);
          setRefresh(!refresh);
          message.success("Created Successfully");
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const handleCategoriesAbout = () => {
    const formData = new FormData();
    formData.append("about", courseCategoryEditorHtml);

    API.post(`/admin/category/about/update`, formData)
      .then((res) => {
        message.success("Updated successfully");
      })
      .catch((err) => {});
  };

  return (
    <div className='pages-container'>
      <div className='bg-content-container'>
        <Card loading={loading} bordered={false}>
          <div className='content-wrapper'>
            <div className='fw-600 mb-2'>{trans.Coursecategories}</div>
            <Editor
              key='courseCategories1'
              editorHtml={courseCategoryEditorHtml}
              setEditorHtml={setCourseCategoryEditorHtml}
              handleChange={handleCourseCategoryEditorChange}
            />
            <div className='d-flex justify-content-end save-section'>
              {/* save changes */}
              <div
                onClick={handleCategoriesAbout}
                className='btn-save-section fw-500'
              >
                <span>{trans.Savesection}</span>
              </div>
            </div>
            {courseCatalogueData.map((item, i) => {
              return (
                <CourseCatalogue
                  key={i}
                  index={i}
                  data={item}
                  courseCatalogueData={courseCatalogueData}
                  setLoading={setLoading}
                  refresh={refresh}
                  setRefresh={setRefresh}
                  setCourseCatalogueData={setCourseCatalogueData}
                  newCatalogueData={newCatalogueData}
                  setNewCatalogueData={setNewCatalogueData}
                />
              );
            })}
          </div>
          <div onClick={handleAnotherCourseCatalogueAdded}>
            <span className='f-12 fw-500'>
              <span className='mr-2 cursor-pointer'> {trans.AddNewSection}</span>
              <span className='f-18 fw-500 align-middle'>+</span>
            </span>
          </div>

          {newCatalogueData.length > 0 && (
            <Button
              onClick={handleCreateNew}
              className='btn-save-changes'
              type='primary'
              htmlType='submit'
            >
              {trans.Save}
            </Button>
          )}
        </Card>
      </div>
    </div>
  );
};

export default CoursesCategories;
