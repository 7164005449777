import Editor from "../../Components/common/Editor";
import ImageIcon from "../../../assets/icons/image.svg";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import { Button, Form, Input, Card, message } from "antd";
import { useState, useEffect } from "react";
import trans from "../../../Languages/Languages";
import React from "react";
import API from "../../../API";

const CollegesInstitutes = () => {
  const [collegeData, setCollegeData] = useState([]);
  const [newData, setNewData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [editorHtml, setEditorHtml] = useState("");
  const [loading, setLoading] = useState(true);

  const handleAddCollege = () => {
    const dummyData = [
      ...collegeData,
      { name: "", description: "", image: "", new: true },
    ];
    setCollegeData(dummyData);

    const newDummyData = [
      ...newData,
      { name: "", description: "", image: "", new: true },
    ];
    setNewData(newDummyData);
  };

  const handleTextEditorChange = (html) => {
    setEditorHtml(html);
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////
  ///                  Use Effect
  ////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    setLoading(true);

    const init = async () => {
      await API.get("/admin/institutes")
        .then((res) => {
          setCollegeData(res.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    };

    init();
    const about = async () => {
      await API.get("/admin/institutes/about")
        .then((res) => {
          setEditorHtml(res.data.about);
        })
        .catch((err) => {
          setLoading(false);
        });
    };

    about();
  }, [refresh]);
  ///////////////////////////////////////////////////////////////////////////////////////////////
  ///                             create new records
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const handleCreateNew = async () => {
    setLoading(true);
    if (newData.length > 0) {
      const formData = new FormData();
      newData.forEach((element, i) => {
        formData.append(`institute[${i}][name]`, element.name);
        formData.append(`institute[${i}][description]`, element.description);
        formData.append(`institute[${i}][image]`, element.image);
      });

      await API.post("/admin/institutes/create", formData)
        .then((res) => {
          setNewData([]);
          setLoading(false);
          setRefresh(!refresh);
          message.success("College Created Successfully");
        })
        .catch((e) => {
          if (e.response.status === 400) {
            message.error("Field is required");

            // error for only image size must not be greater than 5 MB.
            if (e.response.data["institute.0.image"]) {
              if (e.response.data["institute.0.image"][0].includes("5120")) {
                message.error("The image must not be greater than 5 MB.");
              }
            }
          }
          setLoading(false);
        });
    }
  };

  const handleSaveAboutUs = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("about", editorHtml);

    await API.post("/admin/institutes/about/update", formData)
      .then((res) => {
        setRefresh(!refresh);
        setLoading(false);
        message.success("Section Updated Successfully", 3);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <div className="pages-container">
      <div className="content-wrapper">
        <Card bordered={false} className="p-0" loading={loading}>
          {/* Faculties text Area */}
          <Card bordered={false} className="p-0" loading={loading}>
            <div className="bg-content-container">
              <div className="fw-600 mb-2">{trans.text}</div>
              <Editor
                key="college"
                editorHtml={editorHtml}
                setEditorHtml={setEditorHtml}
                handleChange={handleTextEditorChange}
              />
              <div className="d-flex justify-content-end save-section">
                {/* save changes */}
                <div
                  className="btn-save-section fw-500"
                  onClick={handleSaveAboutUs}
                >
                  <span>{trans.Savesection}</span>
                </div>
              </div>
            </div>
          </Card>
          {/* /////////////////// add college /////////////////// */}
          <div className="position-relative mb-5">
            <div className="fw-600 mb-2">{trans.Colleges}</div>

            {collegeData.length > 0 &&
              collegeData.map((item, i) => {
                return (
                  <AddCollege
                    key={i}
                    index={i}
                    data={item}
                    setData={setCollegeData}
                    originalData={collegeData}
                    setNewData={setNewData}
                    newData={newData}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    setLoading={setLoading}
                  />
                );
              })}

            {/* add another */}
            <div>
              <div className="faculty-add-another" onClick={handleAddCollege}>
                <span className="f-12 fw-500">
                  {trans.Addanother}{" "}
                  <span className="f-20 fw-500 align-middle">+</span>
                </span>
              </div>
            </div>

            {/* save changes */}

            {newData.length > 0 && (
              <Button
                onClick={handleCreateNew}
                className="btn-save-changes"
                type="primary"
                htmlType="submit"
              >
                {trans.Save}
              </Button>
            )}
          </div>
        </Card>
      </div>
    </div>
  );
};

export default CollegesInstitutes;

const AddCollege = ({
  data,
  index,
  setData,
  originalData,
  newData,
  setNewData,
  setLoading,
  refresh,
  setRefresh,
}) => {
  const handleNewCollegeDelete = (index) => {
    const dummyData = originalData.filter((item, i) => {
      return index !== i;
    });
    setData(dummyData);

    let newIndex = newData.length + index - originalData.length;
    let newRecord = [];
    newRecord = newData.filter((item, i) => {
      return newIndex !== i;
    });
    setNewData(newRecord);
  };

  const [edit, setEdit] = useState(false);
  const [currentImageObject, setCurrentImageObject] = useState({ image: "" });
  const handleChange = (e) => {
    setCurrentImageObject({ image: e.target.files[0] });

    const dummyData = [...originalData];
    dummyData[index].image = URL.createObjectURL(e.target.files[0]);
    setData(dummyData);
    if (newData.length + index - originalData.length >= 0) {
      const dummyNewData = [...newData];
      dummyNewData[newData.length + index - originalData.length].image =
        e.target.files[0];
      setNewData(dummyNewData);
    }
  };

  // update record
  const handleSectionSave = async (values) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("description", values.description);
    currentImageObject.image === ""
      ? formData.append("image", data.image)
      : formData.append("image", currentImageObject.image);

    await API.post(`/admin/institutes/update/${data.id}`, formData)
      .then((res) => {
        setRefresh(!refresh);
        setLoading(false);
        message.success("College Updated Successfully", 3);
      })
      .catch((e) => {
        setLoading(false);
        message.error("Field is required.");

        // error for only image size must not be greater than 5 MB.
        if (e.response.data["image"]) {
          if (e.response.data["image"][0].includes("5120")) {
            message.error("The image must not be greater than 5 MB.");
          }
        }
      });
  };
  const inputReplace = React.createRef();

  const [isActionShown, setIsActionShown] = useState(false);

  // remove image
  const handleRemove = (index) => {
    const UpdatedData = originalData.map((item, i) => {
      let temp = item;
      if (i === index) temp.image = "";
      return temp;
    });
    setData(UpdatedData);
  };

  // replace image
  const handleReplace = () => {
    inputReplace.current.click();
  };

  const handleInputValueChange = ({ target }) => {
    if (newData.length + index - originalData.length >= 0) {
      const dummyNewData = [...newData];
      dummyNewData[newData.length + index - originalData.length][target.name] =
        target.value;
      setNewData(dummyNewData);
    }
  };

  // delete record
  const handleCollegeDelete = async () => {
    setLoading(true);

    await API.post(`/admin/institutes/delete/${data.id}`)
      .then((res) => {
        message.success("College Deleted Successfully", 3);
        setRefresh(!refresh);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  return (
    <div className="bg-content-container faculty-container college-container">
      <Form onFinish={handleSectionSave}>
        {/* edit action */}
        <div style={{ height: 10 }}>
          {edit ? (
            <div className="edit-action icon-container d-block mr-1">
              <span
                className="f-12 fw-400 cancel cursor-pointer"
                onClick={() => setEdit(false)}
              >
                {trans.Cancel}
              </span>

              <span className="save">
                <Button htmlType="submit">{trans.Save}</Button>
              </span>
            </div>
          ) : (
            <div className="icon-container">
              {!data.new && (
                <span onClick={() => setEdit(true)}>
                  <EditIcon className="b-icon-edit cursor-pointer" />
                </span>
              )}
              <CloseIcon
                className="b-icon-close cursor-pointer"
                onClick={() => {
                  data.new
                    ? handleNewCollegeDelete(index)
                    : handleCollegeDelete(index);
                }}
              />
            </div>
          )}
        </div>

        <div className=" d-flex justify-content-between">
          <div>
            <div className="accordion-detail ">
              <div className="f-12 fw-500 faculty-heading">{trans.Name}</div>
              <Form.Item name="name" initialValue={data.name}>
                <Input
                  onChange={handleInputValueChange}
                  disabled={edit || data.new ? "" : "disabled"}
                  type="text"
                  name="name"
                  placeholder={trans.Typehere}
                />
              </Form.Item>
            </div>
            <div className="accordion-detail  college-text-area">
              <div className="f-12 fw-500 faculty-heading">
                {trans.Description}
              </div>
              <Form.Item name="description" initialValue={data.description}>
                <Input.TextArea
                  onChange={handleInputValueChange}
                  name="description"
                  disabled={edit || data.new ? "" : "disabled"}
                ></Input.TextArea>
              </Form.Item>
            </div>
          </div>
          {/* image */}
          <div
            className={
              data.image !== "" && data.image
                ? "future-image-upload-container faculty-image uploaded"
                : "future-image-upload-container faculty-image"
            }
          >
            {/* medium images */}
            <div
              className="future-image-upload medium-img"
              onMouseEnter={() => setIsActionShown(true)}
              onMouseLeave={() => setIsActionShown(false)}
            >
              {isActionShown && (edit || data.new) && (
                <div>
                  {/* image action */}
                  <div className="image-action-container">
                    <div className="image-action d-flex justify-content-center">
                      <div
                        className="f-9 fw-400 image-action-txt"
                        onClick={handleReplace}
                      >
                        {trans.Replace}
                      </div>
                      <div className="image-action-divider mx-2"></div>
                      <div
                        className="f-9 fw-400 image-action-txt"
                        onClick={() => handleRemove(index)}
                      >
                        {trans.Remove}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <label className="faculty-image-box" htmlFor={`college${index}`}>
                {data.image !== "" && data.image ? (
                  <img src={data.image} alt="" />
                ) : (
                  <div className="cursor-pointer">
                    <img src={ImageIcon} alt="" className="pl-1 pr-1" />
                    <span className="f-12 fw-400 pr-1">Upload Image</span>
                  </div>
                )}

                <input
                  disabled={edit || data.new ? "" : "disabled"}
                  ref={inputReplace}
                  type="file"
                  name="image1"
                  id={`college${index}`}
                  style={{ display: "none" }}
                  onChange={handleChange}
                />
              </label>
            </div>
            <span className="float-right f-img-size f-12 fw-400">
              {trans.Maxsize} : 5MB
            </span>
          </div>
        </div>
      </Form>
    </div>
  );
};
