import Editor from "../../Components/common/Editor";
import trans from "./../../../Languages/Languages";
import {useState, useEffect} from "react";
import {Button, message, Card} from "antd";
import API from "../../../API";

const Scholarships = () => {
  const [editorHtml, setEditorHtml] = useState("");

  const handleTextEditorChange = (html) => {
    setEditorHtml(html);
  };
  const [loading, setLoading] = useState(true);
  //
  const [refresh, setRefresh] = useState(false);
  ///////////////////////////////////////////////////////////////////////////////////////////////
  ///                             Use Effect
  ////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    setLoading(true);

    const init = async () => {
      await API.get("/admin/scholarships")
        .then((res) => {
          setEditorHtml(res.data[0].body);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    };

    init();
  }, [refresh]);

  ///////////////////////////////////////////////////////////////////////////////////////////////
  ///                             update
  ////////////////////////////////////////////////////////////////////////////////////////////////
  const handleSave = (values) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("body", editorHtml);

    API.post("/admin/scholarships/update", formData)
      .then((res) => {
        setRefresh(!refresh);
        setLoading(false);

        message.success("Updated Succesfully", 3);
      })
      .catch((err) => {
        setLoading(false);

        message.error("something went wrong", 3);
      });
  };
  return (
    <div className='pages-container'>
      <Card loading={loading} bordered={false}>
        <div className='content-wrapper'>
          <div className='bg-content-container'>
            <div className='fw-600 h-heading '>{trans.Scholarships}</div>
            <Editor
              key='schiolarships'
              editorHtml={editorHtml}
              setEditorHtml={setEditorHtml}
              handleChange={handleTextEditorChange}
            />
          </div>

          {/* save changes */}

          <Button
            onClick={handleSave}
            className='btn-save-changes'
            type='primary'
            htmlType='submit'
          >
            {trans.Save}
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default Scholarships;
