import React, { useState, useEffect } from "react";
import trans from "./../../../Languages/Languages";
import Editor from "../../Components/common/Editor";
import ImageIcon from "../../../assets/icons/image.svg";

const UploadImageHeader = ({
  record,
  currentPagePagination,
  images,
  setHomeData,
  setImages,
}) => {
  const inputReplace = React.createRef();
  const [isActionShown, setIsActionShown] = useState(false);

  const [carouselEditorHtml, setCarouselEditorHtml] = React.useState();

  // eslint-disable-next-line
  const handleTextAreaChange = ({ event }) => {};

  const handleCarouselEditorChange = (html) => {
    setCarouselEditorHtml(html);
    const dummyRecord = { ...record };
    dummyRecord.carosal_data[currentPagePagination].image_text = html;
    setHomeData(dummyRecord);
  };

  useEffect(() => {
    setCarouselEditorHtml(
      record.carosal_data[currentPagePagination ? currentPagePagination : 0]
        .image_text
    );
    // eslint-disable-next-line no-undef
  }, [record, currentPagePagination]);

  const handleChange = (e) => {
    const dummyRecord = { ...record };
    dummyRecord.carosal_data[currentPagePagination].image = URL.createObjectURL(
      e.target.files[0]
    );
    setHomeData(dummyRecord);

    const dummyImageObject = [...images];
    dummyImageObject[currentPagePagination].image = e.target.files[0];

    setImages(dummyImageObject);
  };

  const handleRemove = () => {
    const dummyRecord = { ...record };
    dummyRecord.carosal_data[currentPagePagination].image = "";
    setHomeData(dummyRecord);

    const dummyImageObject = [...images];
    dummyImageObject[currentPagePagination].image = null;

    setImages(dummyImageObject);
  };

  const handleReplace = () => {
    inputReplace.current.click();
  };

  return (
    <div>
      <div
        className="h-image-upload-container"
        onMouseEnter={() => setIsActionShown(true)}
        onMouseLeave={() => setIsActionShown(false)}
      >
        <div
          className={
            record.carosal_data[currentPagePagination].image
              ? "h-image-upload medium-img h-uploaded"
              : " h-image-upload medium-img"
          }
          style={{ position: "relative" }}
        >
          {isActionShown && (
            <div>
              {/* image action */}
              <div className="image-action-container">
                <div className="image-action d-flex justify-content-center">
                  <div
                    className="f-9 fw-400 image-action-txt"
                    onClick={handleReplace}
                  >
                    {trans.Replace}
                  </div>
                  <div className="image-action-divider mx-2"></div>
                  <div
                    className="f-9 fw-400 image-action-txt"
                    onClick={handleRemove}
                  >
                    {trans.Remove}
                  </div>
                </div>
              </div>
            </div>
          )}

          <label className="image-box" htmlFor="image1">
            {record.carosal_data[currentPagePagination].image ? (
              <div
                className="images-background-config"
                style={{
                  background: `url(${record.carosal_data[currentPagePagination].image})`,
                }}
              ></div>
            ) : (
              <div>
                <img src={ImageIcon} alt="" className="px-2" />

                <span>Upload Image</span>
              </div>
            )}

            <input
              ref={inputReplace}
              type="file"
              name="image1"
              id="image1"
              style={{ display: "none" }}
              onChange={handleChange}
            />
          </label>
        </div>
        <span className="float-left f-img-size f-12 fw-400">
          {trans.Maxsize} : 5MB {trans.RecommendedResolution} : 1920 x 1080
        </span>
      </div>
      {/*/////////////////////////////////////////////////////////////////*/}

      {/* carousel text */}
      <div className="fw-600 carousel-text-heading mb-2">
        {trans.CarouselText}
      </div>

      <Editor
        key="homeCarouselEditorss"
        editorHtml={carouselEditorHtml}
        setEditorHtml={setCarouselEditorHtml}
        handleChange={handleCarouselEditorChange}
      />
    </div>
  );
};

export default UploadImageHeader;
