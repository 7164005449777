import CloseIcon from "@material-ui/icons/Close";
import {message, Card, Button, Form, Input} from "antd";
import trans from "../../../Languages/Languages";
import React, { useEffect, useState } from "react";
import API from "../../../API";
import EditIcon from "@material-ui/icons/Edit";
import Editor from "../common/Editor";
import ImageIcon from "../../../assets/icons/image.svg";

const AccordionContent = () => {
    message.config({
        top: 80,
    });

    const [testimonialData, setTestimonialData] = useState([]);
    const [newData, setNewData] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState({});

    const handleAnotherTestimonial = () => {
        setLoading(true);
        const dummyData = [
            {
                person_name: "",
                profession_title: "",
                description: "",
                image: "",
                new: true,
            },
            ...testimonialData,
        ];
        setTestimonialData(dummyData);

        const newDummyData = [
            {
                person_name: "",
                profession_title: "",
                description: "",
                image: "",
                new: true,
            },
            ...newData,
        ];
        setNewData(newDummyData);

        setTimeout(() => {
            setLoading(false);
        }, 500);
    };

    // Use Effect
    useEffect(() => {
        setLoading(true);

        const init = async () => {
            await API.get("/admin/testimonial")
                .then((res) => {
                    setTestimonialData(res.data);
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                });
        };

        init();
    }, [refresh]);

    // create new records
    const handleCreateNew = async () => {
        setLoading(true);

        if (newData.length > 0) {
            const formData = new FormData();

            newData.forEach((element, i) => {
                formData.append(`testimonials[${i}][person_name]`, element.person_name);
                formData.append(`testimonials[${i}][profession_title]`, element.profession_title);
                formData.append(`testimonials[${i}][description]`, element.description);
                formData.append(`testimonials[${i}][image]`, element.image);
            });

            await API.post("/admin/testimonial/create", formData)
                .then((res) => {
                    setNewData([]);
                    message.success("Testimonials Created Successfully.");
                    setErrors({});
                    setLoading(false);
                    setRefresh(!refresh);
                })
                .catch((err) => {
                    if (err.response.status === 400) {
                        setErrors(err.response.data);

                        Object.keys(err.response.data).map((e) => {
                            message.error(err.response.data[e]);
                        });

                        // error for only image size must not be greater than 5 MB.
                        if (err.response.data["image"]) {
                            if (err.response.data["image"][0].includes("5120")) {
                                message.error("The image must not be greater than 5 MB.");
                            }
                        }
                    }

                    setLoading(false);
                });
        }
    };

    return (
        <div className="pages-container">
            <Card bordered={false} className="p-0" loading={loading}>
                {newData.length > 0 && (
                    <div className="d-flex justify-content-end">
                        <div
                            className="btn-save-section fw-500 float-right my-3"
                            onClick={handleCreateNew}
                        >
                            <span>{trans.Save}</span>
                        </div>
                    </div>
                    // <Button
                    //     onClick={handleCreateNew}
                    //     className="btn-save-changes"
                    //     type="primary"
                    //     htmlType="submit"
                    // >
                    //     {trans.Save}
                    // </Button>
                )}

                {testimonialData &&
                    testimonialData?.map((item, i) => {
                        return (
                            <div className="content-wrapper" key={i}>
                                <AddTestimonial
                                    index={i}
                                    data={item}
                                    setData={setTestimonialData}
                                    originalData={testimonialData}
                                    refresh={refresh}
                                    setRefresh={setRefresh}
                                    setLoading={setLoading}
                                    newData={newData}
                                    setNewData={setNewData}
                                    errors={errors}
                                    setErrors={setErrors}
                                />
                            </div>
                        );
                    })}

                {/* add another */}
                <div className="w-100 d-flex justify-content-center">
                    <div className="news-add-another" onClick={handleAnotherTestimonial}>
                        <span className="f-12 fw-500">
                          {trans.Addanother}
                            <span className="ml-1 f-20 fw-500 align-middle">+</span>
                        </span>
                    </div>
                </div>
            </Card>
        </div>
    );
};

export default AccordionContent;

const AddTestimonial = ({
                     data,
                     index,
                     originalData,
                     setData,
                     newData,
                     setNewData,
                     setLoading,
                     refresh,
                     setRefresh,
                            errors,
                            setErrors,
                 }) => {
    const [editorHtml, setEditorHtml] = useState("");
    const [edit, setEdit] = useState(false);
    const [currentImageObject, setCurrentImageObject] = useState({ image: "" });
    const [isActionShown, setIsActionShown] = useState(false);

    const inputReplace = React.createRef();

    const handleChange = (e) => {
        const dummyImage = { ...currentImageObject };
        dummyImage.image = e.target.files[0];
        setCurrentImageObject(dummyImage);

        const dummyData = [...originalData];
        dummyData[index].image = URL.createObjectURL(e.target.files[0]);
        setData(dummyData);

        if (index <= newData.length - 1) {
            const dummyNewData = [...newData];
            dummyNewData[index].image = e.target.files[0];
            setNewData(dummyNewData);
        }
    };

    const handleTextEditorChange = (html) => {
        setEditorHtml(html);
        if (index <= newData.length - 1) {
            const dummyNewData = [...newData];
            dummyNewData[index].description = html;
            setNewData(dummyNewData);
        }
    };

    useEffect(() => {
        setEditorHtml(data.description);
    }, [data]);

    // update record
    const handleSectionSave = async (values) => {
        setLoading(true);
        const formData = new FormData();
        formData.append("person_name", values.person_name);
        formData.append("profession_title", values.profession_title);
        formData.append("description", editorHtml);
        currentImageObject.image === ""
            ? formData.append("image", data.image)
            : formData.append("image", currentImageObject.image);

        await API.post(`/admin/testimonial/update/${data.id}`, formData)
            .then((res) => {
                message.success("testimonials Updated Successfully");
                setLoading(false);
                setEdit(false);
                setRefresh(!refresh);
            })
            .catch((err) => {
                setLoading(false);
                if (err.response.status === 400) {
                    setErrors(err.response.data);

                    Object.keys(err.response.data).map((e) => {
                        message.error(err.response.data[e]);
                    });

                    // error for only image size must not be greater than 5 MB.
                    if (err.response.data["image"]) {
                        if (err.response.data["image"][0].includes("5120")) {
                            message.error("The image must not be greater than 5 MB.");
                        }
                    }
                }
            });
    };

    // remove image
    const handleRemove = (index) => {
        const UpdatedData = originalData.map((item, i) => {
            let temp = item;
            if (i === index) temp.image = "";
            return temp;
        });
        setData(UpdatedData);
    };

    const handleReplace = () => {
        inputReplace.current.click();
    };

    const handleInputValueChange = ({ target }) => {
        if (index >= 0 && newData.length > 0 && data.new) {
            const dummyNewData = [...newData];
            dummyNewData[index][target.name] = target.value;
            setNewData(dummyNewData);
        }
    };

    const handleNewTestimonialDelete = (index) => {
        const dummyData = originalData.filter((item, i) => {
            return index !== i;
        });
        setData(dummyData);
        let newIndex = newData.length + index - originalData.length;
        let newRecord = [];
        newRecord = newData.filter((item, i) => {
            return newIndex !== i;
        });
        setNewData(newRecord);
    };

    ///////////////////////////////////////////////////////////////////////////////////////////////
    ///                             delete record
    ////////////////////////////////////////////////////////////////////////////////////////////////
    const handleTestimonialDelete = async () => {
        setLoading(true);

        await API.post(`/admin/testimonial/delete/${data.id}`)
            .then((res) => {
                setRefresh(!refresh);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    return (
        <div className="bg-content-container-testimonial faculty-container ">
            <Form onFinish={handleSectionSave} initialValues={data}>
                {/* edit action */}
                <div style={{ height: 10 }}>
                    {edit ? (
                        <div className="edit-action icon-container d-block mr-1">
                          <span
                              className="f-12 fw-400 cancel cursor-pointer"
                              onClick={() => setEdit(false)}
                          >
                            {trans.Cancel}
                          </span>
                            <span className="save">
                            <Button type="" htmlType="submit">
                              {trans.Save}
                            </Button>
                          </span>
                        </div>
                    ) : (
                        <div className="icon-container">
                            {!data.new && (
                                <span onClick={() => setEdit(true)}>
                                  <EditIcon className="b-icon-edit cursor-pointer" />
                                </span>
                            )}
                            <CloseIcon
                                className="b-icon-close cursor-pointer"
                                onClick={() => {
                                    data.new
                                        ? handleNewTestimonialDelete(index)
                                        : handleTestimonialDelete(index);
                                }}
                            />
                        </div>
                    )}
                </div>

                <div className={edit || data.new ? "" : "quill-readonly"}>
                    <Editor
                        key={`news-tab${index}`}
                        editorHtml={editorHtml}
                        setEditorHtml={setEditorHtml}
                        handleChange={handleTextEditorChange}
                        readonly={!edit && !data.new}
                    />
                </div>

                <div className={"d-flex align-items-center justify-content-between"}>
                    <div className="accordion-detail d-input-name mr-5">
                        <div className="f-12 fw-500 input-heading">
                            {trans.PersonName}
                        </div>
                        <Form.Item name="person_name" className="m-0">
                            <Input
                                disabled={edit || data.new ? "" : "disabled"}
                                name="person_name"
                                onChange={handleInputValueChange}
                                type="text"
                                placeholder={trans.Typehere}
                            />
                        </Form.Item>
                        {errors?.person_name && (
                            <span className="text-danger">
                                  {errors?.person_name}
                              </span>
                        )}
                    </div>

                    <div className="accordion-detail d-input-name mr-5">
                        <div className="f-12 fw-500 input-heading">
                            {trans.JobTitle}
                        </div>
                        <Form.Item name="profession_title" className="m-0">
                            <Input
                                disabled={edit || data.new ? "" : "disabled"}
                                name="profession_title"
                                onChange={handleInputValueChange}
                                type="text"
                                placeholder={trans.Typehere}
                            />
                        </Form.Item>
                        {errors?.profession_title && (
                            <span className="text-danger">
                                  {errors?.profession_title}
                              </span>
                        )}
                    </div>

                    {/*image*/}
                    <div className="accordion-detail d-input-name mr-5">
                        <div
                            className={
                                data.image !== "" && data.image
                                    ? "future-image-upload-container bot-image uploaded"
                                    : "future-image-upload-container bot-image"
                            }
                            key={index}
                        >
                            {/* medium images */}
                            <div
                                className="future-image-upload medium-img cursor-pointer"
                                onMouseEnter={() => setIsActionShown(true)}
                                onMouseLeave={() => setIsActionShown(false)}
                            >
                                {isActionShown && (edit || data.new) && (
                                    <div>
                                        {/* image action */}
                                        <div className="image-action-container">
                                            <div className="image-action d-flex justify-content-center">
                                                <div
                                                    className="f-9 fw-400 image-action-txt"
                                                    onClick={handleReplace}
                                                >
                                                    {trans.Replace}
                                                </div>
                                                <div className="image-action-divider mx-2"></div>
                                                <div
                                                    className="f-9 fw-400 image-action-txt"
                                                    onClick={() => handleRemove(index)}
                                                >
                                                    {trans.Remove}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <label className="bot-image-box" htmlFor={`botImage${index}`}>
                                    {data.image !== "" && data.image ? (
                                        <img src={data.image} alt="" />
                                    ) : (
                                        <div className="cursor-pointer">
                                            <img src={ImageIcon} alt="" className="pl-1 pr-1 upload-img" />
                                            <span className="f-12 fw-400 pr-1">Upload Image</span>
                                        </div>
                                    )}

                                    <input
                                        disabled={edit || data.new ? "" : "disabled"}
                                        ref={inputReplace}
                                        type="file"
                                        name="image1"
                                        id={`botImage${index}`}
                                        style={{ display: "none" }}
                                        onChange={handleChange}
                                    />
                                </label>

                                <div className="d-flex justify-content-center mt-2">
                                  <span className="float-right f-img-size f-12 fw-400 align-self-end">
                                    {trans.Maxsize} : 5MB
                                  </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    );
};
