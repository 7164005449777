import React, {useEffect, useState} from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import API from "../../../API";

export default function Chart() {
  const [chartData, setChartData] = useState([{month: "", visitors: ""}]);
  useEffect(() => {
    const init = async () => {
      await API.get("/admin/dashboard")
        .then((res) => {
          setChartData(res.data);
        })
        .catch((err) => {});
    };

    init();
  }, []);
  return (
    <div dir='ltr' style={{width: "100%", height: 300}}>
      <ResponsiveContainer>
        <AreaChart
          height={500}
          data={chartData}
          margin={{top: 10, right: 30, left: 0, bottom: 0}}
        >
          <defs>
            <linearGradient id='colorUv' x1='0' y1='0' x2='0' y2='1'>
              <stop offset='5%' stopColor='#07975140' stopOpacity={1} />
              <stop offset='95%' stopColor='#07975140' stopOpacity={1} />
            </linearGradient>
          </defs>
          <XAxis dataKey='month' />
          <YAxis />
          <Tooltip />
          <Area
            type='monotone'
            dataKey='visitors'
            stroke='#079751'
            fillOpacity={1}
            fill='url(#colorUv)'
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}
