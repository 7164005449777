import {Tabs} from "antd";
import News from "./News";
import Events from "./Events";

const {TabPane} = Tabs;
const NewsAndEvents = () => {
  return (
    <div className='news-and-events'>
      <Tabs type='card' centered defaultActiveKey='1'>
        {/* ////////////////////// News ////////////////////// */}

        <TabPane tab='News' key='1'>
          <News />
        </TabPane>
        {/* ////////////////////// Events ////////////////////// */}
        <TabPane tab='Events' key='2'>
          <Events />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default NewsAndEvents;
