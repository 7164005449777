import React from "react";
import {Col, Form, Input, message, Modal, Row, Select} from "antd";
import {EyeInvisibleOutlined, EyeTwoTone} from "@ant-design/icons";
import trans from "./../../Languages/Languages";
import { useLanguage } from "../../Context/LanguageContext";
import API from "../../API";
const { Item } = Form;
function AddUser({ refresh, setRefresh, isModalVisible, setIsModalVisible }) {
  const handleOk = (values) => {
    API.post("/admin/user/register", values, {
      headers: {
        locale: values.college,
      },
    })
      .then(() => {
        message.success(`User created in ${values.college}`);
        setIsModalVisible(false);
        setRefresh(!refresh);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleChange = () => {};
  const { isRTL } = useLanguage();
  return (
    <Modal
      title={trans.CreateUser}
      visible={isModalVisible}
      onCancel={handleCancel}
      destroyOnClose
      width={700}
      footer={null}
    >
      <Form
        onFinish={handleOk}
        initialValues={{ college: "ar", language: "ar" }}
      >
        <div dir={isRTL ? "rtl" : "ltr"} className={isRTL ? "rtl" : "ltr"}>
          <Row gutter={[15, 15]}>
            <Col span={8}>
              <label htmlFor="">{trans.Name}</label>
              <Item name={"name"}>
                <Input onChange={handleChange} />
              </Item>
            </Col>
            <Col span={8}>
              <label htmlFor="">{trans.Email}</label>
              <Item name={"email"}>
                <Input onChange={handleChange} />
              </Item>
            </Col>
            <Col span={8}>
              <label htmlFor="">{trans.PhoneNumber}</label>
              <Item name={"phone_number"}>
                <Input type="tel" onChange={handleChange} />
              </Item>
            </Col>
            <Col span={16}>
              <label htmlFor="">{trans.College}</label> <br />
              <Item name={"college"}>
                <Select style={{ width: "100%" }}>
                  <Select.Option value="ar">{trans.Arabic}</Select.Option>
                  <Select.Option value="en">{trans.English}</Select.Option>
                  <Select.Option value="ur">{trans.urdu}</Select.Option>
                  <Select.Option value="fr">{trans.French}</Select.Option>
                </Select>
              </Item>
            </Col>
            <Col span={8}>
              <label htmlFor="">{trans.Language}</label>
              <br />
              <Item name={"language"}>
                <Select style={{ width: "100%" }}>
                  <Select.Option value="ar">{trans.Arabic}</Select.Option>
                  <Select.Option value="en">{trans.English}</Select.Option>
                  <Select.Option value="ur">{trans.urdu}</Select.Option>
                  <Select.Option value="fr">{trans.French}</Select.Option>
                </Select>
              </Item>
            </Col>

            <Col span={8}>
              <label htmlFor="">{trans.UserName}</label>
              <Item name={"user_name"}>
                <Input onChange={handleChange} />
              </Item>
            </Col>
            <Col span={8}>
              <label htmlFor="">{trans.Password}</label>
              <Item name={"password"}>
                <Input.Password
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                  onChange={handleChange}
                />
              </Item>
            </Col>
            <Col span={8}>
              <label htmlFor="">{trans.ConfirmPassword}</label>
              <Item name={"confirm_password"}>
                <Input.Password
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                  onChange={handleChange}
                />
              </Item>
            </Col>
          </Row>
          <div className="ant-modal-footer">
            <button type="button" className="ant-btn" onClick={handleCancel}>
              <span>{trans.Cancel}</span>
            </button>
            <button type="submit" className="ant-btn ant-btn-primary">
              <span>{trans.Create}</span>
            </button>
          </div>
        </div>
      </Form>
    </Modal>
  );
}

export default AddUser;
