import React, { useState, useEffect } from "react";
import AboutDegree from "../../Components/OnlineDegree/AboutDegree";
import Syllabus from "./../../Components/OnlineDegree/Syllabus";
import TuitionFees from "./../../Components/OnlineDegree/TuitionFees";
import AcademicHelp from "./../../Components/OnlineDegree/AcademicHelp";
import DeleteIcon from "@material-ui/icons/Delete";
import { Modal, Form, Input, Button, message } from "antd";
import EditIcon from "@material-ui/icons/Edit";
import trans from "./../../../Languages/Languages";
import API from "../../../API";
import { useLocation } from "react-router";

const Degree = ({ currentPage }) => {
  const [currentDegreeName, setCurrentDegreeName] = useState("");
  const [currentDegreeData, setCurrentDegreeData] = useState([]);
  const [refresh, setRefresh] = useState(false);

  message.config({
    top: 80,
  });

  const currentID = window.location.hash.split("/")[3];

  ///////////////////////////////////////////////////////////////////////////////////////////////
  ///                                   Use Effect                                            ///
  ///////////////////////////////////////////////////////////////////////////////////////////////
  let location = useLocation();
  useEffect(() => {
    const init = () => {
      API.get("/admin/degree")
        .then((res) => {
          for (const x of res.data) {
            if (parseInt(currentID) === x.id) {
              setCurrentDegreeName(x.english_name);
            }
          }
        })
        .catch((err) => {});
    };
    init();

    API.get(`/admin/degree/${currentID}`)
      .then((res) => {
        setCurrentDegreeData(res.data);
      })
      .catch((err) => {});
  }, [refresh, currentID, location]);

  ////////////////////////////////// Modal Edit //////////////////////////////////
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleDegreeEdit = () => {
    setIsModalVisible(true);

    // const data = {...modalDegreeName};
    // data["english"] = currentDegree.name;
    // setModalDegreeName(data);
  };

  ////////////////////////////////// Modal Delete //////////////////////////////////
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const handleDegreeDeleteButton = () => {
    setIsDeleteModalVisible(true);
  };
  const handleDegreeDelete = async () => {
    await API.post(`/admin/degree/delete/${currentID}`)
      .then((res) => {
        setIsModalVisible(false);
        message.success("Degree Deleted Successfully");
        setRefresh(!refresh);
      })
      .catch((err) => {
        message.error("Something went wrong, please try again");
      });
  };
  return (
    <div>
      <div
        className="degree-main-heading-container d-flex justify-content-between "
        style={{ marginTop: -50, paddingBottom: 13 }}
      >
        <div className="fw-700 f-16 degree-main-heading">
          {currentDegreeName}
        </div>
        <div>
          <span
            className="degree-main-edit f-12 fw-500"
            onClick={handleDegreeEdit}
          >
            <EditIcon fontSize="small" /> {trans.Edit}
          </span>
          <span className="degree-main-divider"></span>
          <span
            className="degree-main-delete f-12 fw-500"
            onClick={handleDegreeDeleteButton}
          >
            <DeleteIcon fontSize="small" /> {trans.Delete}
          </span>
        </div>
      </div>
      {isModalVisible && (
        <EditDegreePopup
          isModalVisible={isModalVisible}
          handleCancel={handleCancel}
          currentDegreeData={currentDegreeData}
          currentID={currentID}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      )}
      <DeleteDegreePopup
        isModalVisible={isDeleteModalVisible}
        setIsModalVisible={setIsDeleteModalVisible}
        handleDegreeDelete={handleDegreeDelete}
      />

      <div>{getPageComponent(currentPage)}</div>
    </div>
  );
};

export default Degree;

function getPageComponent(currentPage) {
  switch (currentPage.toLowerCase()) {
    case "aboutdegree":
      return <AboutDegree />;
    case "syllabusbysemester":
      return <Syllabus />;
    case "tuitionfees":
      return <TuitionFees />;
    case "academichelp":
      return <AcademicHelp />;
    default:
      return "";
  }
}

const EditDegreePopup = ({
  isModalVisible,
  handleCancel,
  currentDegreeData,
  currentID,
  refresh,
  setRefresh,
}) => {
  const [errors, setErrors] = useState({});
  const style = { top: "40%" };

  let rules = [
    {
      required: true,
      message: "field is required",
    },
  ];

  const handleUpdateDegree = (values) => {
    const formData = new FormData();
    formData.append("id", currentID);
    formData.append("arabic_name", values.arabic_name);
    formData.append("english_name", values.english_name);
    formData.append("french_name", values.french_name);
    formData.append("urdu_name", values.urdu_name);

    API.post(`/admin/degree/update/${currentID}`, formData)
      .then((res) => {
        message.success("Degree Updated Successfully");
        setErrors({});
        handleCancel();
        setRefresh(!refresh);
      })
      .catch((e) => {
        if (e.response.status === 400) {
          setErrors(e.response.data);
        }
      });
  };

  return (
    <div className="online-degree-modal">
      <Modal
        title={`Edit ${currentDegreeData.english_name}`}
        visible={isModalVisible}
        onCancel={handleCancel}
        okText="save"
        width="511px"
        style={style}
        footer={false}
        className="degree-edit-modal"
      >
        <Form onFinish={handleUpdateDegree} initialValues={currentDegreeData}>
          <div className="d-flex justify-content-between">
            <div className="accordion-detail online-degree-modal">
              <div className="f-12 fw-400 input-heading">Name in Arabic</div>
              <Form.Item rules={rules} name="arabic_name">
                <Input type="text" placeholder={trans.Typehere} />
              </Form.Item>
              {errors?.arabic_name && (
                <span className="text-danger">{errors?.arabic_name}</span>
              )}
            </div>
            <div className="accordion-detail online-degree-modal">
              <div className="f-12 fw-400 input-heading ">Name in English</div>
              <Form.Item rules={rules} name="english_name">
                <Input type="text" placeholder={trans.Typehere} />
              </Form.Item>
              {errors?.english_name && (
                <span className="text-danger">{errors?.english_name}</span>
              )}
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <div className="accordion-detail online-degree-modal">
              <div className="f-12 fw-400 input-heading">Name in French</div>
              <Form.Item rules={rules} name="french_name">
                <Input rules={rules} type="text" placeholder={trans.Typehere} />
              </Form.Item>
              {errors?.french_name && (
                <span className="text-danger">{errors?.french_name}</span>
              )}
            </div>
            <div className="accordion-detail online-degree-modal">
              <div className="f-12 fw-400 input-heading ">Name in Urdu</div>
              <Form.Item rules={rules} name="urdu_name">
                <Input type="text" placeholder={trans.Typehere} />
              </Form.Item>
              {errors?.urdu_name && (
                <span className="text-danger">{errors?.urdu_name}</span>
              )}
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <Button
              onClick={handleCancel}
              className="btn-save-changes btn-cancel-degree-edit"
              type="primary"
            >
              {trans.Cancel}
            </Button>
            <Button
              className="btn-save-changes btn-cancel-degree-save"
              type="primary"
              htmlType="submit"
            >
              {trans.Save}
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

const DeleteDegreePopup = ({
  handleDegreeDelete,
  isModalVisible,
  setIsModalVisible,
  modalDegreeName,
}) => {
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const style = { top: "40%" };

  return (
    <div>
      <Modal
        className="online-degree-delete-modal"
        title="Confirmation"
        visible={isModalVisible}
        onOk={() => {
          handleDegreeDelete(modalDegreeName);
          handleCancel();
        }}
        onCancel={handleCancel}
        okText="Delete"
        cancelText="No"
        width="511px"
        style={style}
      >
        <div className="text-center f-15 fw-400">
          Are you sure you want to remove this degree ?
        </div>
        <div className="text-center f-12 fw-300">
          This includes content as well
        </div>
      </Modal>
    </div>
  );
};
